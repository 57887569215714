const lists = [
	{
		area: "Bottom Bar",
	},
	{
		area: "Large List",
	},
	{
		area: "Standard List",
	},
	{
		area: "Function List",
	},
];

export default lists;
