import {
	Box,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemText,
	TextField,
	Typography,
} from "@mui/material";
import React from "react";
import { MdEdit } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { Colours } from "../assets/global/Theme-variable";
import RouterBreadcrumbs from "../components/Breadcrumbs";
import MainButton from "../components/Button";
import SelectList from "../components/SelectList";
import axios from "axios";

import AutoCompleteTagCell from "./datagrids/AutoCompleteTagCell";
import { DataGridContext } from "./datagrids/data grid files/DataGridContext";
import listOfLists from "./grid/data/listOfLists";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CustomTextField from "../components/CustomTextField";
import CustomInput from "../components/CustomPriceField";
import CustomSelect from "../components/CustomSelect";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import AccessControl, { checkPermissions } from "./AccessControl";
import { useAuth0 } from "@auth0/auth0-react";
import { API_ENDPOINTS } from "../API/apiConfig";

export default function IndividualSite() {
	const { state } = useLocation();
	const { data, setData, userPermission } = React.useContext(DataGridContext);
	const navigate = useNavigate();
	const { getAccessTokenSilently } = useAuth0();

	const { site_number, site_name, site_details, plu_hash, site_ref, uid } =
		state.item;

	const [item, setItem] = React.useState(state.item);
	const [isSaving, setIsSaving] = React.useState(false);

	const saveChanges = async () => {
		setIsSaving(true);

		const rowIndex = data["sites"].findIndex((site) => site.uid === item.uid);

		const updatedRows = [...data.sites];

		updatedRows[rowIndex] = item;

		setData({ ...data, sites: updatedRows });

		const token = await getAccessTokenSilently();
		const newRowData = {
			updates: [item],
		};
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		setTimeout(() => {
			axios
				.post(
					API_ENDPOINTS.site, // Using the endpoint from API_ENDPOINTS
					newRowData,
					config
				)
				.then(function (response) {
					console.log("res", response);
				})
				.catch(function (error) {
					console.log("res", error);
				});

			setIsSaving(false);
			setUnsavedChanges(false);
		}, 2000);
		navigate("/sites");
	};

	const handleTagChange = (event, value) => {
		const tagNumbers = value.map((value) => value.tag_ref);
		setItem({
			...item,
			site_details: { ...item.site_details, tags: tagNumbers },
		});
	};

	const [error, setError] = React.useState({ open: false, text: "" });
	const [unsavedChanges, setUnsavedChanges] = React.useState(false);

	if (item !== state.item && !unsavedChanges) {
		setUnsavedChanges(true);
	} else {
	}

	return (
		<Box
			sx={{
				flex: 1,
				minHeight: "100%",
				width: "100%",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
			}}
		>
			<Box
				sx={{
					maxWidth: "100%",
					ml: 4,
					mr: 4,
					marginTop: 2,
					mb: 2,
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
				}}
			>
				<RouterBreadcrumbs />
				{/* <Typography
					variant="h3"
					sx={{color: Colours.gsblue, justifySelf: "center"}}
				>
					{Name}{" "}
				</Typography> */}
			</Box>
			<Box
				sx={{
					flex: 9,
					ml: 4,
					mr: 4,
					backgroundColor: "white",
					borderRadius: 2,
					p: 4,
					mb: 2,
				}}
			>
				<Box
					sx={{
						width: "100%",
						// mb: 2,
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						gap: 8,
					}}
				>
					<Box
						sx={{
							width: "50%",
							display: "flex",
							flexDirection: "column",
							gap: 4,
						}}
					>
						<Box
							sx={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
								gap: 2,
								alignItems: "stretch",
							}}
						>
							{/* <Typography
								variant="h5"
								sx={{mb: 0, color: Colours.gsblue, justifySelf: "center"}}
							>
								Description
							</Typography> */}
							<Box
								sx={{
									width: "100%",
									display: "flex",
									flexDirection: "row",
									gap: 2,
								}}
							>
								<Box sx={{ width: "70%" }}>
									<CustomTextField
										defaultValue={item.site_name}
										// disabled={checkPermissions(userPermission, {
										// 	page: "sites",
										// 	allow: false,
										// 	action: "edit",
										// })}
										label={
											<Typography
												sx={{
													fontSize: 18,
													mb: 0,
													color: Colours.gsblue,
													justifySelf: "center",
												}}
											>
												Site Name
											</Typography>
										}
										onChange={(event) =>
											setItem({ ...item, name: event.target.value })
										}
									/>
								</Box>
								<Box sx={{ width: "30%" }}>
									<CustomTextField
										defaultValue={item.site_number}
										// disabled={checkPermissions(userPermission, {
										// 	page: "sites",
										// 	allow: false,
										// 	action: "edit",
										// })}
										label={
											<Typography
												sx={{
													fontSize: 18,
													mb: 0,
													color: Colours.gsblue,
													justifySelf: "center",
												}}
											>
												Site Number
											</Typography>
										}
										onChange={(event) =>
											setItem({ ...item, code: event.target.value })
										}
									/>
								</Box>
							</Box>
						</Box>
						<Box
							sx={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
								gap: 2,
							}}
						>
							{/* <Typography
								variant="h5"
								sx={{mb: 0, color: Colours.gsblue, justifySelf: "center"}}
							>
								Category
							</Typography>
							<Box
								sx={{
									width: "100%",
									display: "flex",
									flexDirection: "row",
									gap: 2,
									mb: 2,
								}}
							>
								<CustomSelect
									options={typeDummy}
									defaultOption={Type}
									label={"Site Type"}
									onChange={(event) =>
										setItem({ ...item, type: event.target.value.type })
									}
								/>
							</Box> */}
							{/* <Box
								sx={{
									width: "100%",
									display: "flex",
									flexDirection: "column",
									gap: 2,
								}}
							>
								<Box
									sx={{
										width: "100%",
										display: "flex",
										flexDirection: "row",
										gap: 2,
									}}
								></Box> */}
							{/* </Box> */}
						</Box>
					</Box>
					<Box
						sx={{
							width: "50%",
							display: "flex",
							flexDirection: "column",
							gap: 4,
						}}
					>
						<Box
							sx={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
								gap: 2,
							}}
						>
							{/* <Typography
								variant="h5"
								sx={{mb: 0, color: Colours.gsblue, justifySelf: "center"}}
							>
								Organisation
							</Typography> */}
							<AutoCompleteTagCell
								tags={item.site_details.tags}
								onChange={handleTagChange}
								// disabled={checkPermissions(userPermission, {
								// 	page: "sites",
								// 	allow: false,
								// 	action: "edit",
								// })}
							/>
						</Box>
						<Box
							sx={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
								gap: 2,
							}}
						>
							{/* <Typography
								variant="h5"
								sx={{mb: 0, color: Colours.gsblue, justifySelf: "center"}}
							>
								Associated lists
							</Typography> */}
							{/* {appearsOn.length > 0 ? (
								<List dense={true}>
									{appearsOn.map((list) => (
										<ListItem
											sx={{
												backgroundColor: "#F3F6F9",
												mb: 2,
												borderRadius: "4px",
											}}
										>
											<ListItemText primary={list.name} />
											<IconButton aria-label="delete" size="small">
												<ArrowForwardIcon
													color={Colours.gsblue}
													onClick={() =>
														navigate("/designer", {
															state: {item: list},
														})
													}
													size="10px"
												/>
											</IconButton>
										</ListItem>
									))}
								</List>
							) : (
								<Typography>
									This item is not associated with any lists
								</Typography>
							)} */}
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					alignSelf: "flex-end",
					justifyContent: "flex-end",
					alignItems: "center",
					flex: 1,
					// backgroundColor: "white",
					pr: 4,
					pb: 2,
				}}
			>
				{/* <MainButton
					text="Save"
					icon={<MdEdit size={15} />}
					variant="contained"
					width={"10%"}
					height={"40px"}
					onClick={saveChanges}
				/> */}
				<AccessControl
					userPermissions={userPermission}
					unallowedPermissions={{
						page: "items",
						// allow: false,
						strict: false,

						action: "edit",
					}}
					// renderNoAccess={() => <NoAccess permissionsNeeded="read:stats" />}
					renderNoAccess={() => <></>}
				>
					<LoadingButton
						// color={Colours.gsblue}
						onClick={saveChanges}
						loading={isSaving}
						loadingPosition="start"
						startIcon={<SaveIcon />}
						variant="contained"
					>
						<span>Save</span>
					</LoadingButton>
				</AccessControl>
			</Box>
		</Box>
	);
}
