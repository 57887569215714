import { Box, Typography } from "@mui/material";

import React, { useEffect, useLayoutEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Colours } from "../assets/global/Theme-variable";
import RouterBreadcrumbs from "../components/Breadcrumbs";
import MainButton from "../components/Button";
import S3 from "react-aws-s3";

import styled from "styled-components";
import { motion } from "framer-motion";
import AutoCompleteTagCell from "./datagrids/AutoCompleteTagCell";
import { DataGridContext } from "./datagrids/data grid files/DataGridContext";
import listOfLists from "./grid/data/listOfLists";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CustomTextField from "../components/CustomTextField";
import CustomInput from "../components/CustomPriceField";
import CustomSelect from "../components/CustomSelect";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import ItemModifiers from "../components/ItemModifiers";
import axios from "axios";
import { nanoid } from "nanoid";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ErrorDialog from "./grid/components/ErrorDialog";
import NumberFormatCustom, { appearsOn } from "./datagrids/utils";
import { DeleteOutline } from "@mui/icons-material";
import { Avatar, Card, CardMedia } from "@mui/material";
import { FiCamera, FiTrash, FiZoomIn } from "react-icons/fi";
import AccessControl, { checkPermissions } from "./AccessControl";
import { useAuth0 } from "@auth0/auth0-react";
import ImageUpload from "../components/ImageUpload";
import Accordions from "../components/Accordions";
import { API_ENDPOINTS } from "../API/apiConfig";
import Spinner from "../components/Spinner/Spinner";
import { useImmer } from "use-immer";
import OptionSelect from "./grid/components/OptionSelect";

export default function IndividualItem() {
	const { state } = useLocation();
	const _ = require("lodash");

	const {
		data,
		isRetail,
		setData,
		setNewData,
		userPermission,
		setSnackbarMessage,
		setSnackbarOpen,
	} = React.useContext(DataGridContext);
	const navigate = useNavigate();
	const [isSaving, setIsSaving] = React.useState(false);
	const { getAccessTokenSilently } = useAuth0();
	const { id } = useParams();
	const [isLoading, setIsLoading] = React.useState(true);

	const [item, setItem] = useImmer();

	const [modifiers, setModifiers] = React.useState([]);

	const [productImage, setProductImage] = useState("");
	const appears = appearsOn(data?.screens, item?.plu_number);

	useEffect(() => {
		const item2 = data?.items?.find((i) => i?.plu_number === id);

		setItem(_.cloneDeep(item2));
		const modifiersOrig = item2?.plu_details?.priceModifiers?.map(
			(modifier) => ({
				id: nanoid(),
				...modifier,
			})
		);
		setModifiers(modifiersOrig);
		setProductImage(item2?.plu_details?.imgUrl || "");
		setIsLoading(false);
	}, [data?.items, id]);

	window.Buffer = window.Buffer || require("buffer").Buffer;

	// let type = typeDummy.find((type) => type.type === Type);
	// let vatRate = VATDummy.find((rate) => rate.type === VATRate);

	// let mg = majorGroups.find((group) => group.number === sg.majorgroup);

	// console.log(sg);
	// React.useEffect(() => {
	// 	let salesGroup = salesGroups.filter((group) => group.number === salesgroup);

	// 	let mg = majorGroups.find(
	// 		(group) => salesGroup[0].majorgroup === group.number
	// 	);
	// 	setMajorGroup(mg);
	// }, [salesGroup]);

	const handleItemName = (event) => {
		const reg = new RegExp(/^[-a-zA-Z0-9@\s\*&+%£=!:;><)?(^\[\]{\}\\\/\.\_]+$/);
		console.log(reg.test(event.target.value));
		if (reg.test(event.target.value)) {
			setItem({ ...item, plu_name: event.target.value });
		} else {
			return;
		}
		//  else {
		// 	setValid(reg.test(event.target.value));
		// }
	};

	const saveChanges = async () => {
		setIsSaving(true);

		const newItem = _.cloneDeep(item);
		const modifiersClone = _.cloneDeep(modifiers);
		newItem.plu_details.priceModifiers = modifiersClone?.map((mod) => {
			delete mod.id;
			return mod;
		});
		parseInt(newItem.plu_details.salesGroup);

		const token = await getAccessTokenSilently();
		const newRowData = {
			updates: [newItem],
		};
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		let postStatus = 0;

		setTimeout(() => {
			axios
				.post(API_ENDPOINTS.plu, newRowData, config) // Using the endpoint from API_ENDPOINTS

				.then(function (response) {
					postStatus = response.status;

					if (postStatus === 200) {
						setSnackbarMessage({
							message: ["Changes Saved Successfully!"],
							duration: 2000,
							severity: "success",
						});
						setSnackbarOpen(true);
						console.log(response);
						navigate("/items");
						setIsSaving(false);
					} else {
						setSnackbarMessage({
							message: [
								"ERROR couldn't save data back to server. Check fields for errors.",
							],
							duration: 8000,
							severity: "error",
						});
						setSnackbarOpen(true);

						setIsSaving(false);
					}
				})
				.catch(function (error) {
					postStatus = error.response.status;

					// Set the Snackbar message for error
					setSnackbarMessage({
						message: [
							`ERROR couldn't save data back to server. ${error.response.statusText}.`,
						],
						duration: 6000,
						severity: "error",
					});

					// Open the Snackbar
					setSnackbarOpen(true);
					setIsSaving(false);
				});
			setNewData(true);
			setUnsavedChanges(false);
		}, 2000);
	};

	const closeDialog = () => {
		setError({
			...error,
			open: false,
		});
	};
	const handleDelete = () => {
		setError({
			open: true,
			text: "Are you you sure you want to delete this Item?",
			button1text: "Cancel",
			button1func: closeDialog,
			button2text: "Delete",
			button2func: deleteConfirmed,
			isSaving: false,
		});
	};

	const deleteConfirmed = async () => {
		setIsSaving(true);

		const newRows = data.items?.filter((row) => row.uid !== item?.uid);
		const newItem = _.cloneDeep(item);

		newItem.plu_isDeleted = 1;

		const token = await getAccessTokenSilently();

		const newRowData = {
			updates: [item],
		};
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};
		setTimeout(() => {
			axios
				.post(API_ENDPOINTS.plu, newRowData, config) // Using the endpoint from API_ENDPOINTS
				.then(function (response) {
					if (response.status === 200) {
						setSnackbarMessage({
							message: ["Item successfully deleted"],
							duration: 2000,
							severity: "success",
						});
						setSnackbarOpen(true);

						navigate("/items");
						setIsSaving(false);
					} else {
						setSnackbarMessage({
							message: ["ERROR couldn't delete item."],
							duration: 8000,
							severity: "error",
						});
						setSnackbarOpen(true);

						setIsSaving(false);
					}
				})
				.catch(function (error) {
					console.log("res", error);
				});

			setUnsavedChanges(false);
			setData({ ...data, items: newRows });
		}, 2000);
	};

	const handleGroupChange = (event) => {
		setItem({
			...item,
			plu_details: {
				...item.plu_details,
				salesGroup: event.target.value.salesGroup_number,
			},
		});
	};

	const handleTagChange = (event, value) => {
		const tagNumbers = value.map((value) => value.tag_ref);
		setItem({
			...item,
			plu_details: { ...item.plu_details, tags: tagNumbers },
		});
	};

	const config = {
		bucketName: process.env.REACT_APP_BUCKET_NAME,
		region: process.env.REACT_APP_REGION,
		accessKeyId: process.env.REACT_APP_ACCESS,
		secretAccessKey: process.env.REACT_APP_SECRET,
		GENERATE_SOURCEMAP: false,
	};

	const handleFileInput = (e) => {
		console.log(e);

		// setSelectedFile(e.target.files[0]);
		const ReactS3Client = new S3(config);
		// the name of the file uploaded is used to upload it to S3
		const fileName = item.plu_number;
		ReactS3Client.uploadFile(e.target.files[0], fileName)
			.then((data) => {
				console.log(data);
				setProductImage(data.location);
				setItem({
					...item,
					plu_details: {
						...item.plu_details,
						imgUrl: data.location,
					},
				});
			})

			.catch((err) => console.log(err));
	};

	const handleFileDelete = (e) => {
		// setSelectedFile(null);
		const ReactS3Client = new S3(config);
		// fileInput.file = null;
		ReactS3Client.deleteFile(item.plu_details.imgUrl)
			.then((data) => {
				console.log(data);
				setProductImage("");
				setItem({
					...item,
					plu_details: {
						...item.plu_details,
						imgUrl: "",
					},
				});
			})

			.catch((err) => console.log(err));
	};

	const [error, setError] = useState({ open: false, text: "" });
	const [unsavedChanges, setUnsavedChanges] = useState(false);

	if (
		!data.items ||
		!data.screens ||
		!data.categories ||
		!data.majorcategories ||
		!data.priceband
	)
		return <Spinner />;
	return (
		<Card
			sx={{
				maxWidth: "100%",
				minHeight: "100%",

				display: "flex",
				flexDirection: "column",
				flex: 1,
			}}
		>
			<ErrorDialog
				onClose={() => navigate("/items")}
				isSaving={isSaving}
				errorMessage={error}
				setErrorMessage={setError}
			/>

			<Box
				sx={{
					maxWidth: "100%",
					pt: 3,
					pb: 2,
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					// height: 50,
				}}
			>
				<RouterBreadcrumbs
					setErrorMessage={setError}
					unsavedChanges={unsavedChanges}
					saveChanges={saveChanges}
				/>
				<Box
					sx={{
						// width: "100%",
						// position: "sticky",
						// bottom: 20,
						// right: 0,
						display: "flex",
						flexDirection: "row",
						alignSelf: "flex-end",
						backgroundColor: "white",
						justifyContent: "space-between",
						alignItems: "space-between",
						// flex: 1,
						gap: 2,
						// // backgroundColor: "white",
						// pr: 4,
						// pl: 4,
						// height: "10%",
						// ml: 200,
						// pb: 2,
					}}
				>
					<AccessControl
						userPermissions={userPermission}
						unallowedPermissions={{
							page: "items",
							// allow: false,
							strict: false,

							action: "delete",
						}}
						// renderNoAccess={() => <NoAccess permissionsNeeded="read:stats" />}
						renderNoAccess={() => <></>}
					>
						<MainButton
							text="Delete"
							variant="outlined"
							// width={"5%"}
							icon={<DeleteOutline size={15} />}
							onClick={handleDelete}
							// onClick={() => setModalVisible(!modalVisible)}
						/>
					</AccessControl>
					<AccessControl
						userPermissions={userPermission}
						unallowedPermissions={{
							page: "items",
							// allow: false,
							action: "edit",
							strict: false,
						}}
						// renderNoAccess={() => <NoAccess permissionsNeeded="read:stats" />}
						renderNoAccess={() => <></>}
					>
						<LoadingButton
							// color={Colours.gsblue}
							size="small"
							onClick={saveChanges}
							loading={isSaving}
							loadingPosition="start"
							startIcon={<SaveIcon />}
							variant="gradient"
							sx={{}}
						>
							<span>Save</span>
						</LoadingButton>
					</AccessControl>
				</Box>
			</Box>

			<Box
				sx={{
					height: "80%",
					display: "flex",
					flex: 1,
					flexDirection: "column",
					border: "1px solid #f0f0f0",
					borderRadius: 2,
					padding: 5,
					mb: 4,
				}}
			>
				<Box
					sx={{
						width: "100%",
						// mb: 2,
						height: "100%",
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						gap: 5,
					}}
				>
					<Box
						sx={{
							width: "50%",
							display: "flex",
							flexDirection: "column",
							gap: 2,
							height: "100%",
						}}
					>
						<Box
							sx={{
								width: "100%",
								display: "flex",
								flexDirection: "row",
								gap: 2,
							}}
						>
							<Box
								sx={{
									width: "60%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-between",
									gap: 2,
								}}
							>
								<CustomTextField
									value={item?.plu_name ?? ""}
									type="standard"
									// multiline={false}
									disabled={
										!checkPermissions(userPermission, {
											page: "items",
											// allow: false,
											strict: false,

											action: "edit",
										})
									}
									label={
										<Typography
											sx={{
												fontSize: 18,
												mb: 0,
												color: Colours.gsblue,
												justifySelf: "center",
											}}
										>
											Name
										</Typography>
									}
									onChange={handleItemName}
								/>
								<Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
									<CustomTextField
										value={item?.plu_details?.price}
										disabled={
											!checkPermissions(userPermission, {
												page: "items",
												// allow: false,
												strict: false,

												action: "edit",
											})
										}
										id="price"
										type="price"
										label={
											<Typography
												sx={{
													fontSize: 18,
													mb: 0,
													color: Colours.gsblue,
													justifySelf: "center",
												}}
											>
												Base Price
											</Typography>
										}
										width={isRetail ? "40%" : "100%"}
										onChange={(event) =>
											setItem({
												...item,
												plu_details: {
													...item.plu_details,
													price: event.target.value,
												},
											})
										}
									/>
									{isRetail && (
										<CustomTextField
											value={item?.plu_details?.barcode}
											type="standard"
											multiline={false}
											disabled={
												!checkPermissions(userPermission, {
													page: "items",
													// allow: false,
													strict: false,

													action: "edit",
												})
											}
											width={"60%"}
											label={
												<Typography
													sx={{
														fontSize: 18,
														mb: 0,
														color: Colours.gsblue,
														justifySelf: "center",
													}}
												>
													Barcode
												</Typography>
											}
											onChange={(event) =>
												setItem({
													...item,
													plu_details: {
														...item.plu_details,
														barcode: event.target.value,
													},
												})
											}
										/>
									)}
								</Box>
								<OptionSelect
									options={data?.categories.filter(
										(i) => i.salesGroup_isDeleted === 0
									)}
									value={data?.categories
										?.filter((i) => i.salesGroup_isDeleted === 0)
										.find(
											(v) =>
												parseInt(v?.salesGroup_number) ===
												parseInt(item?.plu_details.salesGroup)
										)}
									label={"Category"}
									onChange={handleGroupChange}
									type="salesGroup"
									width={"100%"}
									disabled={
										!checkPermissions(userPermission, {
											page: "items",
											// allow: false,
											strict: false,

											action: "edit",
										})
									}
									// addButton
									// onClickAddButton={handleNewVariant}
									// open={openDropDown}
									// setOpen={setOpenDropdown}
									// mb={2}
								/>
							</Box>
							<Box sx={{ flexDirection: "row", width: "40%" }}>
								<Typography
									sx={{
										fontSize: 14,
										mb: 0.8,
										color: Colours.gsblue,
										justifySelf: "center",
									}}
								>
									Product Image
								</Typography>
								<ImageUpload
									handleFileInput={handleFileInput}
									handleFileDelete={handleFileDelete}
									item={item}
									image={productImage}
									setImage={setProductImage}
									variant="square"
								/>
							</Box>
						</Box>

						<Box
							sx={{
								width: "100%",
								display: "flex",
								flexDirection: "row",
								gap: 2,
								// height: 150,
							}}
						>
							<CustomTextField
								value={item?.plu_details?.longDescription}
								type="standard"
								multiline={true}
								disabled={
									!checkPermissions(userPermission, {
										page: "items",
										// allow: false,
										strict: false,

										action: "edit",
									})
								}
								label={
									<Typography
										sx={{
											fontSize: 18,
											mb: 0,
											color: Colours.gsblue,
											justifySelf: "center",
										}}
									>
										Description
									</Typography>
								}
								onChange={(event) => {
									setUnsavedChanges(true);
									setItem({
										...item,
										plu_details: {
											...item.plu_details,
											longDescription: event.target.value,
										},
									});
								}}
							/>
						</Box>

						<AutoCompleteTagCell
							tags={item?.plu_details?.tags}
							onChange={handleTagChange}
							limit={5}
							label={
								<Typography
									sx={{
										fontSize: 18,
										mb: 0,
										color: Colours.gsblue,
										justifySelf: "center",
									}}
								>
									Tags
								</Typography>
							}
							height={130}
							type="individualItem"
							disabled={
								!checkPermissions(userPermission, {
									page: "items",
									// allow: false,
									strict: false,

									action: "edit",
								})
							}
						/>
					</Box>

					<Box sx={{ width: "50%", height: "100%" }}>
						<Accordions
							item={item}
							setItem={setItem}
							modifiers={modifiers}
							setModifiers={setModifiers}
							appears={appears}
						/>
					</Box>
				</Box>
			</Box>
		</Card>
	);
}

export const TextBox = styled(motion.div)`
	color: #fff;
	padding: 0.5rem;
	background: #f3f6f9;
	overflow: hidden;
	z-index: 2;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 2;
`;

export const ImageBox = styled(motion.div)(
	(props) => `
	position: absolute;
	// top: 0;
	// left: 0;
	width: 100%;
	height: 100%;
	background: url(${
		props.productimage
			? props.productimage
			: "https://max-cloud-images.s3-eu-west-1.amazonaws.com/place-holder.png"
	});
	background-size: cover;
	transition: transform 0.3s;
	background-position: center;
`
);

export const BoxContainer = styled(motion.div)`
	width: 100%;
	height: 200px;
	border-radius: 5px;
	background: #fff;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	overflow: hidden;
	position: relative;
`;
