import React from "react";
import { useDndContext } from "@dnd-kit/core";
import { DataGridContext } from "../../datagrids/data grid files/DataGridContext";
import GridContext from "../context/GridContext";
import { Typography } from "@mui/material";
import { motion } from "framer-motion";

export default function DragOverlayItem({ id }) {
	const { items, variant } = React.useContext(GridContext);
	const { data } = React.useContext(DataGridContext);
	let item = variant.list_details.buttons.find((item) => item.id === id);

	let background = data?.colours[0]?.palette_details?.colours.find(
		(col) => col.index === item?.style?.background
	);

	let foreground = data?.colours[0]?.palette_details?.colours.find(
		(col) => col.index === item?.style?.foreground
	);
	// console.log(item);
	// console.log(data.colours[0].palette_details.colours);
	// const allItems = [...items];
	// DragOver seems to cache this component so I can't tell if the item is still actually active
	// It will remain active until it has settled in place rather than when dragEnd has occured
	// I need to know when drag end has taken place to trigger the scale down animation
	// I use a hook which looks at DndContex to get active
	const isReallyActive = useDndIsReallyActiveId(id);
	return (
		<motion.div
			style={{
				//   ...styles.cardStyles,
				backgroundColor: background?.colour || "black",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				width: "100%",
				height: "100%",
				padding: 0,
				transform: isReallyActive ? "scale(1.05)" : "none",
			}}
			animate={{
				scale: [1.2, 1, 1.2],
				transition: {
					duration: 1.5,
					repeat: "Infinity",
					repeatType: "loop",
				},
			}}
			// variants={button}
			initial="rest"
		>
			<Typography
				// className="card-title"
				sx={{
					color: foreground?.colour || "white",
					// color: "pink",
					fontSize: JSON.parse(item.style.font.match(/\d+/)[0]),
					alignSelf:
						item.style.alignmentV === 1
							? "center"
							: item.style.alignmentV === 0
							? "flex-start"
							: "flex-end",
					fontWeight: item.style.font.replace(/[0-9]/g, "") === "B" ? 700 : 400,
					textAlign: item.style.alignmentH === 1 ? "center" : "left",
					userSelect: "none",
					display: item.hideText === 1 ? "none" : null,
					overflow: "hidden",
					whiteSpace: "pre-line",
				}}
			>
				{item.functionType === 13
					? `Server ${parseInt(item.functionValue)}`
					: item?.text?.replaceAll("|", "\n")}
			</Typography>
		</motion.div>
	);
}
function useDndIsReallyActiveId(id) {
	const context = useDndContext();
	const isActive = context.active?.id === id;
	return isActive;
}
