import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Typography } from "@mui/material";
import React, { useContext } from "react";
import Spinner from "../components/Spinner/Spinner";
import { MyFallbackComponent } from "../views/FallBackComponent";
import AccessControl from "../views/AccessControl";
import NoPermission from "../views/NoPermission";
import { DataGridContext } from "../views/datagrids/data grid files/DataGridContext";
import { useFetch } from "../views/profile/useFetch";

export const AuthenticationGuard = ({ component, page, friendlyName }) => {
	// const { error, loggedInUser } = useAuth0();

	const { error, loggedInUser, isAuthenticated } = useFetch();

	// const { userPermission } = useContext(DataGridContext);
	// const userPermissions = loggedInUser?.app_metadata?.user_permissions?.rules;

	// const userPermission = {
	// 	name: "",
	// 	permissions: [],
	// };
	let userPermission = {
		name: "",
		permissions: [],
		userTypePermissions: [],
	};
	// console.log(loggedInUser);
	if (loggedInUser) {
		// const userTypePermissions =
		// 	loggedInUser?.app_metadata?.userType_permissions?.rules;
		// const userPermissions = loggedInUser?.app_metadata?.user_permissions?.rules;
		// console.log("UT permissions", userTypePermissions);
		// console.log("U permissions", userPermissions);
		// console.log("loggedinuser", loggedInUser);

		(loggedInUser?.app_metadata?.userType_permissions?.rules || []).forEach(
			(rule) => {
				userPermission.permissions.push(rule);
			}
		);

		(loggedInUser?.app_metadata?.user_permissions?.rules || []).forEach(
			(rule) => {
				userPermission.permissions.push(rule);
			}
		);

		userPermission.permissions.forEach((rule) => {
			if (!(rule.page instanceof Array)) rule.page = [rule.page];
			if (!(rule.api instanceof Array)) rule.api = [rule.api];
			if (!rule.actions && rule.action) rule.actions = rule.action;
			if (!(rule.actions instanceof Array)) rule.actions = [rule.actions];

			if (rule.page.includes("plu") && !rule.page.includes("items"))
				rule.page.push("items");
			if (rule.page.includes("list") && !rule.page.includes("screens"))
				rule.page.push("screens");
		});
	}

	const Component = withAuthenticationRequired(component, {
		onRedirecting: () => <Spinner />,
	});

	if (error) {
		return <MyFallbackComponent error={error.message} />;
	}
	if (userPermission.permissions.length === 0) {
		// change to spinner when permissions finished
		// return <Component {...props} />;
		return <Spinner />;
	}
	console.log(userPermission);
	return (
		<AccessControl
			userPermissions={userPermission}
			unallowedPermissions={{
				page:page,
				// allow: false,
				action: "view",
				strict: false,
			}}
			renderNoAccess={() => (
				<NoPermission
					dbName={friendlyName ? friendlyName : page}
				/>
			)}
		>
			<Component  />
		</AccessControl>
	);
};
