import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Typography } from "@mui/material";
import { Colours } from "../assets/global/Theme-variable";

const RadioButton = ({ checked }) => (
	<svg width="25px" height="25px" viewBox="0 0 24 24" fontSize="18px">
		<circle
			cx="50%"
			cy="50%"
			r="11px"
			stroke="#c4c4c4"
			strokeWidth="1px"
			fill="none"
		/>
		{checked && <circle cx="50%" cy="50%" r="6px" fill={Colours.gsblue} />}
	</svg>
);

export default function RadioButtonsGroup({ value, onChange }) {
	return (
		<FormControl
			sx={{
				width: "100%",
				"& .MuiRadioGroup-row": {
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					width: "100%",
				},
			}}
		>
			{/* <FormLabel id="demo-row-radio-buttons-group-label">Item Type</FormLabel> */}
			<RadioGroup
				row
				aria-labelledby="demo-row-radio-buttons-group-label"
				name="row-radio-buttons-group"
				value={value}
				onChange={onChange}
				// sx={{
				// 	".MuiRadioGroup-root": {
				// 		display: "flex",
				// 		flexDirection: "row",
				// 		justifyContent: "space-between",
				// 		alignItems: "center",
				// 		width: "100%",
				// 	},
				// }}
			>
				<FormControlLabel
					value="Item"
					control={
						<Radio
							icon={<RadioButton />}
							checkedIcon={<RadioButton checked />}
						/>
					}
					label={
						<Typography
							sx={{
								fontSize: 14,
							}}
						>
							Item
						</Typography>
					}
				/>
				<FormControlLabel
					value="Modifier"
					control={
						<Radio
							icon={<RadioButton />}
							checkedIcon={<RadioButton checked />}
						/>
					}
					label={<Typography sx={{ fontSize: 14 }}>Modifier</Typography>}
				/>
				<FormControlLabel
					value="ItemWithMod"
					control={
						<Radio
							icon={<RadioButton />}
							checkedIcon={<RadioButton checked />}
						/>
					}
					label={<Typography sx={{ fontSize: 14 }}>Item + Modifier</Typography>}
				/>
			</RadioGroup>
		</FormControl>
	);
}
