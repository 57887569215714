import React from "react";
import {
	Box,
	FormControl,
	InputLabel,
	OutlinedInput,
	Popover,
	Typography,
} from "@mui/material";
import PopUpColourPicker from "../../../grid/components/PopUpColourPicker";
import { useGridApiContext } from "@mui/x-data-grid";
import colours from "../../../grid/data/colours";

export const ColourPicker = (props) => {
	const { id, value, field } = props;
	const ref = useGridApiContext();
	console.log(value);

	// const colourArr = Object.values(colours);
	console.log(colours.find((colour) => colour.colour === value));
	const [currentColour, setCurrentColour] = React.useState(
		colours.find((colour) => colour.colour === value)?.colour || value
	);

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleOpenPopover = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClosePopover = () => {
		setAnchorEl(null);
	};

	const popoverOpen = Boolean(anchorEl);
	const popoverid = popoverOpen ? "simple-popover" : undefined;

	const handleColourChange = async (colour) => {
		setCurrentColour(colour);
		console.log(colour);
		await ref.current.setEditCellValue({
			id,
			field,
			value: colour,
		});
		ref.current.stopCellEditMode({ id, field });
	};

	return (
		<Box>
			<Box
				sx={{
					backgroundColor: currentColour,
					width: 20,
					height: 20,
					borderRadius: 1,

					// display: "flex",
					// justifyContent: "center",
					// alignItems: "center",
				}}
				onClick={handleOpenPopover}
			></Box>

			<Popover
				id={popoverid}
				open={popoverOpen}
				anchorEl={anchorEl}
				onClose={handleClosePopover}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<PopUpColourPicker
					selectedColour={currentColour}
					handleColourChange={handleColourChange}
					type="button"
					label="Button colour"
				/>
			</Popover>
		</Box>
	);
};
