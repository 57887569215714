import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@mui/material";
import { Box, Button } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import LogoIcon from "../components/LogoIcon";
import { Colours } from "../assets/global/Theme-variable";
import GradientText from "../components/GradientText";

export default function NoPermission({ ...props }) {
	const navigate = useNavigate();
	return (
		<Box
			sx={{
				flex: 1,
				minHeight: "100vh",
				// width: "100",
				gap: 4,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			{" "}
			<GradientText text="Oops!" />
			<Typography sx={{ fontSize: 14 }}>
				You don't have the correct permissions to
				{props.text ? props.text : ` view  ${props.dbName}`}
			</Typography>
			<Button variant="contained">
				<Link
					to={".."}
					onClick={(e) => {
						e.preventDefault();
						navigate(-1);
					}}
					style={{ textDecoration: "none", color: "white" }}
				>
					Go Back
				</Link>
			</Button>
		</Box>
	);
}
