import React from "react";
import {
	Card,
	CardContent,
	Divider,
	Box,
	Typography,
	TextField,
	FormControlLabel,
	Checkbox,
	Button,
	Grid,
	RadioGroup,
	Radio,
	FormControl,
	MenuItem,
} from "@mui/material";

export default function SelectList({
	option,
	options,
	setOption,
	label,
	defaultoption,
	onChange,
	width,
	marginBottom,
}) {
	// const handleChange = (event) => {
	// 	setOption(event.target.value);
	// };
	return (
		<TextField
			fullWidth
			id="standard-select-number"
			variant="outlined"
			select
			label={label}
			size={"small"}
			value={option && option}
			onChange={onChange}
			sx={{
				width: width,
				marginBottom: marginBottom && marginBottom,
			}}
			defaultValue={defaultoption && defaultoption} // To solve a web error the word defaultOption has now been chnaged all over to defaultoption
		>
			{options.map((option) => (
				<MenuItem
					key={
						option.value
							? option.value
							: option.name
							? option.name
							: option.amount
							? option.amount
							: option.type
							? option.type
							: option
					}
					value={option}
				>
					{option.value
						? option.value
						: option.name
						? option.name
						: option.amount
						? option.amount
						: option.type
						? option.type
						: option}
				</MenuItem>
			))}
		</TextField>
	);
}
