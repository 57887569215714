import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { Controller } from "react-hook-form";
import { MenuItem, Select, Typography } from "@mui/material";

const ReactHookFormSelect = ({
	name,
	label,
	control,
	defaultValue,
	children,
	rules,
	...props
}) => {
	const labelId = `${name}-label`;
	console.log(children);
	return (
		<>
			<FormControl sx={{ width: "100%" }} variant="outlined">
				<InputLabel shrink id={labelId}>
					{label}
				</InputLabel>
				<Controller
					render={({ field: { onChange, value, ref, onBlur } }) => (
						<Select
							labelId={labelId}
							label={label}
							size="small"
							id={labelId}
							notched
							onChange={onChange}
							fullWidth
							value={value ? value : ''}
							sx={{
								width: "100%",
							}}
							MenuProps={{
								PaperProps: {
									style: {
										maxHeight: 48 * 4.5 + 8,
										width: 250,
									},
								},
							}}
						>
							{children.length > 0 ? (
								children
							) : (
								<MenuItem disabled>No options available</MenuItem>
							)}
						</Select>
					)}
					name={name}
					control={control}
					// defaultValue={defaultValue}
					rules={rules}
				/>
			</FormControl>
		</>
	);
};
export default ReactHookFormSelect;
