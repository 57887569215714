import { Box, IconButton, Modal, Typography } from "@mui/material";
import React from "react";
import NewListForm from "./NewListForm";
import CloseIcon from "@mui/icons-material/Close";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	borderRadius: "20px",
	width: 400,
	minHeight: 400,
	bgcolor: "white",
	boxShadow: 24,
	p: 4,
	display: "flex",
	flexDirection: "column",
};

export default function NewListModal({
	modalVisible,
	setModalVisible,
	setPage,
	onSubmit,
	isSaving,
}) {
	return (
		<Modal open={modalVisible}>
			<Box sx={style}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						mb: 2,
					}}
				>
					<Typography variant="h3">New List</Typography>

					<IconButton
						aria-label="close"
						sx={{ alignSelf: "flex-end" }}
						onClick={() => setModalVisible(!modalVisible)}
					>
						<CloseIcon />
					</IconButton>
				</Box>
				<NewListForm
					onSubmit={onSubmit}
					modalVisible={modalVisible}
					setModalVisible={setModalVisible}
					isSaving={isSaving}
				/>
			</Box>
		</Modal>
	);
}
