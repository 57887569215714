// export const modifiers = [
// 	{ number: 1, name: "Half", prefix: "HLF" },
// 	{ number: 2, name: "Double", prefix: "DBL" },
// 	{ number: 3, name: "125ml", prefix: "125ml" },
// 	{ number: 4, name: "175ml", prefix: "175ml" },
// 	{ number: 5, name: "250ml", prefix: "250ml" },
// 	{ number: 6, name: "1/3", prefix: "1TH" },
// 	{ number: 7, name: "2/3", prefix: "2TH" },
// ];

export const modifiers = (modifierData) => {
	//  `modifierData` contains the real modifier data which is context
	const realData = modifierData;

	// Dynamically generate the `modifiers` array based on `realData`
	return realData
		.filter((i) => i.modifier_isDeleted === 0)
		.map((modifier) => ({
			number: parseInt(modifier.modifier_number), // ParseInt to convert string to number
			name: modifier.modifier_name,
			prefix: modifier.modifier_details.prefix,
		}));
	// .filter((i) => i.modifier_isDeleted === 0);
};
