import { motion, useMotionValue, useTransform } from "framer-motion";
import { Colours } from "../assets/global/Theme-variable";
import { useMediaQuery } from "@mui/material";

const magenta = "#6FADA2";
const blue = "#40f";
const purple = Colours.gsblue;

const GradientText = (props) => {
	const matches = useMediaQuery("(min-width:600px)");

	return (
		<motion.span
			style={{
				fontFamily: "DM Sans",
				fontWeight: 600,
				fontSize: matches ? 90 : 70,
				WebkitBackgroundClip: "text",
				textAlign: "left",
				lineHeight: 1,
				WebkitTextFillColor: "transparent",
				backgroundImage: `linear-gradient(to right, ${purple}, ${magenta}, ${blue})`,
			}}
			animate={{
				backgroundImage: [
					`linear-gradient(to right, ${purple}, ${magenta}, ${blue})`,
					`linear-gradient(to right, ${magenta}, ${blue}, ${purple})`,
					`linear-gradient(to right, ${blue}, ${purple}, ${magenta})`,
					`linear-gradient(to right, ${purple}, ${magenta}, ${blue})`,
				],
			}}
			transition={{
				repeat: Infinity,
				ease: "linear",
				duration: 3,
			}}
		>
			{props.text ? props.text : "Optimise your operations with Max Cloud"}
		</motion.span>
	);
};

export default GradientText;
