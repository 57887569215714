import colours from "./colours";

const sampleItems = [
	{
		id: 1,
		index: 1,
		colour: colours[26],
		text: "Budweiser",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Budweiser", number: 1},
		textColour: "white",
		fontSize: 18,
		buttonType: "PLU",
	},
	{
		id: 2,
		index: 2,
		colour: colours[27],
		text: "House Red",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		funct: {name: "House Red", number: 1},
		textAlign: "center",
		fontSize: 18,
		textColour: "white",
		buttonType: "PLU",
	},

	{
		id: 3,
		index: 3,
		colour: colours[28],
		text: "Prosecco",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Prosecco", number: 1},
		fontSize: 18,
		textColour: "white",
		buttonType: "PLU",
	},
	{
		id: 4,
		index: 4,
		colour: colours[29],
		text: "Magners",
		row: 1,
		col: 1,
		textAlign: "center",
		buttonSize: "1x1",
		funct: {name: "Magners", number: 1},
		fontSize: 18,
		textColour: "white",
		buttonType: "PLU",
	},

	{
		id: 5,
		index: 5,
		colour: colours[26],
		text: "Bulmers",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",

		funct: {name: "Bulmers", number: 1},
		fontSize: 18,
		textColour: "white",
		buttonType: "PLU",
	},
	{
		id: 6,
		index: 6,
		colour: colours[29],
		text: "San Miguel",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "San Miguel", number: 1},
		fontSize: 18,
		textColour: "white",
		buttonType: "PLU",
	},
	{
		id: 7,
		index: 7,
		colour: colours[29],
		text: "Vodka",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",

		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "white",
		buttonType: "PLU",
	},
	{
		id: 8,
		index: 8,
		colour: colours[41],
		text: "Tequila",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",

		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "white",
		buttonType: "PLU",
	},

	{
		id: 9,
		index: 9,
		colour: colours[26],
		text: "Jack Daniels",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",

		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "white",
		buttonType: "PLU",
	},
	{
		id: 10,
		index: 10,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",

		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},

	{
		id: 11,
		index: 11,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 12,
		index: 12,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		Size: 18,
		textColour: "white",
		buttonType: "PLU",
	},
	{
		id: 13,
		index: 13,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 14,
		index: 14,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 15,
		index: 15,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 16,
		index: 16,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 17,
		index: 17,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 18,
		index: 18,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 19,
		index: 19,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 20,
		index: 20,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 21,
		index: 21,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 22,
		index: 22,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 23,
		index: 23,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 24,
		index: 24,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 25,
		index: 25,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 26,
		index: 26,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 27,
		index: 27,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 28,
		index: 28,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 29,
		index: 29,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 30,
		index: 30,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 31,
		index: 31,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 32,
		index: 32,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 33,
		index: 33,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 34,
		index: 34,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 35,
		index: 35,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 36,
		index: 36,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 37,
		index: 37,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 38,
		index: 38,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 39,
		index: 39,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 40,
		index: 40,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 41,
		index: 41,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 42,
		index: 42,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 43,
		index: 43,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 44,
		index: 44,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
		buttonType: "PLU",
	},
	{
		id: 45,
		index: 45,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
	},
	{
		id: 46,
		index: 46,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
	},
	{
		id: 47,
		index: 47,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
	},
	{
		id: 48,
		index: 48,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
	},
	{
		id: 49,
		index: 49,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
	},
	{
		id: 50,
		index: 50,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "white",
		fontSize: 18,
	},
	{
		id: 51,
		index: 51,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textAlign: "center",
		fontSize: 18,
		textColour: "white",
	},
	{
		id: 52,
		index: 52,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "white",
	},
	{
		id: 53,
		index: 53,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		textAlign: "center",
		buttonSize: "1x1",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "white",
	},
	{
		id: 54,
		index: 54,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "white",
	},
	{
		id: 55,
		index: 55,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "white",
	},
	{
		id: 56,
		index: 56,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "white",
	},
	{
		id: 57,
		index: 57,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "white",
	},
	{
		id: 58,
		index: 58,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "white",
	},
	{
		id: 59,
		index: 59,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
	},
	{
		id: 60,
		index: 60,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
	},
	{
		id: 61,
		index: 61,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
	},
	{
		id: 62,
		index: 62,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
	},
	{
		id: 63,
		index: 63,
		colour: colours[63],
		text: "",
		row: 1,
		col: 1,
		buttonSize: "1x1",
		textAlign: "center",
		funct: {name: "Bar", number: 1},
		fontSize: 18,
		textColour: "black",
	},
];

export default sampleItems;
