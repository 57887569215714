const standardListLayouts = [
	{
		value: "3x2",
		row: 2,
		col: 3,
	},
	{
		value: "4x2",
		row: 2,
		col: 4,
	},

	{
		value: "3x3",
		row: 3,
		col: 3,
	},
	{
		value: "4x3",
		row: 3,
		col: 4,
	},
	{
		value: "4x4",
		row: 4,
		col: 4,
	},
	{
		value: "4x5",
		row: 5,
		col: 4,
	},
	{
		value: "5x1",
		row: 1,
		col: 5,
	},
	{
		value: "5x4",
		row: 4,
		col: 5,
	},
	{
		value: "6x4",
		row: 4,
		col: 6,
	},
	{
		value: "6x5",
		row: 5,
		col: 6,
	},
	{
		value: "7x5",
		row: 5,
		col: 7,
	},
	{
		value: "10x12",
		row: 12,
		col: 10,
	},
];

export default standardListLayouts;
