import * as React from "react";
import Box from "@mui/material/Box";
import {
	useGridApiContext,
	GridToolbarQuickFilter,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
	GridPrintExportMenuItem,
	GridCsvExportMenuItem,
	GridCsvExportOptions,
	gridFilteredSortedRowIdsSelector,
	gridVisibleColumnFieldsSelector,
	gridFilterModelSelector,
} from "@mui/x-data-grid";
import {
	Button,
	Grid,
	InputAdornment,
	Menu,
	MenuItem,
	OutlinedInput,
	Switch,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import PricingFilterSites from "../../../grid/components/PricingFilterSites";
import PricingFilterPricebands from "../../../grid/components/PricingFilterPricebands";
import AccessControl from "../../../AccessControl";
import CustomSearch from "../../../../components/CustomSearch";
import { DataGridContext } from "../../data grid files/DataGridContext";
import RouterBreadcrumbs from "../../../../components/Breadcrumbs";
import axios from "axios";
import { API_ENDPOINTS } from "../../../../API/apiConfig";
import { useAuth0 } from "@auth0/auth0-react";
import { motion } from "framer-motion";
import { LoadingButton } from "@mui/lab";

export default function CustomToolbar({
	modalVisible,
	setModalVisible,
	modalConfig,
	setModalConfig,
	setImportModalVisible,
	importModalVisible,
	selectionModel,
	gridList,
	selectedRowsData,
	page,
	searchValue,
	requestSearch,
	showDeleted,
	handleSearchValueChange,
	exportFields,
	setNewItemModalOpen,
	NewItemModalOpen,
	setSnackbar,
	priceBandsEnabled,
	handlePriceBandsToggle,
	toggleShowDeleted,
	filterModel,
	setFilterModel,
	table,
	contextName,
}) {
	const apiRef = useGridApiContext();

	const { data, setData, userPermission, loggedInUser } =
		React.useContext(DataGridContext);

	const label = { inputProps: { "aria-label": "Switch demo" } };

	//const [priceBandsEnabled, setPriceBandsEnabled] = React.useState(false);

	// exportFields not contains the field names and is built in DataGrid.JS as the columns are created from DataGridConfig
	// these fields also include the dymnanic priceModifier field/column creation too
	// exportFields is pass through here via a paramter

	//const { priceBandsEnabled, handlePriceBandsToggle } = usePriceBandsToggle();

	const csvOptions = {
		fileName: `${gridList.gridPage}`,
		// delimiter: ";",
		delimiter: ",",
		utf8WithBom: true,
		fields: exportFields,
	};

	const printOptions = {
		fileName: `${gridList.gridPage}`,
		allColumns: false,
		fields: exportFields,
		hideFooter: true,
		hideToolbar: true,
	};

	const [anchorElMenu, setAnchorElMenu] = React.useState(null);
	const openMenu = Boolean(anchorElMenu);

	const getJson = (apiRef) => {
		// Select rows and columns
		const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
		const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

		// Format the data. Here we only keep the value
		const data = filteredSortedRowIds.map((id) => {
			const row = {};
			visibleColumnsField.forEach((field) => {
				row[field] = apiRef.current.getCellParams(id, field).value;
			});
			return row;
		});

		// Stringify with some indentation
		return JSON.stringify(data, null, 2);
	};

	const exportBlob = (blob, filename) => {
		// Save the blob in a json file
		const url = URL.createObjectURL(blob);

		const a = document.createElement("a");
		a.href = url;
		a.download = filename;
		a.click();

		setTimeout(() => {
			URL.revokeObjectURL(url);
		});
	};

	function JsonExportMenuItem(props) {
		const apiRef = useGridApiContext();

		const { hideMenu } = props;
		return (
			<AccessControl
				userPermissions={userPermission}
				unallowedPermissions={{
					page: page,
					// allow: false,
					strict: false,

					action: "export",
				}}
				// renderNoAccess={() => <NoAccess permissionsNeeded="read:stats" />}
				renderNoAccess={() => <></>}
			>
				<MenuItem
					onClick={() => {
						const jsonString = getJson(apiRef);
						const blob = new Blob([jsonString], {
							type: "text/json",
						});
						exportBlob(blob, `${page}`);

						// Hide the export menu after the export
						hideMenu?.();
					}}
				>
					Export JSON
				</MenuItem>
			</AccessControl>
		);
	}

	// const displayQuickFilter = () => {
	// 	if (gridList.quickFilter === true) {
	// 		return <GridToolbarQuickFilter />;
	// 	} else {
	// 		return null;
	// 	}
	// };
	// const displayColumnButton = () => {
	// 	if (gridList.displayColumnsButton === true) {
	// 		return (
	// 			<GridToolbarColumnsButton
	// 				startIcon={<BsLayoutThreeColumns color={Colours.gsblue} size={20} />}
	// 			/>
	// 		);
	// 	} else {
	// 		return null;
	// 	}
	// };
	// const showFilterButton = () => {
	// 	if (gridList.displayFilterButton === true) {
	// 		return (
	// 			<GridToolbarFilterButton
	// 				startIcon={<BsLayoutThreeColumns color={Colours.gsblue} />}
	// 			/>
	// 		);
	// 	} else {
	// 		return null;
	// 	}
	// };
	const showExport = () => {
		if (gridList.allowExport === true) {
			return (
				<AccessControl
					userPermissions={userPermission}
					unallowedPermissions={{
						page: page,
						// allow: false,
						strict: false,

						action: "export",
					}}
					// renderNoAccess={() => <NoAccess permissionsNeeded="read:stats" />}
					renderNoAccess={() => <></>}
				>
					<Button
						variant="outlined"
						size="medium"
						onClick={(event) => {
							setAnchorElMenu(event.currentTarget);
						}}
						className="exportButton"
					>
						Export
					</Button>
				</AccessControl>
			);
		} else {
			return null;
		}
	};

	const openNewModal = () => {
		setModalConfig({ focusField: "", editRow: {}, isNew: true });
		setModalVisible(true);
	};

	const showModalButton = () => {
		if (gridList.allowModalButton === true) {
			return (
				<AccessControl
					userPermissions={userPermission}
					unallowedPermissions={{
						page: page,
						// allow: false,
						action: "create",
						strict: false,
					}}
					renderNoAccess={() => <></>}
				>
					<Button
						className="new"
						variant="gradient"
						size="medium"
						onClick={openNewModal}
					>
						{gridList.modalButtonTitle}
					</Button>
				</AccessControl>
			);
		} else {
			return null;
		}
	};

	// console.log("filter model", gridFilterModelSelector(apiRef));
	const showMakeDeletedVisibleButton = () => {
		const isFiltered =
			filterModel?.items[0]?.field === `${table}_isDeleted` &&
			filterModel?.items[0]?.operator === `is` &&
			(filterModel?.items[0]?.value === `true` ||
				filterModel?.items[0]?.value === "");

		// console.log("filter model", gridFilterModelSelector(apiRef));

		if (gridList.gridPage !== "pricing") {
			return (
				<AccessControl
					userPermissions={userPermission}
					unallowedPermissions={{
						page: page,
						// allow: false,
						action: "undelete",
						strict: true,
					}}
					renderNoAccess={() => <></>}
				>
					<Button
						className="new"
						variant="gradient"
						size="medium"
						onClick={toggleShowDeleted}
					>
						{showDeleted || isFiltered
							? "Hide Deleted Items"
							: "Show Deleted Items"}
					</Button>
				</AccessControl>
			);
		}
		// } else {
		// 	return null;
		// }
	};
	const showImportButton = () => {
		if (gridList.allowImport) {
			return (
				<AccessControl
					userPermissions={userPermission}
					unallowedPermissions={{
						page: page,
						// allow: false,
						action: "create",
						strict: false,
					}}
					renderNoAccess={() => <></>}
				>
					<Button
						variant="outlined"
						size="medium"
						onClick={() => setImportModalVisible(!importModalVisible)}
						className="importButton"
					>
						Import
					</Button>
				</AccessControl>
			);
		}
	};

	const showNewItemModalButton = () => {
		if (
			gridList.gridPage ===
			"items - something else so it doesn't load the newModal"
		) {
			return (
				<AccessControl
					userPermissions={userPermission}
					unallowedPermissions={{
						page: page,
						// allow: false,
						action: "create",
						strict: false,
					}}
					renderNoAccess={() => <></>}
				>
					<Button
						variant="gradient"
						size="medium"
						// sx={{
						// 	":hover": {
						// 		backgroundColor: "#fff",
						// 		color: "#3c52b2",
						// 	},
						// }}
						onClick={() => setNewItemModalOpen(!NewItemModalOpen)}
					>
						New Item
					</Button>
				</AccessControl>
			);
		} else {
			return null;
		}
	};

	// const handlePriceBandsToggle = () => {
	// 	setPriceBandsEnabled(!priceBandsEnabled);
	// 	// Add any other logic you need here
	// };

	const showPriceBandsToggle = () => {
		if (gridList.gridPage === "pricing") {
			return (
				<Box>
					<Typography sx={{ fontSize: 15 }} variant="datagrid">
						Price Bands
					</Typography>
					<Switch
						{...label}
						checked={priceBandsEnabled}
						onChange={handlePriceBandsToggle}
						//color="primary"
					/>
				</Box>
			);
		} else {
			return null;
		}
	};
	const { getAccessTokenSilently } = useAuth0();
	const [isSyncing, setIsSyncing] = React.useState(false);

	const syncMarketMan = async () => {
		setIsSyncing(true);
		let postStatus = 0;

		const token = await getAccessTokenSilently();

		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		axios
			.get(
				`${API_ENDPOINTS["marketMan"]}`,

				config
			)
			.then(function (response) {
				postStatus = response.status;
				if (postStatus === 200) {
					setSnackbar({
						children: "successfully synced with MarketMan",
						severity: "success",
					});
					setIsSyncing(false);
				}
			})
			.catch(function (error) {
				setIsSyncing(false);

				postStatus = error.response.status;
				console.log(error, postStatus);
				setSnackbar({
					children: "Sync failed",
					severity: "success",
				});
			});
	};
	const showMarketManSync = () => {
		if (
			(loggedInUser?.app_metadata?.company_ref === "22" || loggedInUser?.app_metadata?.company_ref === "14") && gridList.gridPage === "items"
		) {
			const variants = {
				rotate: { rotate: 360, transition: { duration: 2, repeat: Infinity } },
				
			};

			return (
				<Tooltip title={"Sync with MarketMan"}>
					<Button
						variant="gradient"
						size="medium"
						// sx={{
						// 	":hover": {
						// 		backgroundColor: "#fff",
						// 		color: "#3c52b2",
						// 	},
						// }}
						// startIcon={<SyncIcon />}
						disabled={isSyncing}
						onClick={syncMarketMan}
					>
						<motion.div
							// animate={{
							// 	// scale: [1, 2, 2, 1, 1],
							// 	rotate: [180],
							// 	// borderRadius: ["0%", "0%", "50%", "50%", "0%"],
							// }}
							// transition={{
							// 	// duration: 2,
							// 	ease: "easeInOut",
							// 	// times: [0, 0.2, 0.5, 0.8, 1],
							// 	repeat: Infinity,
							// 	// repeatDelay: 1,
							// }}
							sx={{
								// height: "min-content",
								// width: "min-content",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								// height: 30,
								// width: 30,
							}}
							variants={variants}
							animate={isSyncing ? "rotate" : "stop"}

							// animate={{ rotate: isSyncing ? 360 : 0 }}
							// transition={{ repeat: isSyncing ? Infinity : 0 }}
						>
							<Box sx={{ height: 25, width: 25 }}>
								<SyncIcon />
							</Box>
						</motion.div>
					</Button>
				</Tooltip>
			);
		}
		return null;
	};

	const showPriceBandPageFilters = () => {
		if (gridList.gridPage === "pricing") {
			return <PricingFilterPricebands />;
		}
		// Return null if conditions are not met
		return null;
	};
	const showSitePageFilters = () => {
		if (gridList.gridPage === "pricing") {
			return <PricingFilterSites />;
		}
		// Return null if conditions are not met
		return null;
	};

	return (
		<GridToolbarContainer>
			{/* <Box sx={{width: "100%", display: "flex", flexDirection: "column"}}> */}
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					mb: 2,
					alignItems: "center",
					// padding: 2,
					width: "100%",
					gap: 2,
				}}
			>
				{/* <Box
						sx={{
							maxWidth: "100%",
							ml: 4,
							mr: 4,
							marginTop: 2,
							mb: 2,
							display: "flex",
							// flexDirection: "row",
							// justifyContent: "space-between",
						}}
					> */}
				<RouterBreadcrumbs />

				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
						alignItems: "center",
						// padding: 2,
						// width: "100%",
						gap: 2,
					}}
				>
					{showSitePageFilters()}
					{showPriceBandPageFilters()}

					<CustomSearch
						value={searchValue}
						onChange={handleSearchValueChange}
					/>
					{showMakeDeletedVisibleButton()}
					{showImportButton()}
					{showMarketManSync()}

					{showExport()}
					{showModalButton()}
					{showNewItemModalButton()}
				</Box>
			</Box>

			<Menu
				id="menu-options"
				anchorEl={anchorElMenu}
				open={openMenu}
				onClose={() => {
					setAnchorElMenu(null);
				}}
			>
				<GridCsvExportMenuItem options={csvOptions} />
				<GridPrintExportMenuItem options={printOptions} />
				{/* <JsonExportMenuItem /> */}
			</Menu>
			{/* </Box> */}
		</GridToolbarContainer>
	);
}
