import React, { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { Colours } from "../assets/global/Theme-variable";
import { Navigate, useNavigate } from "react-router-dom";
import { useFetch } from "../views/profile/useFetch";
import { DataGridContext } from "../views/datagrids/data grid files/DataGridContext";

const UserBadge = ({ token, handleLogout }) => {
	// const {isAuthenticated, isLoading, user} = useAuth0();
	const [openProfile, setOpenProfile] = React.useState(false);
	const navigate = useNavigate();
	const {
		loggedInUser,
		name,
		setName,
		nickname,
		setNickame,
		role,
		setRole,
		email,
		setEmail,
		photo,
		setPhoto,
	} = useContext(DataGridContext);
	return (
		<>
			{/* <Profile
				loggedinuser={user}
				token={token}
				open={openProfile}
				setOpenProfile={setOpenProfile}
			/> */}
			<Box
				sx={{
					width: "100%",
					justifySelf: "flex-end",
					justifyContent: "center",
					alignItems: "center",
					display: "flex",
					flexDirection: "row",
				}}
				// onClick={() => navigate("/profile")}
			>
				<Avatar
					src={
						photo
							? photo
							: "https://max-cloud-images.s3-eu-west-1.amazonaws.com/place-holder.png"
					}
					alt={name}
					sx={{
						width: 40,
						height: 40,
						mr: 1,
						ml: 2,
					}}
				/>
				<Box
					sx={{
						mr: 2,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<Typography
						variant="h5"
						sx={{
							color: Colours.gsblue,
							fontSize: 14,
							lineHeight: 1,
							userSelect: "none",
						}}
					>
						{name && name.length > 10 ? nickname : name}
					</Typography>
					<Typography
						variant="h5"
						sx={{
							color: Colours.gsgrey,
							fontSize: 14,
							lineHeight: 1,
							userSelect: "none",
							mt: 0.5,
						}}
					>
						{loggedInUser?.app_metadata.company_name}
					</Typography>
					{/* <Typography
						onClick={handleLogout}
						variant="h6"
						sx={{
							color: Colours.lighterGrey,
							fontWeight: 100,
							fontSize: 12,
							cursor: "pointer",
							"&:hover": {
								color: Colours.gsblue,
							},
						}}
					>
						{/* {loggedInUser?.user_metadata && loggedInUser.user_metadata.role
							? loggedInUser?.user_metadata.role
							: "User"} */}
					{/* Log Out */}
					{/* </Typography>  */}
				</Box>
			</Box>
		</>
	);
};

export default UserBadge;
