import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@mui/material";
import { Box, Button } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import LogoIcon from "../components/LogoIcon";
import { Colours } from "../assets/global/Theme-variable";

export default function Verify() {
	const navigate = useNavigate();
	const domain = process.env.REACT_APP_API_SERVER_URL;

	// const searchParams = new URLSearchParams(document.location.search);
	// const state = searchParams.get("state");

	// // const [searchParams, setSearchParams] = useSearchParams();
	// const token = state.token;
	// console.log("state", state);
	// const [continueUrl] = useState(
	// 	`https://gs-systems.uk.auth0.com/continue?state=${state}`
	// );

	// console.log("url", continueUrl);

	// // const {getAccessTokenSilently} = useAuth0();

	// const postToken = async () => {
	// 	// const token = getAccessTokenSilently();

	// 	// const config = {
	// 	// 	headers: {Authorization: `Bearer ${token}`},
	// 	// };
	// 	console.log("continue", continueUrl);

	// 	await axios
	// 		.post(continueUrl)
	// 		.then((response) => {
	// 			console.log("fe res", response);
	// 		})
	// 		.catch((error) => {
	// 			console.log("fe err", error);
	// 		});
	// };
	const { logout } = useAuth0();
	const returnToLogIn = () => {
		logout({
			logoutParams: {
				returnTo: `${window.location.origin}`,
			},
		});
	};

	return (
		<Box
			sx={{
				flex: 1,
				minHeight: "100vh",
				// width: "100",

				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			{/* <a href={continueUrl}>Verify</a>
			<Button onClick={postToken}> Verify</Button> */}
			<LogoIcon height={200} />
			<Typography sx={{ fontSize: 14, mt: 5 }}>
				Your email address has not yet been verified, please verify your email
				address and try again.
			</Typography>

			<Button onClick={returnToLogIn}>Return to log in</Button>
		</Box>
	);
}
