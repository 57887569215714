import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";

import colours from "../data/colours";
import AccessControl from "../../AccessControl";
import { DataGridContext } from "../../datagrids/data grid files/DataGridContext";

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
// 	PaperProps: {
// 		style: {
// 			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
// 			width: 250,
// 		},
// 	},
// };

export default function SelectWithAddOption({
	options,
	onChange,
	value,
	width,
	type,
	open,
	setOpen,
	label,
	marginRight,
}) {
	// const [open, setOpen] = React.useState(false);
	const { userPermission } = React.useContext(DataGridContext);

	const handleClickOpen = () => {
		setOpen(true);
	};

	return (
		<FormControl variant="outlined" sx={{ width: width, p: 0 }}>
			<InputLabel shrink id="label">
				{label}
			</InputLabel>
			<Select
				notched
				labelId="label"
				id="demo-multiple-checkbox"
				value={value ? value : ""}
				onChange={onChange}
				renderValue={(selected) => selected}
				options={options}
				size="small"
				sx={{ pr: 2, mr: marginRight && marginRight }}
				label={label}
				defaultValue="None"
				MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
			>
				<AccessControl
					userPermissions={userPermission}
					unallowedPermissions={{
						page: "lists",
						strict: false,

						// allow: false,
						action: "edit",
					}}
					// renderNoAccess={() => <NoAccess permissionsNeeded="read:stats" />}
					renderNoAccess={() => <></>}
				>
					<MenuItem value={"buttonClick"}>
						<Button onClick={handleClickOpen} sx={{ mb: 0 }}>
							Add New
						</Button>
					</MenuItem>
				</AccessControl>
				{options &&
					options.map((option) => (
						<MenuItem key={option} value={option}>
							{type === "List"
								? `${option.list_number} ${option.list_name}`
								: option}
						</MenuItem>
					))}
			</Select>
		</FormControl>
	);
}
