import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { DataGridContext } from "../../datagrids/data grid files/DataGridContext";
import { Button, Menu } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
export default function PricingFilterPricebands() {
	const {
		data,

		priceBandFilterValue,
		setPriceBandFilterValue,
	} = React.useContext(DataGridContext);

	//const siteFilterData = data.sites;
	const priceBandFilterData = data.priceband.filter(
		(priceband) => priceband.priceband_details.isActive
	);
	const [isUpdating, setIsUpdating] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				mminxHeight:
					ITEM_HEIGHT * priceBandFilterData.length + ITEM_PADDING_TOP,
				width: 200,
			},
		},
	};

	React.useEffect(() => {
		if (isUpdating) {
			setIsUpdating(false);
		}
	}, [isUpdating]);

	const handlePriceBandChange = (number) => {
		// const {
		// 	target: { value },
		// } = event;
		console.log(number);
		const value = number;
		let val = [...priceBandFilterValue];

		if (val.includes(number)) {
			console.log("here");
			val = priceBandFilterValue.filter((i) => i !== number);
		} else {
			val.push(number);
		}
		console.log(typeof value === "string" ? value.split(",") : value);
		setIsUpdating(true);
		// const val = [...priceBandFilterValue, value];
		setPriceBandFilterValue(
			// On autofill we get a stringified value.
			val
		);
	};
	console.log(priceBandFilterValue);
	if (priceBandFilterData.length > 1) {
		return (
			<div>
				<Button
					id="basic-button"
					aria-controls={open ? "basic-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					onClick={handleClick}
				>
					Pricebands
					<FilterAltIcon />
				</Button>
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						"aria-labelledby": "basic-button",
					}}
				>
					{priceBandFilterData.map((priceband) => (
						<MenuItem
							key={priceband.priceband_name}
							value={priceband.priceband_number}
							onClick={(event) =>
								handlePriceBandChange(priceband.priceband_number)
							}
						>
							<Checkbox
								checked={
									priceBandFilterValue.indexOf(priceband.priceband_number) > -1
								}
							/>
							<ListItemText primary={priceband.priceband_name} />
						</MenuItem>
					))}
				</Menu>
			</div>
			// <div style={{ display: "flex", justifyContent: "space-between" }}>
			// 	{/* <FormControl sx={{ m: 1 }}>
			// 		<InputLabel shrink id="label">
			// 			Priceband Filter
			// 		</InputLabel> */}
			// 	<Button
			// 		id="basic-button"
			// 		aria-controls={open ? "basic-menu" : undefined}
			// 		aria-haspopup="true"
			// 		aria-expanded={open ? "true" : undefined}
			// 		onClick={handleClick}
			// 	>
			// 		Dashboard
			// 	</Button>
			// 	<Select
			// 		multiple
			// 		notched
			// 		labelId="label"
			// 		variant={"outlined"}
			// 		id="pricebandpricefilterchekbox"
			// 		value={priceBandFilterValue}
			// 		open={open}
			// 		onClick={handleClose}
			// 		onChange={handlePriceBandChange}
			// 		input={<OutlinedInput label="Priceband Filter" />}
			// 		renderValue={(selected) =>
			// 			selected
			// 				.map((value) => {
			// 					const selectedPriceBand = priceBandFilterData.find(
			// 						(priceBand) => priceBand.priceband_number === value
			// 					);
			// 					return selectedPriceBand
			// 						? selectedPriceBand.priceband_name
			// 						: "";
			// 				})
			// 				.join(", ")
			// 		}
			// 		size="small"
			// 		style={{ width: 200 }}
			// 		MenuProps={MenuProps} // Use the same MenuProps as in the Sites Filter
			// 	>
			// 		{priceBandFilterData.map((priceband) => (
			// 			<MenuItem
			// 				key={priceband.priceband_name}
			// 				value={priceband.priceband_number}
			// 			>
			// 				<Checkbox
			// 					checked={
			// 						priceBandFilterValue.indexOf(priceband.priceband_number) > -1
			// 					}
			// 				/>
			// 				<ListItemText primary={priceband.priceband_name} />
			// 			</MenuItem>
			// 		))}
			// 	</Select>
			// 	{/* </FormControl> */}
			// </div>
		);
	}
}
