const typography = {
	fontFamily: "'DM Sans', sans-serif",
	body1: {
		fontWeight: 200, // or 'bold'
		fontSize: 16,
		letterSpacing: "0.2px",
		color: "#54595f",
		lineHeight: 1.5,
	},
	body2: {
		fontWeight: 200, // or 'bold'
		fontSize: 16,
		letterSpacing: "0.2px",
		color: "#54595f",
	},
	datagrid: {
		fontWeight: 200, // or 'bold'
		fontSize: 13,
		letterSpacing: "0.2px",
	},
	tinyText: {
		fontWeight: 200, // or 'bold'
		fontSize: 11,
		letterSpacing: "0.2px",
		color: "#2a9ec5",
		lineHeight: 1,
	},
	switch: {
		fontWeight: 200, // or 'bold'
		fontSize: 12,
		letterSpacing: "0.2px",
		color: "#54595f",
		lineHeight: 1,
	},
	h1: {
		fontWeight: 300,
		fontSize: 16,
		letterSpacing: "0.2px",
		color: "#54595f",
	},
	h2: {
		fontWeight: 500,
		fontSize: 24,
		color: "#54595f",

		// letterSpacing: "-0.24px",
	},
	h3: {
		fontWeight: 500,
		color: "#2a9ec5",

		fontSize: 18,
		// letterSpacing: "-0.06px",
	},
	h4: {
		fontWeight: 500,
		color: "#54595f",

		fontSize: 18,
		// letterSpacing: "-0.06px",
	},
	h5: {
		fontWeight: 500,
		fontSize: 16,
		color: "#54595f",

		// letterSpacing: "-0.05px",
	},
	h6: {
		fontWeight: 500,
		fontSize: 14,
		color: "#54595f",

		// letterSpacing: "-0.05px",
	},
	buttons: {
		textTransform: "none",
	},
};

export default typography;
