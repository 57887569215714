import { GridColumnMenu } from "@mui/x-data-grid";

export default function CustomColumnMenu(props) {
	return (
		<GridColumnMenu
			{...props}
			slots={
				{
					// Hide `columnMenuColumnsItem`
					// columnMenuColumnsItem: null,
				}
			}
		/>
	);
}
