import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	IconButton,
	Skeleton,
	Typography,
} from "@mui/material";
import React from "react";
import { Colours } from "../assets/global/Theme-variable";
import { DataGridContext } from "../views/datagrids/data grid files/DataGridContext";
import { useNavigate } from "react-router-dom";
import MainButton from "./Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { CiBeerMugFull } from "react-icons/ci";

export default function StatCard({ stat }) {
	const navigate = useNavigate();
	return (
		<Card
			//Removing the key Resolves web console error messages bundle.js:6117 Warning: StatCard: `key` is not a prop. Trying to access it will result in `undefined` being returned. If you need to access the same value within the child component, you should pass it as a different prop

			sx={{
				width: "25%",
				height: "auto",
				// aspectRatio: 3 / 1,
				// backgroundColor: "#DFF2F8",
				border: "1px solid #f0f0f0",
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "space-between",
				// margin: 2,
				padding: 2,
				borderRadius: 1,
				// boxShadow: 1,
			}}
		>
			<CardContent
				sx={{
					padding: 0,
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				{/* <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
					{stat.name}
				</Typography> */}

				<Box
					sx={{
						borderRadius: "50%",
						background: "#DFF2F8",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: 40,
						width: 40,
						mr: 2,
						ml: 1,
					}}
				>
					<stat.icon size={stat.iconSize} color={Colours.gsblue} />
				</Box>
				<Typography component={"div"} sx={{ flexDirection: "column" }}>
					{stat.stat !== null ? (
						<Box
							sx={{
								fontSize: 30,
								textAlign: "left",
								userSelect: "none",
								fontWeight: "500",
								lineHeight: "normal",

								// mb: -0.5,
							}}
							color={Colours.gsblue}

							// gutterBottom
						>
							{stat.stat}
						</Box>
					) : (
						<Skeleton
							animation="wave"
							height={40}
							sx={{ bgcolor: Colours.white }}
						/>
					)}
					<Box
						sx={{
							fontSize: 14,
							paddingTop: 0,
							userSelect: "none",
							fontWeight: "500",
							lineHeight: "normal",
							color: Colours.darkGrey,
						}}
					>
						{stat.name}
					</Box>
				</Typography>
			</CardContent>
			<CardActions
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					justifySelf: "flex-end",
				}}
			>
				<IconButton
					aria-label="go"
					size="small"
					// moved on click to button because of this error Warning: Failed prop type: MUI: You are providing an onClick event listener to a child of a button element.
					// Prefer applying it to the IconButton directly.
					// This guarantees that the whole <button> will be responsive to click events.
					onClick={() => navigate(`/${stat.url}`)}
				>
					<ArrowForwardIcon
						color={Colours.gsblue}
						size="10px"
						sx={{ color: Colours.gsblue }}
					/>
				</IconButton>
			</CardActions>
		</Card>
	);
}
