import {
	Autocomplete,
	Box,
	Button,
	InputAdornment,
	MenuItem,
	Modal,
	TextField,
	Typography,
} from "@mui/material";
import React from "react";
import { useForm, useFormContext } from "react-hook-form";
import axios from "axios";
import { DataGridContext } from "../views/datagrids/data grid files/DataGridContext";
import { Colours } from "../assets/global/Theme-variable";
import { useAuth0 } from "@auth0/auth0-react";
import { MacroNumber } from "./MacroNumber";
import { API_ENDPOINTS } from "../API/apiConfig";
import ReactHookFormSelect from "./ReactHookFormSelect";

export default function NewMacroModal({
	modalVisible,
	setModalVisible,
	type,
	context,
	modalConfig,
}) {
	const { handleSubmit, reset, register, control, getValues, formState } =
		useFormContext({});
	const { getAccessTokenSilently } = useAuth0();
	const { data, setData, setSnackbarMessage, setSnackbarOpen } =
		React.useContext(DataGridContext);

	// const onSubmit = async () => {
	// 	const token = await getAccessTokenSilently();
	// 	const form = getValues();
	// 	const config = {
	// 		headers: { Authorization: `Bearer ${token}` },
	// 	};

	// 	let newItem = {
	// 		[`${context}_name`]: form.name,
	// 		[`${context}_details`]: {},
	// 		[`${context}_number`]: form[`${context}_number`],
	// 	};

	// 	if (context === "macro") {
	// 		newItem.macro_details.behaviour = form.behaviour;
	// 	}

	// 	if (context === "analysisCategory") {
	// 		newItem.analysisCategory_details.type = form.analysisCategory_type.value;
	// 	}
	// 	if (context === "modifier") {
	// 		newItem = {
	// 			...newItem,
	// 			modifier_details: {
	// 				prefix: form.prefix,
	// 			},
	// 		};
	// 	}
	// 	console.log(context);
	// 	console.log(newItem);

	// 	// if (type === "paymentMethod") {
	// 	// 	newItem.paymentMethod_details = {};
	// 	// 	newItem.paymentMethod_details.tags = [];
	// 	// }
	// 	const newData = {
	// 		updates: [newItem],
	// 	};

	// 	axios
	// 		.post(API_ENDPOINTS[context], newData, config)
	// 		.then(function (response) {
	// 			console.log(response);
	// 			if (response.status === 200) {
	// 				const itemType = type.charAt(0).toUpperCase() + type.slice(1); // // added line to dynamically chnage depending on type
	// 				setSnackbarMessage({
	// 					message: [`${itemType} Successfully Added`], // added line to dynamically chnage depending on type
	// 					duration: 2000,
	// 					severity: "success",
	// 				});
	// 				setModalVisible(false);

	// 				// Ensure that data[context] is an array before attempting to add to it
	// 				setData((prevData) => ({
	// 					...prevData,
	// 					[`${context}`]: Array.isArray(prevData[`${context}`])
	// 						? [...prevData[`${context}`], response.data[context][0]]
	// 						: [response.data[context][0]],
	// 				}));
	// 				reset({});
	// 				setSnackbarOpen(true);
	// 			}
	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 			console.log("Error response:", error.response); // Add this line to check the response object
	// 			setSnackbarMessage({
	// 				message: [
	// 					`ERROR couldn't save data back to server. ${error.response.statusText}.`,
	// 				],
	// 				duration: 6000,
	// 				severity: "error",
	// 			});
	// 		});
	// };

	// const style = {
	// 	position: "absolute",
	// 	top: "50%",
	// 	left: "50%",
	// 	transform: "translate(-50%, -50%)",
	// 	borderRadius: "20px",
	// 	width: 410,
	// 	minHeight: 200,
	// 	bgcolor: "white",
	// 	boxShadow: 24,
	// 	p: 4,
	// 	display: "flex",
	// 	justifyContent: "space-around",
	// 	flexDirection: "column",
	// };

	// const handleClose = () => {
	// 	reset();
	// 	setModalVisible(!modalVisible);
	// };

	const paymentMethodNumbers = Array.from(Array(50), (e, i) =>
		JSON.stringify(i + 1)
	);

	const modiferNumbers = Array.from(Array(9), (e, i) => JSON.stringify(i + 1));

	const paymentMethodNumbersArr = [];

	const modifierNumbersArr = [];

	const allModifiers = data?.modifier?.map((modifier) => {
		modifierNumbersArr?.push(modifier?.modifier_number);
	});

	const modifierOptions = modiferNumbers.filter(
		(number) => !modifierNumbersArr.includes(number)
	);
	// console.log(numbers);

	data?.paymentMethod
		?.filter((i) => i.paymentMethod_isDeleted === 0)
		.map((paymentMethod) => {
			paymentMethodNumbersArr?.push(paymentMethod?.paymentMethod_number);
		});

	const paymentMethodOptions = paymentMethodNumbers?.filter(
		(number) => !paymentMethodNumbersArr?.includes(number)
	);

	const analysisCategoryNumbers = Array.from(Array(99), (e, i) =>
		JSON.stringify(i + 1)
	);
	const analysisCategories = [
		{ name: "Food", value: 1 },
		{ name: "Drink", value: 2 },
		{ name: "Other", value: 3 },
	];

	const analysisCategoryArr = [];
	// console.log(numbers);

	const allAnalysisCategories = data?.analysisCategory
		?.filter((i) => i.analysisCategory_isDeleted === 0)
		.map((cat) => {
			analysisCategoryArr?.push(cat?.analysisCategory_number);
		});

	const analysisCategoryOptions = analysisCategoryNumbers?.filter(
		(number) => !analysisCategoryArr?.includes(number)
	);
	console.log(context);

	return (
		// <Modal open={modalVisible}>
		// 	<form onSubmit={handleSubmit(onSubmit)}>
		// 		<Box sx={style}>
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				alignItems: "center",
				mb: 2,
				gap: 2,
			}}
		>
			{/* <Typography
							variant="h5"
							sx={{ mb: 0, color: Colours.gsblue, justifySelf: "center" }}
						>
							New{" "}
							{context === "macro"
								? "Macro"
								: context === "function"
								? "Function"
								: context === "analysisCategory"
								? "Analysis Category"
								: context === "paymentMethod"
								? "Payment Method"
								: context === "modifier"
								? "Modifier"
								: ""}
						</Typography>
						<Button
							variant="contained"
							size="medium"
							sx={{ alignSelf: "flex-end" }}
							onClick={() => handleClose()}
						>
							X
						</Button>
					</Box> */}
			{context === "analysisCategory" && (
				// <Box sx={{ mt: 2 }}>
				// 	<MacroNumber
				// 		label={"Type"}
				// 		size="small"
				// 		type="analysisCategory"
				// 		sx={{ width: 350, mb: 2 }}
				// 		errors={formState.errors}
				// 		name="analysisCategory_details.type"
				// 		InputLabelProps={{
				// 			shrink: true,
				// 		}}
				// 		register={register}
				// 		options={[
				// 			{ name: "Food", value: 1 },
				// 			{ name: "Drink", value: 2 },
				// 			{ name: "Other", value: 3 },
				// 		]}
				// 		validationSchema={{
				// 			required: "Type is required",
				// 		}}
				// 		defaultvalue={""}
				// 	/>
				// </Box>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						flexDirection: "column",
						gap: 2,
					}}
				>
					{/* <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}> */}
					<ReactHookFormSelect
						id="userType_role"
						name="analysisCategory_number"
						label="Number"
						control={control}
						errors={formState.errors}
						rules={{
							required: "Analysis Category is required",
						}}
					>
						{analysisCategoryOptions?.map((number) => (
							<MenuItem key={number} value={number}>
								{number}
							</MenuItem>
						))}
					</ReactHookFormSelect>
					{formState?.errors?.analysisCategory_number && (
						<Typography sx={{ color: Colours.red, fontSize: 12 }}>
							{formState?.errors?.analysisCategory_number?.message}
						</Typography>
					)}
					{/* </Box> */}
					<ReactHookFormSelect
						id="userType_role"
						name="analysisCategory_details.type"
						label="Type"
						control={control}
						errors={formState.errors}
						rules={{
							required: "Type is required",
						}}
					>
						{analysisCategories?.map((method) => (
							<MenuItem key={method.value} value={method.value}>
								{method.name}
							</MenuItem>
						))}
					</ReactHookFormSelect>
					{formState?.errors?.analysisCategory_details?.type && (
						<Typography sx={{ color: Colours.red, fontSize: 12 }}>
							{formState?.errors?.analysisCategory_details?.type?.message}
						</Typography>
					)}
				</Box>
			)}
			<Box>
				<TextField
					label={"Name"}
					size="small"
					type="text"
					sx={{ width: 350 }}
					InputLabelProps={{
						shrink: true,
					}}
					{...register(`${context}_name`, {
						required: "Name is required",
						pattern: {
							value: /^[-a-zA-Z0-9@\s\*&+%£=!:;><)?(^\[\]{\}\\\/\.\_]+$/,
							message: "Name cannot contain emoji's or special characters",
						},
						maxLength: {
							value: 30,
							message: "Name must be less than 30 characters",
						},
						validate: (value) => {
							if (modalConfig.isNew === true) {
								return (
									!data[context]?.some(
										(policy) => policy[`${context}_name`] === value
									) || `A ${context} with this name already exists`
								);
							}
						},
					})}
				/>
				{formState.errors && formState.errors[`${context}_name`] && (
					<Typography sx={{ fontSize: 12, ml: 1, color: Colours.red }}>
						{formState.errors[`${context}_name`]?.message}
					</Typography>
				)}
			</Box>
			{/* {context === "analysisCategory" && (
				<Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
					<ReactHookFormSelect
						id="userType_role"
						name="analysisCategory_number"
						label="Number"
						control={control}
						errors={formState.errors}
						rules={{
							required: "Analysis Category is required",
						}}
					>
						{analysisCategoryOptions?.map((number) => (
							<MenuItem key={number} value={number}>
								{number}
							</MenuItem>
						))}
					</ReactHookFormSelect>
					{formState?.errors?.analysisCategory_number && (
						<Typography sx={{ color: Colours.red, fontSize: 12 }}>
							{formState?.errors?.analysisCategory_number?.message}
						</Typography>
					)}
				</Box>
			)} */}
			{context === "paymentMethod" && (
				// <MacroNumber
				// 	label={"Number"}
				// 	size="small"
				// 	type="number"
				// 	sx={{ width: 350, mb: 2 }}
				// 	errors={formState.errors}
				// 	name="paymentMethod_number"
				// 	InputLabelProps={{
				// 		shrink: true,
				// 	}}
				// 	register={register}
				// 	options={paymentMethodOptions}
				// 	validationSchema={{
				// 		required: "Number is required",
				// 	}}
				// />
				<Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
					<ReactHookFormSelect
						id="userType_role"
						name="paymentMethod_number"
						label="Number"
						control={control}
						errors={formState.errors}
						rules={{
							required: "Number is required",
						}}
					>
						{paymentMethodOptions?.map((method) => (
							<MenuItem key={method} value={method}>
								{method}
							</MenuItem>
						))}
					</ReactHookFormSelect>
					{formState?.errors?.paymentMethod_number && (
						<Typography sx={{ color: Colours.red, fontSize: 12 }}>
							{formState?.errors?.paymentMethod_number?.message}
						</Typography>
					)}
				</Box>
			)}
			{context === "modifier" && (
				<>
					{/* <MacroNumber
						label={"Number"}
						size="small"
						type="number"
						sx={{ width: 350, mb: 2 }}
						errors={formState.errors}
						name="modifier_number"
						InputLabelProps={{
							shrink: true,
						}}
						register={register}
						options={modifierOptions}
						validationSchema={{
							required: "Number is required",
						}}
					/> */}
					<Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
						<ReactHookFormSelect
							id="userType_role"
							name="modifier_number"
							label="Number"
							control={control}
							errors={formState.errors}
							rules={{
								required: "Modifier number is required",
							}}
						>
							{modifierOptions?.map((modifier) => (
								<MenuItem key={modifier} value={modifier}>
									{modifier}
								</MenuItem>
							))}
						</ReactHookFormSelect>
						{formState?.errors?.modifier_number && (
							<Typography sx={{ color: Colours.red, fontSize: 12 }}>
								{formState?.errors?.modifier_number?.message}
							</Typography>
						)}
					</Box>
					<Box>
						<TextField
							label={"Prefix"}
							size="small"
							type="text"
							sx={{ width: 350 }}
							InputLabelProps={{
								shrink: true,
							}}
							{...register("modifier_details.prefix", {
								required: "Prefix is required",
								pattern: {
									value: /^[-a-zA-Z0-9@\s\*&+%£=!:;><)?(^\[\]{\}\\\/\.\_]+$/,
									message:
										"Prefix cannot contain emoji's or special characters",
								},
								maxLength: {
									value: 40,
									message: "Prefix must be less than 40 characters",
								},
							})} // Register the prefix field
						/>
						{formState.errors && formState.errors?.modifier_details?.prefix && (
							<Typography sx={{ fontSize: 12, ml: 1, color: Colours.red }}>
								{formState.errors?.modifier_details?.prefix?.message}
							</Typography>
						)}
					</Box>
				</>
			)}

			{context === "macro" && (
				<Box>
					<TextField
						label={"Behaviour"}
						size="small"
						type="text"
						sx={{ width: 350 }}
						InputLabelProps={{
							shrink: true,
						}}
						{...register("macro_details.behaviour", {
							required: "Behaviour is required",
							pattern: {
								value: /^[-a-zA-Z0-9@\s\*&+%£=!:;><)?(^\[\]{\}\\\/\.\_]+$/,
								message: "Name cannot contain emoji's or special characters",
							},
							maxLength: {
								value: 70,
								message: "Behaviour must be less than 70 characters",
							},
						})}
					/>
					{formState.errors && formState?.errors?.macro_details?.behaviour && (
						<Typography sx={{ fontSize: 12, ml: 1, color: Colours.red }}>
							{formState?.errors?.macro_details?.behaviour?.message}
						</Typography>
					)}
				</Box>
			)}
		</Box>
		// 	</form>
		// </Modal>
	);
}
