import React from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
} from "@mui/material";
import MainButton from "../../../components/Button";
import { Colours } from "../../../assets/global/Theme-variable";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

export default function ErrorDialog({
	saveChanges,
	discardChanges,
	errorMessage,
	onClose,
	setErrorMessage,
	isSaving,
}) {
	const handleClose = () => {
		setErrorMessage((prev) => ({ ...prev, open: false }));
		onClose(); // You can call onClose here if needed
	};

	return (
		<Dialog
			maxWidth={"xs"}
			open={errorMessage.open || false}
			onClose={handleClose}
			keepMounted
			sx={{ padding: 2 }}
		>
			<DialogTitle
				sx={{
					color: Colours.gsblue,
					fontSize: 18,
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				{errorMessage.button2text}
				<IconButton
					aria-label="close"
					sx={{ alignSelf: "flex-end" }}
					onClick={handleClose}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<DialogContentText>{errorMessage.text}</DialogContentText>
			</DialogContent>
			<DialogActions
				sx={{ justifyContent: "space-between", pl: 3, pr: 3, pb: 2 }}
			>
				<Button
					// text={errorMessage.button1text}
					variant="outlined"
					onClick={
						errorMessage.button1func ? errorMessage.button1func : handleClose
					}
				>
					{errorMessage.button1text}
				</Button>
				<LoadingButton
					size="small"
					onClick={errorMessage.button2func}
					loading={isSaving}
					variant={!isSaving ? "gradient" : "outlined"}
					loadingPosition="start"
					startIcon={<SaveIcon />}
					disabled={isSaving}
				>
					<span>{errorMessage.button2text}</span>
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}
