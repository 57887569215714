import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import { DataGridContext } from "../../data grid files/DataGridContext";

export default function DisplayGrid({ selectedRow }) {
	const { data } = useContext(DataGridContext);
	return (
		<Box
			display="grid"
			gridTemplateColumns="repeat(10, 1fr)"
			gridTemplateRows="repeat(10, 1fr) "
			sx={{
				// flex: 1,
				height:
					selectedRow?.list_details?.location === "L2"
						? 300
						: selectedRow?.list_details?.location === "F"
						? 300
						: 50,
				width:
					selectedRow?.list_details?.location === "L2"
						? 300
						: selectedRow?.list_details?.location === "F"
						? 300
						: 400,
				padding: 0,
			}}
		>
			{selectedRow && (
				<Box
					sx={{
						gridRow:
							// selectedRow?.list_details?.location === "L"
							// 	? "1/6"
							// 	: selectedRow?.list_details?.location === "F"
							// 	? "5/11"
							// 	:
							"1/11",
						gridColumn:
							// selectedRow?.list_details?.location === "L"
							// 	? "1/10"
							// 	: selectedRow?.list_details?.location === "F"
							// 	? "1/6"

							// :
							"1/11",
						display: "grid",
						gridAutoFlow: "dense",
						gridTemplateColumns: `repeat(${selectedRow?.list_details?.cols}, 1fr)`,
						gridTemplateRows: `repeat(${selectedRow?.list_details?.rows}, 1fr)`,
						gridGap: "2px",
						width: "100%",
						height: "100%",
						flex: 1,
						gridAutoRows: "0",
						overflowY: "hidden",
						textOverflow: "ellipsis",
					}}
				>
					{selectedRow?.list_details?.buttons
						?.filter((button) => button.pageNo === 1)
						// .sort(sortItems)
						.map((item, index) => (
							<Box
								sx={{
									gridColumn: `span ${item.mx}`,
									gridRow: `span ${item.my}`,
									display: item.my === 0 || item.mx === 0 ? "none" : null,
									width: "100%",
									height: "100%",
									overflowY: "hidden",

									boxSizing: "border-box",
									justifyContent: "center",
									alignItems: "center",
									transform: "none",
								}}
								key={index}
							>
								<Box
									sx={{
										display: "flex",
										alignItems:
											item.style.alignmentV === 1
												? "center"
												: item.style.alignmentV === 0
												? "flex-start"
												: "flex-end",
										justifyContent: "center",
										width: "100%",
										height: "100%",
										backgroundColor:
											data?.colours[0]?.palette_details?.colours.find(
												(col) => col?.index === item?.style?.background
											)?.colour ||
											data.colours[0].palette_details.colours[59]?.colour,

										textAlign: "center",
									}}
								>
									<Typography
										sx={{
											color:
												data?.colours[0]?.palette_details?.colours.find(
													(col) => col?.index === item?.style?.foreground
												)?.colour ||
												data.colours[0].palette_details.colours[63]?.colour,

											lineHeight: 1,
											fontSize: `clamp(0.01rem, 0.5rem, 1rem)`,
											fontWeight:
												item.style.font.replace(/[0-9]/g, "") === "B"
													? 700
													: 400,
											textAlign:
												item.style.alignmentH === 1 ? "center" : "left",
											userSelect: "none",
											display: item.hideText === 1 ? "none" : null,
											overflow: "hidden",
											whiteSpace: "pre-line",
											textOverflow: "ellipsis",
										}}
									>
										{item.functionType === 13
											? `Server ${parseInt(item.functionValue)}`
											: item?.text?.replaceAll("|", "\n")}
									</Typography>
								</Box>
							</Box>
						))}
				</Box>
			)}
		</Box>
	);
}
