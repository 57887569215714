import React from "react";
//import { Link } from 'react-router-dom';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";

import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";

import {
	AppBar,
	Box,
	IconButton,
	Toolbar,
	Menu,
	MenuItem,
	Button,
	Avatar,
	Divider,
	ListItemIcon,
} from "@mui/material";

import userimg from "../../../assets/images/user.jpg";
import { Colours } from "../../../assets/global/Theme-variable";
import { Typography } from "@mui/material";
import SearchBar from "../../../components/SearchBar";

const Header = (props) => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<AppBar sx={props.sx} elevation={0} className={props.customClass}>
			<Toolbar sx={{ p: 0 }}>
				<IconButton
					color="inherit"
					aria-label="menu"
					onClick={props.toggleMobileSidebar}
					sx={{
						display: {
							lg: "none",
							xs: "inline",
						},
					}}
				>
					<MenuOutlinedIcon width="20" height="20" />
				</IconButton>

				{/* <Typography variant="h1" sx={{ml: 0}}>
					Sites
				</Typography> */}
				<Box flexGrow={1} />
				{/* <Box
					sx={{
						display: "flex",
						alignItems: "Center",
						justifyContent: "center",
					}}
				>
					<SearchBar />
				</Box> */}
				{/* <Box
					sx={{
						mr: 2,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<Typography
						variant="h5"
						sx={{color: Colours.gsblue, fontSize: 16, lineHeight: 1}}
					>
						Stuart Wearing
					</Typography>
					<Typography
						variant="h6"
						sx={{color: Colours.lighterGrey, fontWeight: 100, fontSize: 12}}
					>
						Head of Operations
					</Typography>
				</Box>
				<Button
					aria-label="menu"
					color="inherit"
					aria-controls="profile-menu"
					aria-haspopup="true"
					onClick={handleClick}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
						}}
					>
						<Avatar
							src={userimg}
							alt={userimg}
							sx={{
								width: "50px",
								height: "50px",
							}}
						/>
					</Box>
				</Button>
				<Menu
					id="profile-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					anchorOrigin={{horizontal: "right", vertical: "bottom"}}
					transformOrigin={{horizontal: "right", vertical: "top"}}
					sx={{
						"& .MuiMenu-paper": {
							width: "250px",
							right: 0,
							top: "70px !important",
						},
					}}
				>
					<MenuItem onClick={handleClose}>
						<Avatar
							sx={{
								width: "35px",
								height: "35px",
							}}
						/>
						<Box
							sx={{
								ml: 2,
							}}
						>
							My account
						</Box>
					</MenuItem>
					<Divider />
					<MenuItem onClick={handleClose}>
						<ListItemIcon>
							<Settings fontSize="small" />
						</ListItemIcon>
						Settings
					</MenuItem>
					<MenuItem onClick={handleClose}>
						<ListItemIcon>
							<Logout fontSize="small" />
						</ListItemIcon>
						Logout
					</MenuItem>
				</Menu> */}
			</Toolbar>
		</AppBar>
	);
};

export default Header;
