import React from "react";
import {Player, Controls} from "@lottiefiles/react-lottie-player";
import {Box, Typography} from "@mui/material";

export default function NotFoundPage() {
	return (
		// <div>
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "center",
				width: "100%",
			}}
		>
			<Player
				autoplay
				loop
				src="https://lottie.host/23163f4a-2b7b-44ed-81d1-a61d7d8e1545/wOUXYvv1v8.json"
				style={{width: "500px"}}
			>
				<Box>
					<Typography sx={{textAlign: "center"}}>Page Not Found</Typography>
				</Box>
				<Controls
					visible={false}
					// buttons={["play", "repeat", "frame", "debug"]}
				/>
			</Player>
		</Box>
		// </div>
	);
}
