import React from "react";
import {Typography} from "@mui/material";
import {useAuth0} from "@auth0/auth0-react";
import {MyFallbackComponent} from "./FallBackComponent";

export const Callback = () => {
	const {error} = useAuth0();
	if (error) {
		console.log("error", error);
		return <MyFallbackComponent error={error.message} />;
	} else
		return (
			<div className="page-layout">
				<div className="page-layout__content" />
				<Typography>Callback</Typography>
			</div>
		);
};
