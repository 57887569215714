export const checkPermissions = (userPermissions, permissions) => {
	// console.log(permissions);
	if (permissions?.length === 0) {
		return true;
	} else {
		let permitted = false;
		userPermissions?.permissions.forEach((rule) => {
			if (
				(permissions.page === "?" ||
					permissions.page === "*" ||
					rule?.page?.indexOf("*") !== -1 ||
					rule?.page?.indexOf(permissions?.page) !== -1) &&
				(permissions.action === "?" ||
					permissions.action === "*" ||
					(rule?.actions?.indexOf("*") !== -1 && permissions.strict !== true) ||
					rule?.actions?.indexOf(permissions?.action) !== -1)
			) {
				// console.log(rule, rule?.allow);
				permitted = !!rule?.allow;
			}
		});
		// console.log(permitted);
		return permitted;

		// req.permissionChecker = ( api, page, action, strict = false ) => {
		// 	var permitted = false;
		// 	req.permissions2.forEach((rule) => {
		// 	  if (
		// 		( api == "?" || api == "*" || ( !rule.api && api == "pos" ) || ( !strict && rule.api && rule.api.indexOf("*") != -1 ) || ( rule.api && rule.api.indexOf(api) != -1 ) ) && // Todo make it so it doesnt allow permissions with no api added. - oliver 2024-04-12
		// 		( page == "?" || page == "*" || ( !strict && rule.page.indexOf("*") != -1 ) || rule.page.indexOf(page) != -1 ) &&
		// 		( action == "?" || action == "*" || ( !strict && rule.action.indexOf("*") != -1 ) || rule.action.indexOf(action) != -1 )
		// 	  ) {
		// 		permitted = !!rule.allow;
		// 	  }
		// 	});
		// 	if (req.debug) console.log(new Date(), "DEBUG", req.uid, "User", req.userRef, ( !permitted && "is NOT" || "is" ), "allowed to", "\""+action+"\"", "on", "\""+page+"\""+(strict && "STRICT"));
		// 	return permitted;
		//   }
	}
};

export const checkPermissions2 = (userPermissions, permissions) => {
	if (permissions?.length === 0) {
		return true;
	} else {
		let permitted = undefined;
		// console.log(
		userPermissions?.forEach((rule) => {
			if (
				(rule?.page?.indexOf("*") !== -1 ||
					rule?.page?.indexOf(permissions?.page) !== -1) &&
				// (rule?.api?.indexOf("*") !== -1 ||
				// rule?.api?.indexOf(permissions?.api) !== -1) &&
				(rule?.actions?.indexOf("*") !== -1 ||
					rule?.actions?.indexOf(permissions?.action) !== -1)
			) {
				permitted = !!rule?.allow;
			}
		});
		return permitted;
	}
};

const AccessControl = ({
	userPermissions,
	unallowedPermissions,
	children,
	renderNoAccess,
}) => {
	const permitted = checkPermissions(userPermissions, unallowedPermissions);
	// console.log(permitted);
	if (permitted === false) {
		return renderNoAccess();
	}
	return children;
};


export default AccessControl;
