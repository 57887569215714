export const textAlignOptions = [
	{
		name: "flex-start",
		value: "Top",
		tillValue: 0,
	},
	{
		name: "center",
		value: "Center",
		tillValue: 1,
	},
	{
		name: "flex-end",
		value: "Bottom",
		tillValue: 2,
	},
];
