import { TextField } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import React from "react";

export function PasswordField(props) {
	const { id, value, field, hasFocus } = props;
	const apiRef = useGridApiContext();
	const ref = React.useRef();

	React.useLayoutEffect(() => {
		if (hasFocus) {
			ref.current.focus();
		}
	}, [hasFocus]);

	const handleValueChange = (event) => {
		const newValue = event.target.value; // The new value entered by the user
		apiRef.current.setEditCellValue({
			id,
			field,
			value: newValue,
			debounceMs: 200,
		});
	};

	return (
		// <input ref={ref} type="text" value={value} onChange={handleValueChange} />
		<TextField
			// label="Password"
			value={value}
			// {...params}
			ref={ref}
			onChange={handleValueChange}
			// onChange={ref.current.setEditCellValue({
			// 	id,
			// 	field,
			// 	value: selectedOptions.map((val) => val && val[`${type}_ref`]),
			// })}
			// className={classes.textField}
			name="password"
			// readOnly={readOnly}
			// onFocus={e => setReadOnly(false)}
			sx={{
				"& input": {
					textSecurity: "disc",
					MozTextSecurity: "disc",
					WebkitTextSecurity: "disc",
				},
				border: "none",
			}}
		/>
	);
}
