import * as React from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { getTextColour } from "../../utils";
import { Colours } from "../../../../assets/global/Theme-variable";

const Root = styled("div")(
	({ theme }) => `
    background-color: none;
display: flex;
flex-direction: row;
flex-wrap: wrap;
  font-size: 14px;
`
);

function Tag(props) {
	const { label, onDelete, ...other } = props;

	return (
		<div {...other}>
			<Typography
				sx={{
					fontSize: 10,
					color:
						props.colour === "#2a9ec5"
							? "white"
							: getTextColour(props.colour).colour,
				}}
			>
				{label}
			</Typography>
		</div>
	);
}

const StyledTag = styled(Tag)(
	(props) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${props.colour};
  border: 1px solid ${props.colour};
  border-radius: 6px;
  box-sizing: content-box;
  padding: 2px 6px 2px 6px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${props.colour};
    background-color: ${props.colour};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
    color: white;
  }
`
);

export const ChipDisplayCell = (props) => {
	const limitTags = 3;
	const { value, type } = props;

	const numTags = value?.length;
	return (
		<Root>
			{value
				?.slice(0, limitTags)
				.map(
					(option, index) =>
						option !== undefined && (
							<StyledTag
								label={option[`${type}_name`] && option[`${type}_name`]}
								key={option[`${type}_uid`]}
								colour={
									option?.tag_details
										? option?.tag_details?.styling?.colour
										: Colours.gsblue
								}
							/>
						)
				)}
			{numTags > limitTags && ` +${numTags - limitTags}`}
		</Root>
	);
};
