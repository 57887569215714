import { lazy } from "react";
import { Navigate } from "react-router-dom";

// import LogIn from "../views/login/LogIn.js";

import Profile from "../views/Profile.js";

import SitesList from "../views/datagrids/data grid files/GridPage.js";
import { GridPage } from "../views/datagrids/data grid files/GridPage.js";
import IndividualSite from "../views/IndividualSite.js";
import IndividualItem from "../views/IndividualItem.js";
import { AuthenticationGuard } from "./AuthenticationGuard.js";
import { Callback } from "../views/CallBack.js";
import NotFoundPage from "../views/NotFoundPage.js";
import Home from "../views/Home.js";
import LandingPage from "../views/Landing.js";
import NotRegistered from "../views/NotRegistered.js";
import Verify from "../views/Verify.js";
import Training from "../views/Training.js";
import Help from "../views/Help.js";
import Configuration from "../views/Configuration.js";
import DesignerPage from "../views/DesignerPage.js";
import Admin from "../views/Admin.js";
import Permissions from "../views/Permissions.js";
import GridPageRefactor from "../views/datagrids/DataGridRefactor/GridPage.js";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));

/*****Routes******/

const ThemeRoutes = [
	{
		path: "/*",
		element: <Home />,
	},
	{
		path: "callback",
		element: <Callback />,
	},
	{
		path: "*",
		element: <NotFoundPage />,
		handle: {
			crumb: () => "Not Found",
		},
	},
	{
		path: "verify",
		element: <Verify />,
		handle: {
			crumb: () => "Not verified",
		},
	},
	{
		path: "notRegistered",
		element: <NotRegistered />,
		handle: {
			crumb: () => "Not Found",
		},
	},
	{
		path: "home/*",
		element: <Home />,
		handle: {
			crumb: () => "Home",
		},
	},
	// {
	// 	path: "login",
	// 	element: <LogIn />,
	// 	handle: {
	// 		crumb: () => "Home",
	// 	},
	// },
	{
		path: "landing/*",
		element: <LandingPage />,
		handle: {
			crumb: () => "Home",
		},
	},
	{
		path: "majorcategories/*",
		element: (
			<AuthenticationGuard
				component={GridPageRefactor}
				page="majorcategories"
				dbName="majorgroup"
			/>
		),
		handle: {
			crumb: () => "Major Categories",
		},
	},
	{
		path: "training/*",
		element: <AuthenticationGuard component={Training} page="training" />,
		handle: {
			crumb: () => "Training",
		},
	},
	{
		path: "help/*",
		element: <AuthenticationGuard component={Help} page="help" />,
		handle: {
			crumb: () => "Help",
		},
	},
	{
		path: "categories/*",
		element: (
			<AuthenticationGuard
				component={GridPageRefactor}
				page="categories"
				dbName="salesgroup"
			/>
		),
		handle: {
			crumb: () => "Categories",
		},
	},
	{
		path: "printers/*",
		element: (
			<AuthenticationGuard
				component={GridPageRefactor}
				page="printers"
				dbName="printer"
			/>
		),
		handle: {
			crumb: () => "Printers",
		},
	},
	{
		path: "sites/*",
		handle: {
			crumb: () => "Sites",
		},
		// element: (
		// 	<AuthenticationGuard
		// 		component={GridPageRefactor}
		// 		page="sites"
		// 		dbName="site"
		// 	/>
		// ),
		children: [
			{
				index: true,
				path: "*",
				element: (
					<AuthenticationGuard
						component={GridPageRefactor}
						page="sites"
						dbName="site"
					/>
				),
				handle: {
					crumb: () => "Sites",
				},
			},
			{
				path: ":id/*",
				element: <AuthenticationGuard component={IndividualSite} />,
				handle: {
					crumb: () => ":id",
				},
			},
		],
	},

	{
		path: "items/*",
		handle: {
			crumb: () => "Items",
		},
		children: [
			{
				index: true,
				path: "*",
				element: (
					<AuthenticationGuard
						component={GridPageRefactor}
						page="items"
						dbName="plu"
					/>
				),
				handle: {
					crumb: () => "Items",
				},
			},
			{
				path: ":id/*",
				element: <AuthenticationGuard component={IndividualItem} />,
				handle: {
					crumb: () => ":id",
				},
			},
		],
	},
	// { path: "menus", element: <BasicTable table={"Menus"} /> },
	{
		path: "tags/*",
		element: (
			<AuthenticationGuard
				component={GridPageRefactor}
				page="tags"
				dbName="tag"
				friendlyName="tags"
			/>
		),
		handle: {
			crumb: () => "Tags",
		},
	},
	{
		path: "pricing/*",
		element: (
			<AuthenticationGuard
				component={GridPageRefactor}
				page="pricings"
				dbName="pricing"
				friendlyName="prices"
			/>
		),
		handle: {
			crumb: () => "Pricing",
		},
	},
	{
		path: "staff/*",
		element: (
			<AuthenticationGuard
				component={GridPageRefactor}
				page="staff"
				dbName="server"
				friendlyName="servers"
			/>
		),
	},
	{
		path: "screens/*",
		element: (
			<AuthenticationGuard
				component={GridPageRefactor}
				page="screens"
				dbName="list"
			/>
		),
		handle: {
			crumb: () => "screens",
		},
	},

	{
		path: "designer/*",
		element: (
			<AuthenticationGuard
				component={DesignerPage}
				page="designer"
				dbName="list"
				friendlyName="screens"
			/>
		),
		handle: {
			crumb: () => "Designer",
		},
	},
	{
		path: "profile/*",
		element: <AuthenticationGuard component={Profile} />,
		handle: {
			crumb: () => "Profile",
		},
	},
	{
		path: "configuration/*",
		element: (
			<AuthenticationGuard component={Configuration} page="configuration" />
		),
		handle: {
			crumb: () => "Configuration",
		},
	},
	{
		path: "permissions/*",
		element: <AuthenticationGuard component={Permissions} page="permissions" />,
		handle: {
			crumb: () => "Permissions",
		},
	},
	{
		path: "admin/*",
		element: <AuthenticationGuard component={Admin} />,
		handle: {
			crumb: () => "Admin",
		},
	},
	{
		path: "newDataGrid/*",
		element: <AuthenticationGuard component={GridPageRefactor} page="staff" />,
		handle: {
			crumb: () => "Admin",
		},
	},
];

// const ThemeRoutes = [
// 	{
// 		path: "/",
// 		element: <Home />,
// 	},
// 	{
// 		path: "callback",
// 		element: <Callback />,
// 	},
// 	{
// 		path: "*",
// 		element: <NotFoundPage />,
// 		handle: {
// 			crumb: () => "Not Found",
// 		},
// 	},
// 	{
// 		path: "majorgroups",
// 		element: <GridPage page="majorgroups" />,

// 		handle: {
// 			crumb: () => "Major Groups",
// 		},
// 	},
// 	{
// 		path: "salesgroups",
// 		element: <GridPage page="salesgroups" />,

// 		handle: {
// 			crumb: () => "Sales Groups",
// 		},
// 	},
// 	{
// 		path: "sites",
// 		handle: {
// 			crumb: () => "Sites",
// 		},
// 		children: [
// 			{
// 				index: true,
// 				element: <GridPage page="sites" />,

// 				handle: {
// 					crumb: () => "Sites",
// 				},
// 			},
// 			{
// 				path: "/sites/:id",
// 				element: <IndividualSite />,

// 				handle: {
// 					crumb: () => ":id",
// 				},
// 			},
// 		],
// 	},

// 	{
// 		path: "items",
// 		handle: {
// 			crumb: () => "Items",
// 		},
// 		children: [
// 			{
// 				index: true,
// 				element: <GridPage page="items" />,

// 				handle: {
// 					crumb: () => "Items",
// 				},
// 			},
// 			{
// 				path: "/items/:id",
// 				element: <IndividualItem />,

// 				handle: {
// 					crumb: () => ":id",
// 				},
// 			},
// 		],
// 	},
// 	{
// 		path: "tags",
// 		element: <GridPage page="tags" />,

// 		handle: {
// 			crumb: () => "Tags",
// 		},
// 	},
// 	{
// 		path: "pricing",
// 		element: <GridPage page="pricings" />,

// 		handle: {
// 			crumb: () => "Pricing",
// 		},
// 	},
// 	{
// 		path: "servers",
// 		element: <GridPage page="servers" />,
// 		handle: {
// 			crumb: () => "Servers",
// 		},
// 	},
// 	{
// 		path: "lists",
// 		element: <GridPage page="lists" />,

// 		handle: {
// 			crumb: () => "lists",
// 		},
// 	},

// 	{
// 		path: "designer",
// 		element: <ListDesigner />,
// 		handle: {
// 			crumb: () => "Designer",
// 		},
// 	},
// 	{
// 		path: "profile",
// 		element: <Profile />,
// 		handle: {
// 			crumb: () => "Profile",
// 		},
// 	},

// ];

export default ThemeRoutes;
