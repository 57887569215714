import * as React from "react";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import { Box, Chip, InputLabel, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Colours } from "../assets/global/Theme-variable";

export const SiteSelect = React.forwardRef((props, ref) => {
	const { options, value, onChange } = props;
	return (
		<Autocomplete
			multiple
			id="site_selector"
			options={options}
			getOptionLabel={(option) => option.site_name}
			value={value}
			onChange={onChange}
			size="small"
			filterSelectedOptions
			isOptionEqualToValue={(option) =>
				value.find((v) => v.site_number === option.site_number)
			}
			limitTags={3}
			renderTags={(value, getTagProps) =>
				value.map((option, index) => (
					<Chip
						label={
							<Typography
								style={{
									whiteSpace: "normal",
									color: "white",
									fontSize: 12,
								}}
							>
								{option.site_name}
							</Typography>
						}
						{...getTagProps({ index })}
						deleteIcon={<CloseIcon color={"blue"} />}
						sx={{
							// height: "100%",
							backgroundColor: Colours.gsblue,
							padding: "2px 4px 2px 8px",
							borderRadius: 2,

							"& svg": {
								fontSize: "12px",
								cursor: "pointer",
								padding: "4px",
								color: `
										white
									 !important`,
							},
						}}
					/>
				))
			}
			renderInput={(params) => (
				<TextField
					{...params}
					label="Site"
					InputLabelProps={{
						shrink: true,
					}}
				/>
			)}
		/>
	);
});
