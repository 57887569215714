import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { DataGridContext } from "../../datagrids/data grid files/DataGridContext";
import { Button, Menu } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

export default function PricingFilterSites() {
	const { data, siteFilterValue, setSiteFilterValue, setPriceBandFilterValue } =
		React.useContext(DataGridContext);

	const siteFilterData = data.sites;

	const [isUpdating, setIsUpdating] = React.useState(false);

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				mminxHeight: ITEM_HEIGHT * siteFilterData.length + ITEM_PADDING_TOP,
				width: 200,
			},
		},
	};

	React.useEffect(() => {
		setSiteFilterValue(siteFilterData.map((site) => site.site_ref));
		setPriceBandFilterValue(["1"]);
	}, []);

	// const handleSiteChange = (event) => {
	// 	const {
	// 		target: { value },
	// 	} = event;
	// 	setIsUpdating(true);
	// 	setSiteFilterValue(
	// 		// On autofill we get a stringified value.
	// 		typeof value === "string" ? value.split(",") : value
	// 	);
	// };
	const handleSiteChange = (number) => {
		// const {
		// 	target: { value },
		// } = event;
		console.log(number);
		let val = [...siteFilterValue];

		if (val.includes(number)) {
			console.log("here");
			val = siteFilterValue.filter((i) => i !== number);
		} else {
			val.push(number);
		}
		setIsUpdating(true);
		setSiteFilterValue(val);
	};
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	React.useEffect(() => {
		if (isUpdating) {
			setIsUpdating(false);
		}
	}, [isUpdating]);

	if (data.sites.length > 1) {
		return (
			<div>
				<Button
					id="basic-button"
					aria-controls={open ? "basic-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					onClick={handleClick}
				>
					Sites
					<FilterAltIcon />
				</Button>
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						"aria-labelledby": "basic-button",
					}}
				>
					{siteFilterData.map((site) => (
						<MenuItem
							key={site.site_name}
							value={site.site_ref}
							onClick={() => handleSiteChange(site.site_ref)}
						>
							<Checkbox checked={siteFilterValue.indexOf(site.site_ref) > -1} />
							<ListItemText primary={site.site_name} />
						</MenuItem>
					))}
				</Menu>
			</div>
			// <div style={{ display: "flex", justifyContent: "space-between" }}>
			// 	<FormControl sx={{ m: 1 }}>
			// 		<InputLabel shrink id="label">
			// 			Sites Filter
			// 		</InputLabel>
			// 		<Select
			// 			id="sitepricefiltercheckbox"
			// 			multiple
			// 			variant={"outlined"}
			// 			value={siteFilterValue}
			// 			onChange={handleSiteChange}
			// 			input={<OutlinedInput label="Sites Filter" />}
			// 			renderValue={(selected) =>
			// 				selected
			// 					.map((value) => {
			// 						const selectedSite = siteFilterData.find(
			// 							(site) => site.site_ref === value
			// 						);
			// 						return selectedSite ? selectedSite.site_name : "";
			// 					})
			// 					.join(", ")
			// 			}
			// 			size="small"
			// 			notched
			// 			labelId="label"
			// 			style={{ width: 200 }}
			// 			MenuProps={MenuProps}
			// 		>
			// 			{siteFilterData.map((site) => (
			// 				<MenuItem key={site.site_name} value={site.site_ref}>
			// 					<Checkbox
			// 						checked={siteFilterValue.indexOf(site.site_ref) > -1}
			// 					/>
			// 					<ListItemText primary={site.site_name} />
			// 				</MenuItem>
			// 			))}
			// 		</Select>
			// 	</FormControl>
			// </div>
		);
	}
}
