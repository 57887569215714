import {
	Button,
	DialogContent,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Stack,
	Typography,
} from "@mui/material";
import { Box, Dialog, Fade, Grid, IconButton } from "@mui/material";
import React, { forwardRef } from "react";
import { DataGridContext } from "./DataGridContext";
import CircleIcon from "@mui/icons-material/Circle";
import { Colours } from "../../../assets/global/Theme-variable";

const Transition = forwardRef(function Transition(props, ref) {
	return <Fade ref={ref} {...props} />;
});

function ConfirmBox({
	open,
	closeDialog,
	deleteFunction,
	page,
	gridList,
	contextName,
}) {
	const { data, setData } = React.useContext(DataGridContext);
	const confirmText = (uid) => {
		const index = data[contextName]?.findIndex((elem) => elem["uid"] === uid);
		console.log(index);
		return index >= 0
			? data[contextName][index][gridList.showDeleteField]
			: "Unknown";
	};

	return (
		<Dialog
			// fullWidth
			open={open?.status}
			maxWidth="md"
			scroll="body"
			onClose={closeDialog}
			onBackdropClick={closeDialog}
			TransitionComponent={Transition}
		>
			<DialogContent sx={{ px: 4, py: 4, position: "relative" }}>
				{/* <IconButton
					size="medium"
					onClick={closeDialog}
					sx={{position: "absolute", right: "1rem", top: "1rem"}}
				>
					X
				</IconButton> */}

				{/* <Grid container spacing={6}>
					<Grid item xs={12}> */}
				<Stack spacing={2}>
					{/* <Box
						sx={{
							mb: 2,
							display: "flex",
							justifyContent: "flex-start",
							flexDirection: "column",
						}}
					> */}
					<Stack
						direction="row"
						// spacing={2}
						alignItems="center"
						justifyContent="space-between"
						mb={2}
					>
						<Typography sx={{ mb: 1 }} variant="h2">
							{/* {open?.id && `Delete ${confirmText(open?.id)}`} */}
							Confirm Delete
						</Typography>
						{/* <Button
							variant="contained"
							size="medium"
							sx={{ alignSelf: "flex-end" }}
							onClick={closeDialog}
						>
							X
						</Button> */}
					</Stack>
					<Stack spacing={2} mb={2}>
						<Typography sx={{ mb: 2 }}>
							Are you sure you want to delete the selected row?
						</Typography>
						{open?.msg && (
							<>
								<Typography variant="body1">{open?.msg}</Typography>
								<List sx={{ mt: 0, ml: 2 }} dense={true}>
									{open?.lists?.map((list) => (
										<ListItem component="div" disablePadding sx={{ ml: 4 }}>
											<CircleIcon
												sx={{
													fontSize: 10,
													color: Colours.gsblue,
													textAlign: "center",
													mr: 1,
												}}
											/>
											<Typography>{list?.list_name}</Typography>
										</ListItem>
									))}
								</List>
							</>
						)}
						{/* </Box> */}
					</Stack>
					{/* </Grid> */}
					<Stack
						direction="row"
						spacing={2}
						justifyContent="flex-end"
						alignItems="center"
					>
						<Button
							onClick={closeDialog}
							size="medium"
							variant="outlined"
							// color="primary"
						>
							Cancel
						</Button>
						<Button
							onClick={deleteFunction}
							size="medium"
							variant="contained"
							// color="error"
						>
							Delete
						</Button>
					</Stack>
				</Stack>

				{/* </Grid> */}
			</DialogContent>
		</Dialog>
	);
}

export default ConfirmBox;
