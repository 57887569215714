import { Button } from "@mui/material";
import React from "react";

export default function MainButton({
	text,
	variant,
	width,
	onClick,
	icon,
	isSaving,
	height,
}) {
	return (
		<Button
			variant={variant}
			sx={{
				width: width,
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				height: height,
				padding: 1,
			}}
			onClick={onClick}
			size="small"
		>
			{text}
			{icon && icon}
		</Button>
	);
}
