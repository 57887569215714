import * as React from "react";
import Select from "@mui/material/Select";
import { MenuItem, Checkbox } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Colours } from "../assets/global/Theme-variable";

export const DataGridCheckbox = React.forwardRef(function DataGridCheckbox(
	props,
	ref
) {
	const { id, value, field, valueOptions, width } = props;
	const handleChange = async (event) => {
		// console.log(event.target.checked);
		await ref.current.setEditCellValue({
			id,
			field,
			value:
				event.target.checked === true && field === "staydown"
					? 1
					: event.target.checked === false && field === "staydown"
					? 0
					: field === "ignoreThisList"
					? event.target.checked
					: event.target.checked,
		});
		ref.current.stopCellEditMode({ id, field });
	};

	return (
		<Checkbox
			icon={
				<CloseIcon
					style={{
						color: Colours.gsgrey,
					}}
				/>
			}
			checkedIcon={
				<CheckIcon
					style={{
						color: Colours.gsblue,
					}}
				/>
			}
			checked={value === 1 || value === "true" || value === true ? true : false}
			onChange={handleChange}
		/>
	);
});
