import { Controller } from "react-hook-form";

import { Autocomplete, TextField } from "@mui/material";

const ReactHookFormMultiSelectChip = ({
	name,
	label,
	control,
	defaultValue,
	onChange,
	children,
	fieldValueToSave,
	selectoptions,
	nameToShow,
	...props
}) => {
	return (
		<Controller
			render={({ field: { onChange, value, ref, onBlur }, ...props }) => (
				<Autocomplete
					multiple
					filterSelectedOptions
					options={selectoptions}
					getOptionLabel={(option) =>
						nameToShow ? option[nameToShow] : option.policy_name
					}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							label={label}
						/>
					)}
					onChange={(e, data) => onChange(data.map((v) => v[fieldValueToSave]))}
					{...props}
					limitTags={5}
					size="small"
				/>
			)}
			name={name}
			control={control}
			defaultValue={defaultValue}
		/>
	);
};
export default ReactHookFormMultiSelectChip;
