import React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Button, Modal } from "@mui/material";
import colours from "../data/colours";
import GridContext from "../context/GridContext";
import {
	autoFill,
	fillExistingList,
	fillList,
	groupBy,
	newPageFill,
} from "../../datagrids/utils";
import OptionSelect from "./OptionSelect";
import buttonTypes from "../data/buttonTypes";
import {
	Autocomplete,
	Checkbox,
	FormControlLabel,
	Typography,
} from "@mui/material";
import { DataGridContext } from "../../datagrids/data grid files/DataGridContext";
import { Colours } from "../../../assets/global/Theme-variable";
import axios from "axios";
import CustomSwitch from "../../../components/Switch";

export default function NewPageDialog({
	open,
	setNewVariant,
	newVariant,
	setOpen,
	page,
	setPage,
}) {
	const { setPageArr, setNewChanges, setVariant } =
		React.useContext(GridContext);

	const { data, setData } = React.useContext(DataGridContext);

	const handleClose = () => {
		setOpen(false);
	};

	let categories = groupBy(data, data.items, function (item) {
		return [item.plu_details.salesGroup];
	});
	const [group, setGroup] = React.useState(categories[0]);
	const [autofillList, setAutofillList] = React.useState(false);

	const handleSubmit = () => {
		setVariant((draft) => {
			const newList = { ...draft };
			newPageFill(
				newList,
				autofillList &&
					group?.items.filter(
						(item) => item.plu_number.charAt(item.plu_number.length - 1) === "0" && item.plu_isDeleted === 0
					),
				data.colours
			);

			setPage(newList.list_details.pages);
			setPageArr(
				Array.from(Array(newList.list_details.pages), (e, i) => i + 1)
			);
		});
		setNewChanges(true);

		setOpen(false);
	};

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "20px",
		width: 300,
		// minHeight: 400,
		bgcolor: "white",
		boxShadow: 24,
		p: 4,
		display: "flex",
		flexDirection: "column",
		alignItems: "stretch",
		gap: 2,
	};

	return (
		<Modal open={open}>
			<Box sx={style}>
				<Typography variant="h3">New Page</Typography>

				{/* <Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				> */}
				{/* <FormControlLabel
						value={autofillList}
						sx={{ m: 0, mb: 2, alignSelf: "flex-start" }}
						control={
							<Checkbox
								checked={autofillList}
								onChange={() => setAutofillList(!autofillList)}
								inputProps={{ "aria-label": "autofill page with PLU group" }}
								disableRipple
							/>
						}
						label={
							<Typography
								sx={{
									fontSize: 12,
								}}
							>
								Autofill Page with PLU group
							</Typography>
						}
						labelPlacement="start"
					/> */}
				<Box sx={{ width: "100%", alignSelf: "flex-start" }}>
					<CustomSwitch
						label="Autofill Page?"
						value={autofillList}
						onChange={() => setAutofillList(!autofillList)}
					/>
				</Box>
				{autofillList && (
					<OptionSelect
						options={categories}
						value={categories.find((i)=> i.group === group.group)}
						// defaultoption={categories[0]}
						label={"Category"}
						onChange={(event) => setGroup(event.target.value)}
						type="group"
						width={"100%"}
						mb={2}
					/>
				)}
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
					}}
				>
					<Button onClick={handleClose} variant="outlined">
						Cancel
					</Button>
					<Button variant="contained" onClick={handleSubmit}>
						Add
					</Button>
				</Box>
				{/* </Box> */}
			</Box>
		</Modal>
	);
}
