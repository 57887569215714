import React from "react";
import GridContext from "../context/GridContext";
// import GridItem from "./GridItem";
import { Box } from "@mui/material";
import NewGridRefactor from "./NewGridRefactor";
import { DataGridContext } from "../../datagrids/data grid files/DataGridContext";

export default function Main({
	setOpen,
	open,
	onDrop,
	colNo,
	rowNo,
	editBar,
	selectedTextColour,
	textAlign,
	selectedColour,
	handleOpen,
	setItem,
	openItem,
	cellCount,
	draggingItem,
	activeId,
	setDraggingItem,
	setList,
	errorMessage,
	list,
	setErrorMessage,
}) {
	const { data } = React.useContext(DataGridContext);
	const { items, variant, listArea } = React.useContext(GridContext);
	// console.log(variant);
	return (
		<>
			<Box
				display="grid"
				gridTemplateColumns="repeat(10, 1fr)"
				gridTemplateRows="repeat(10, 1fr) "
				sx={{
					flex: 1,
					height: "100%",
					padding: 0,
				}}
			>
				<NewGridRefactor
					handleOpen={handleOpen}
					disableSelection={true}
					setErrorMessage={setErrorMessage}
					colNo={colNo}
					rowNo={rowNo}
					openItem={openItem}
					selectedColour={selectedColour}
					selectedTextColour={selectedTextColour}
					textAlign={textAlign}
					draggingItem={draggingItem}
					setDraggingItem={setDraggingItem}
					editBar={editBar}
					setList={setList}
					activeId={activeId}
				/>
			</Box>
		</>
	);
}
