import * as React from "react";
import PropTypes from "prop-types";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

import {
	Box,
	Chip,
	InputLabel,
	TextField,
	Typography,
	autocompleteClasses,
	useAutocomplete,
} from "@mui/material";

import { DataGridContext } from "./DataGridContext";
import { getTextColour } from "../utils";

const Root = styled("div")(
	({ theme }) => `
    background-color: none;
	

  font-size: 14px;
`
);

const Label = styled("label")`
	padding: 0 0 4px;
	line-height: 1.5;
	display: block;
`;

const InputWrapper = styled("div")(
	({ theme }) => `
  border: 'none';
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: wrap;
background-color: none;
  &:hover {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};

  }

  &.focused {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    color: ${
			theme.palette.mode === "dark"
				? "rgba(255,255,255,0.65)"
				: "rgba(0,0,0,.85)"
		};
        background-color: transparent;

    box-sizing: border-box;
    width: 100%;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

function Tag(props) {
	const { label, onDelete, ...other } = props;
	const colour = getTextColour(props.colour).colour;
	//console.log(colour);
	return (
		<div {...other}>
			<Typography sx={{ fontSize: 10, color: colour }}>{label}</Typography>
			<CloseIcon color={colour} onClick={onDelete} />
		</div>
	);
}

function Option(props) {
	const { label, onDelete, ...other } = props;
	return (
		<div {...other}>
			<Typography sx={{ fontSize: 10, color: "white" }}>{label}</Typography>
			<CloseIcon
				sx={{ color: `${props.colour} !important` }}
				onClick={onDelete}
			/>
		</div>
	);
}

Tag.propTypes = {
	label: PropTypes.string.isRequired,
	onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
	(props) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${props.colour};
  border: 1px solid ${props.colour};
  border-radius: 6px;
  box-sizing: content-box;
  padding: 2px 4px 2px 8px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${props.colour};
    background-color: ${props.colour};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${getTextColour(props.colour).colour};
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
    color: ${getTextColour(props.colour).colour};
  }
`
);

const StyledOption = styled(Option)(
	(props) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${props.colour};
  border: 1px solid ${props.colour};
  border-radius: 6px;
  box-sizing: content-box;
  padding: 2px 4px 2px 8px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${props.colour};
    background-color: ${props.colour};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
	color: ${getTextColour(props.colour).colour};

  }
`
);
// const Listbox = styled("ul")(
// 	({theme}) => `
//   width: 300px;
//   margin: 2px 0 0;
//   padding: 0;
//   position: absolute;
// display: flex;
// flex-direction: row;
// flex-wrap: wrap;
//   background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
//   overflow: auto;
//   border-radius: 4px;
//   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
//   z-index: 1;

//   & li {
//     display: flex;

//     & span {
//       flex-grow: 1;
//     }

//     & svg {
//       color: transparent;
//     }
//   }

//   & li[aria-selected='true'] {
//     background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
//     font-weight: 600;

//     & svg {
//       color: white;
//     }
//   }

//   & li.${autocompleteClasses.focused} {
//     background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
//     cursor: pointer;

//     & svg {
//       color: white;
//     }
//   }
// `
// );

const Listbox = styled("ul")(
	({ theme }) => `
  width: '100%';
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

export const EmmasAutoCompleteTagCell = React.forwardRef(
	function EmmasAutoCompleteTagCell(props, ref) {
		const { data, setData } = React.useContext(DataGridContext);

		// Initialize the value state once with the provided default value
		const defaultValue = props.value;
		const [selectedOptions, setSelectedOptions] = React.useState(
			defaultValue || []
		);

		const {
			getRootProps,
			getInputProps,
			getTagProps,
			getListboxProps,
			getOptionProps,
			groupedOptions,
			value,
			focused,
			setAnchorEl,
		} = useAutocomplete({
			value: selectedOptions,
			onChange: (event, newValue) => {
				setSelectedOptions(newValue); // Update selectedOptions when value changes
			},
			multiple: true,
			options: data.tags.filter((i) => i.tag_isDeleted === 0),
			getOptionLabel: (option) => option.tag_name,
			isOptionEqualToValue: (option, value) => {
				if (option && option.tag_ref && value && value.tag_ref) {
					return option.tag_ref === value.tag_ref;
				}
				return false;
			},
		});

		const { id, field } = props;

		// update the cell value and stop the cell edit mode whenever the value, field, id, or ref changes.
		React.useEffect(() => {
			// Check if 'value' is defined. If 'value' is undefined, null, false, NaN, 0, or an empty string, the rest of the code inside the 'if' statement will not run.
			if (value) {
				// Call 'setEditCellValue' on the current ref object. This is likely updating the value of a cell in a data grid.
				// The new value is obtained by mapping over 'value' and returning the 'tag_ref' property of each item.
				// If an item doesn't have a 'tag_ref' property, it will return undefined.
				ref.current.setEditCellValue({
					id,
					field,
					value: value.map((val) => val && val.tag_ref),
				});

				// Call 'stopCellEditMode' on the current ref object. This is likely exiting the edit mode of a cell in a data grid.
				ref.current.stopCellEditMode({ id, field });
			}
		}, [value, field, id, ref]); // These are the dependencies of the effect. The effect will run whenever any of these values change.
		// const limitTags = 3;
		return (
			<Root sx={{ width: "100%" }}>
				<div {...getRootProps()}>
					<InputWrapper ref={setAnchorEl}>
						{value?.map((option, index) => {
							if (option && option.tag_name && option.tag_ref) {
								return (
									<StyledTag
										label={option.tag_name}
										key={option.tag_ref}
										{...getTagProps({ index })}
										colour={option.tag_details?.styling?.colour}
									/>
								);
							}
							return null;
						})}

						<input {...getInputProps()} />
					</InputWrapper>
				</div>
				{groupedOptions.length > 0 ? (
					<Listbox {...getListboxProps()}>
						{groupedOptions?.map((option, index) => (
							<li {...getOptionProps({ option, index })} key={option.tag_ref}>
								<Typography>{option.tag_name}</Typography>
								<CheckIcon fontSize="small" sx={{ marginLeft: 1 }} />
							</li>
						))}
					</Listbox>
				) : null}
			</Root>
		);
	}
);
