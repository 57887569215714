import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Typography } from "@mui/material";
import { Colours } from "../assets/global/Theme-variable";

export default function SelectGroup({
	label,
	values,
	onChange,
	selectedModifiers,
}) {
	return (
		<FormControl component="fieldset" sx={{ width: "100%" }}>
			{/* <FormLabel component="legend"> */}
			<Typography sx={{ color: Colours.gsblue, fontSize: 14 }}>
				{label}
			</Typography>
			{/* </FormLabel> */}
			<FormGroup
				aria-label="position"
				row
				sx={{ alignItems: "space-between", justifyContent: "space-between" }}
			>
				{values.map((value, index) => (
					<FormControlLabel
						value={value}
						control={
							<Checkbox
								size="small"
								onChange={onChange}
								name={value.number}
								checked={selectedModifiers[value]}
								// color="#c4c4c4"
							/>
						}
						label={value.prefix}
						labelPlacement="end"
						sx={{ width: 100 }}
						key={index}
					/>
				))}
			</FormGroup>
		</FormControl>
	);
}

// import * as React from "react";
// import Checkbox from "@mui/material/Checkbox";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
// import { Typography } from "@mui/material";
// import { Colours } from "../assets/global/Theme-variable";

// export default function SelectGroup({
// 	label,
// 	options,
// 	value,
// 	onChange,
// 	selectedMods,
// }) {
// 	// field
// 	console.log(value);
// 	return (
// 		<FormControl component="fieldset" sx={{ width: "100%" }}>
// 			{/* <FormLabel component="legend"> */}
// 			<Typography sx={{ color: Colours.gsblue, fontSize: 14 }}>
// 				{label}
// 			</Typography>
// 			{/* </FormLabel> */}
// 			<FormGroup
// 				aria-label="position"
// 				row
// 				sx={{ alignItems: "space-between", justifyContent: "space-between" }}
// 			>
// 				{options?.map((option, index) => (
// 					<FormControlLabel
// 						value={option}
// 						control={
// 							<Checkbox
// 								size="small"
// 								onChange={onChange}
// 								name={option.number}
// 								checked={console.log(
// 									value[option.number] && value[option.number] === true
// 								)}
// 								// color="#c4c4c4"
// 							/>
// 						}
// 						label={option.prefix}
// 						labelPlacement="end"
// 						sx={{ width: 100 }}
// 						key={index}
// 					/>
// 				))}
// 			</FormGroup>
// 		</FormControl>
// 	);
// }
