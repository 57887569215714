import { FormControl } from "@mui/base";
import { Box, InputLabel, TextField, Typography } from "@mui/material";
import React from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Colours } from "../assets/global/Theme-variable";
import { NumberFormatCustom } from "../views/datagrids/utils";

export const DisguisedTextField = ({
	onChange,
	defaultValue,
	focused,
	setFocused,
	price,
	value,
	setPrice,
	disabled,
}) => {
	return (
		<TextField
			value={value.toFixed(2)}
			onChange={onChange}
			size="small"
			disabled={disabled}
			name="numberformat"
			InputProps={{
				inputComponent: NumberFormatCustom,
				startAdornment: <InputAdornment position="start">£</InputAdornment>,
				sx: {
					color: Colours.gsblue,
					padding: 0,
					// padding: "1px 5px",
				},
				disableUnderline: true,
			}}
			variant="standard"
			sx={{
				"& .MuiFilledInput-underline": {
					bgcolor: Colours.fieldBackground,
				},
				"& :hover.MuiFilledInput-underline": {
					bgcolor: Colours.fieldBackground,
				},
				"& .css-nz481w-MuiInputBase-input-MuiInput-input": {
					// padding: "10px 6px",
					color: Colours.gsblue,
					border: "none",
					padding: 0,
				},
				"& .MuiInputBase-input": {
					padding: 0,
				},
				"& .MuiInput": {
					padding: 0,
				},
			}}
		/>
	);
};

const InputAdornment = styled("div")`
	display: inline-flex;
	align-items: center;
	justify-content: center;
`;
