export const fontSizeOptions = [
	{
		value: "8B",
		name: 8,
	},
	{
		value: "10B",
		name: 10,
	},
	{
		value: "11B",
		name: 11,
	},
	{
		value: "12B",
		name: 12,
	},
	{
		value: "14B",
		name: 14,
	},
	{
		value: "16B",
		name: 16,
	},
	{
		value: "18B",
		name: 18,
	},
	{
		value: "20B",
		name: 20,
	},
	{
		value: "22B",
		name: 22,
	},
	{
		value: "24B",
		name: 24,
	},
];
