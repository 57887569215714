import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ItemModifiers from "./ItemModifiers";
import { Colours } from "../assets/global/Theme-variable";
import { Box, IconButton, List, ListItem, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	maxHeight: "90%",
	overflow: "scroll",
	border: `1px solid ${theme.palette.divider}`,
	borderRadius: 4,
	mb: 2,
	flex: 1,
	"&:not(:last-child)": {
		borderBottom: 0,
		// borderTop: "1px solid red",
	},
	"&:nth-of-type(2)": {
		// borderBottom: 0,
		// borderTop: "1px solid red",
		borderRadius: "0px 0px 4px 4px",
	},
	"&:nth-of-type(1)": {
		// borderBottom: 0,
		// borderTop: "1px solid red",
		borderRadius: "4px 4px 0px 0px",
	},
	"&::before": {
		display: "none",
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
		{...props}
	/>
))(({ theme }) => ({
	// backgroundColor:
	// 	theme.palette.mode === "dark"
	// 		? "rgba(255, 255, 255, .05)"
	// 		: "rgba(0, 0, 0, .03)",
	flex: 1,
	flexDirection: "row-reverse",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(90deg)",
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: "1px solid rgba(0, 0, 0, .125)",
	overflow: "scroll",
	minHeight: "100%",
	flex: 1,
}));

export default function Accordions({
	item,
	setItem,
	modifiers,
	setModifiers,
	appears,
}) {
	const [expanded, setExpanded] = React.useState("panel1");
	const navigate = useNavigate();
	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	return (
		<>
			<Accordion
				expanded={expanded === "panel1"}
				onChange={handleChange("panel1")}
			>
				<AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
					<Typography
						sx={{
							// fontSize: 14,
							mb: 0,
							color: Colours.gsblue,
							justifySelf: "center",
						}}
					>
						Price Variants
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<ItemModifiers
						item={item}
						setItem={setItem}
						modifiers={modifiers}
						setModifiers={setModifiers}
					/>
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expanded === "panel2"}
				onChange={handleChange("panel2")}
			>
				<AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
					<Typography
						sx={{
							// fontSize: 14,
							mb: 0,
							color: Colours.gsblue,
							justifySelf: "center",
						}}
					>
						Associated Screens
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					{/* <Box
						sx={{
							width: "100%",
							display: "flex",
							flexDirection: "column",
							// gap: 2,
							flex: 1,
							minHeight: "100%",
						}}
					> */}
					{appears?.length > 0 ? (
						<List dense={true}>
							{appears?.map((list) => (
								<ListItem
									sx={{
										backgroundColor: Colours.fieldBackground,
										mb: 2,
										borderRadius: "4px",
									}}
									key={list.list_uid}
								>
									<ListItemText primary={list.list_name} />
									<IconButton
										aria-label="delete"
										size="small"
										onClick={() =>
											navigate("/designer", {
												state: { item: list },
											})
										}
									>
										<ArrowForwardIcon
											color={Colours.gsblue}
											// onClick={() =>
											// 	navigate("/designer", {
											// 		state: { item: list },
											// 	})
											// }
											size="10px"
										/>
									</IconButton>
								</ListItem>
							))}
						</List>
					) : (
						<Typography>This item does not appear on any screens</Typography>
					)}
					{/* </Box> */}
				</AccordionDetails>
			</Accordion>
		</>
	);
}
