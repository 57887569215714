// import React from "react";
// import { DataGridContext } from "./DataGridContext";

// export function TagConfig(module) {
// 	const { data, setData } = React.useContext(DataGridContext);

// 	const TagConfigData = [
// 		{
// 			module_name: "Allergen",
// 			module_tag: "allergen",
// 			moduleOptions: [
// 				{
// 					option_name: "Severity",
// 					option_tag: "severity",
// 					option_type: "list",
// 					option_values: [
// 						{ value: "contains", text: "contains" },
// 						{ value: "contaminant", text: "contaminant" },
// 						{ value: "may contain", text: "may contain" },
// 						{ value: "does not contain", text: "does not contain" },
// 					],
// 				},
// 				{
// 					option_name: "Allergen",
// 					option_tag: "allergen",
// 					option_type: "list",
// 					option_values: [
// 						{ value: "Celery", text: "Celery" },
// 						{
// 							value: "Cereals containing gluten",
// 							text: "Cereals containing gluten",
// 						},
// 						{ value: "Crustaceans", text: "Crustaceans" },
// 						{ value: "Eggs", text: "Eggs" },
// 						{ value: "Lupin", text: "Lupin" },
// 						{ value: "Fish", text: "Fish" },
// 						{ value: "Milk", text: "Milk" },
// 						{ value: "Molluscs", text: "Molluscs" },
// 						{ value: "Mustard", text: "Mustard" },
// 						{ value: "Nuts", text: "Nuts" },
// 						{ value: "Peanuts", text: "Peanuts" },
// 						{ value: "Sesame seeds", text: "Sesame seeds" },
// 						{ value: "Soya", text: "Soya" },
// 						{ value: "Sulphur Dioxide", text: "Sulphur Dioxide" },
// 					],
// 				},
// 			],
// 		},
// 		// {
// 		// 	module_name: "Dietary",
// 		// 	module_tag: "dietary",
// 		// 	moduleOptions: [
// 		// 		{
// 		// 			option_name: "Diet",
// 		// 			option_tag: "diet",
// 		// 			option_type: "list",
// 		// 			option_values: [
// 		// 				{ value: "Vegan", text: "Vegan" },
// 		// 				{ value: "Vegaterian", text: "Vegaterian" },
// 		// 				{ value: "Halal", text: "Halal" },
// 		// 				{ value: "Kosher", text: "Kosher" },
// 		// 			],
// 		// 		},
// 		// 		{
// 		// 			option_name: "Override",
// 		// 			option_tag: "override",
// 		// 			option_type: "True/False",
// 		// 		},
// 		// 	],
// 		// },
// 		{
// 			module_name: "Age Check",
// 			module_tag: "ageCheck",
// 			moduleOptions: [
// 				{
// 					option_name: "Age",
// 					option_tag: "ageCheck",
// 					option_type: "list",
// 					option_values: [
// 						{ value: "16", text: "16" },
// 						{ value: "18", text: "18" },
// 						{ value: "21", text: "21" },
// 						{ value: "25", text: "25" },
// 					],
// 				},
// 			],
// 		},
// 		{
// 			module_name: "Discountable",
// 			module_tag: "discountable",
// 			moduleOptions: [
// 				{
// 					option_name: "Enable",
// 					option_tag: "discountable",
// 					option_type: "True/False",
// 				},
// 			],
// 		},
// 		{
// 			module_name: "Printer",
// 			module_tag: "printer",
// 			moduleOptions: [
// 				{
// 					option_name: "Printer Location",
// 					option_tag: "printer",
// 					option_type: "list",
// 					option_values: [
// 						{ value: 0, text: "None" },
// 						...data.printers.map((option) => ({
// 							value: option.printer_ref,
// 							text: option.printer_name,
// 						})),
// 					],
// 				},
// 			],
// 		},

// 		// Added and ready to use in the second fase, when Tag types is ready
// 		// {
// 		// 	module_name: "Location",
// 		// 	module_tag: "location",
// 		// 	moduleOptions: [
// 		// 		{
// 		// 			option_name: "Concept",
// 		// 			option_tag: "location",
// 		// 			option_type: "list",
// 		// 			option_values: [{ value: "", text: "" }],
// 		// 		},
// 		// 		{
// 		// 			option_name: "Region",
// 		// 			option_tag: "location",
// 		// 			option_type: "list",
// 		// 			option_values: [{ value: "", text: "" }],
// 		// 		},
// 		// 	],
// 		// },

// 		{
// 			module_name: "Available Online",
// 			module_tag: "availableOnline",
// 			moduleOptions: [
// 				{
// 					option_name: "Enable",
// 					option_tag: "online",
// 					option_type: "True/False",
// 				},
// 				{
// 					option_name: "Online Only",
// 					option_tag: "onlineOnly",
// 					option_type: "True/False",
// 				},
// 			],
// 		},
// 		{
// 			module_name: "Item Type",
// 			module_tag: "itemType",
// 			moduleOptions: [
// 				{
// 					option_name: "Type",
// 					option_tag: "type",
// 					option_type: "list",
// 					option_values: [
// 						{ value: 0, text: "General" },
// 						{ value: 1, text: "Extras" },
// 						{ value: 20, text: "Condiment" },
// 						{ value: 30, text: "Deposit" },
// 						{ value: 31, text: "Information" },
// 						{ value: 40, text: "Dummy" },
// 						{ value: 41, text: "Qualifier" },
// 						{ value: 42, text: "Other" },
// 					],
// 				},
// 			],
// 		},
// 		{
// 			module_name: "Price Type",
// 			module_tag: "priceType",
// 			moduleOptions: [
// 				{
// 					option_name: "Price Options",
// 					option_tag: "priceType",
// 					option_type: "list",
// 					option_values: [
// 						{ value: 0, text: "Non-priced" },
// 						{ value: 2, text: "Preset price" },
// 						{ value: 1, text: "Price check" },
// 					],
// 				},
// 			],
// 		},
// 		{
// 			module_name: "Analysis Category",
// 			module_tag: "analysis",
// 			moduleOptions: [
// 				{
// 					option_name: "Analysis Category",
// 					option_tag: "analysisCategory_number",
// 					option_type: "list",
// 					option_values: data.analysisCategory.map((option) => ({
// 						value: option.analysisCategory_number,
// 						text: option.analysisCategory_name,
// 					})),
// 				},
// 			],
// 		},
// 		{
// 			module_name: "VAT Rating",
// 			module_tag: "VAT",
// 			moduleOptions: [
// 				{
// 					option_name: "VAT Options",
// 					option_tag: "VAT",
// 					option_type: "list",
// 					option_values: [
// 						{
// 							value: "A",
// 							text: "A - Standard (20/20)",
// 							// added in title which is related tio the tool tip
// 							title: "Standard VAT rate (20% on both goods and services)",
// 						},
// 						{
// 							value: "B",
// 							text: "B - Zero (0/0)",
// 							title: "Zero-rated VAT (0% on both goods and services)",
// 						},
// 						{
// 							value: "C",
// 							text: "C - Reduced (5/5)",
// 							title: "Reduced VAT rate (5% on both goods and services)",
// 						},
// 						{
// 							value: "D",
// 							text: "D - Standard (20/0)",
// 							title: "Standard VAT rate on goods (20%), 0% on services",
// 						},
// 						{
// 							value: "E",
// 							text: "E - Reduced (5/0)",
// 							title: "Reduced VAT rate on goods (5%), 0% on services",
// 						},
// 						{
// 							value: "F",
// 							text: "F - Reduced (5/20)",
// 							title: "Reduced VAT rate on goods (5%), 20% on services",
// 						},
// 					], // has a rate so we show the friendly name of the options
// 				},
// 			],
// 		},
// 		{
// 			module_name: "Allow Modifer",
// 			module_tag: "allowModifer",
// 			moduleOptions: [
// 				{
// 					option_name: "Enable",
// 					option_tag: "allowModifer",
// 					option_type: "True/False",
// 				},
// 			],
// 		},
// 		{
// 			module_name: "Not Alone On Kp",
// 			module_tag: "notAloneOnKp",
// 			moduleOptions: [
// 				{
// 					option_name: "Enable",
// 					option_tag: "notAloneOnKp",
// 					option_type: "True/False",
// 				},
// 			],
// 		},
// 		{
// 			module_name: "kp Consolidate Top Item",
// 			module_tag: "kpConsolidateTopItem",
// 			moduleOptions: [
// 				{
// 					option_name: "Enable",
// 					option_tag: "kpConsolidateTopItem",
// 					option_type: "True/False",
// 				},
// 			],
// 		},
// 		{
// 			module_name: "Automatic Quantity (covers)",
// 			module_tag: "automaticQuantitycovers",
// 			moduleOptions: [
// 				{
// 					option_name: "Enable",
// 					option_tag: "automaticQuantitycovers",
// 					option_type: "True/False",
// 				},
// 			],
// 		},
// 		{
// 			module_name: "Free Text",
// 			module_tag: "freeText",
// 			moduleOptions: [
// 				{
// 					option_name: "Enable",
// 					option_tag: "freeText",
// 					option_type: "True/False",
// 				},
// 			],
// 		},
// 		{
// 			module_name: "Free Text and Description",
// 			module_tag: "freeText_+_Desc",
// 			moduleOptions: [
// 				{
// 					option_name: "Enable",
// 					option_tag: "freeText_+_Desc",
// 					option_type: "True/False",
// 				},
// 			],
// 		},
// 		{
// 			module_name: "Sales Tracking",
// 			module_tag: "salesTracking",
// 			moduleOptions: [
// 				{
// 					option_name: "Enable",
// 					option_tag: "salesTracking",
// 					option_type: "True/False",
// 				},
// 			],
// 		},
// 		{
// 			module_name: "EAN On Receipt",
// 			module_tag: "ean_On_Receipt",
// 			moduleOptions: [
// 				{
// 					option_name: "Enable",
// 					option_tag: "ean_On_Receipt",
// 					option_type: "True/False",
// 				},
// 			],
// 		},
// 		{
// 			module_name: "Follow On",
// 			module_tag: "followOn",
// 			moduleOptions: [
// 				{
// 					option_name: "Screen 1",
// 					option_tag: "followOn1",
// 					option_type: "list",
// 					option_values: [
// 						{ value: 0, text: "None" },
// 						...data.screens.map((option) => ({
// 							value: option.list_number,
// 							text: `${option.list_number} - ${option.list_name}`, // Concatenate list number and name
// 						})),
// 					],
// 				},
// 				{
// 					option_name: "Screen 2",
// 					option_tag: "followOn2",
// 					option_type: "list",
// 					option_values: [
// 						{ value: 0, text: "None" },
// 						...data.screens.map((option) => ({
// 							value: option.list_number,
// 							text: `${option.list_number} - ${option.list_name}`, // Concatenate list number and name
// 						})),
// 					],
// 				},
// 				{
// 					option_name: "Screen 3",
// 					option_tag: "followOn3",
// 					option_type: "list",
// 					option_values: [
// 						{ value: 0, text: "None" },
// 						...data.screens.map((option) => ({
// 							value: option.list_number,
// 							text: `${option.list_number} - ${option.list_name}`, // Concatenate list number and name
// 						})),
// 					],
// 				},
// 			],
// 		},
// 	];
// 	// console.log("what are the lists", data.screens);

// 	switch (module) {
// 		case "list":
// 			// const tagOptions = TagConfig("list").map((option) => ({
// 			//   label: option.prettyName, // The pretty name shown to the user
// 			//   value: option.code, // The associated code
// 			// }));
// 			return TagConfigData.map((tag) => ({
// 				label: tag.module_name,
// 				value: tag.module_tag,
// 			}));
// 			break;
// 		case "":
// 			return TagConfigData;
// 			break;
// 		default:
// 			// will filter and then just return the first element (there should only be one anyway)
// 			return TagConfigData.filter((tag) => tag.module_tag === module)[0];
// 	}
// }

// Emma amended code 8th March, old unchanged code commented out above incase new code causes any issues
import React from "react";
import { DataGridContext } from "./DataGridContext";

export function TagConfig(module, data) {
	// had to take usecontext hook out due to console error RE changes in hooks, passing data through when function called instead

	const TagConfigData = [
		{
			module_name: "Allergen",
			module_tag: "allergen",
			moduleOptions: [
				{
					option_name: "Severity",
					option_tag: "severity",
					option_type: "list",
					option_values: [
						{ value: "contains", text: "contains" },
						{ value: "contaminant", text: "contaminant" },
						{ value: "may contain", text: "may contain" },
						{ value: "does not contain", text: "does not contain" },
					],
				},
				{
					option_name: "Allergen",
					option_tag: "allergen",
					option_type: "list",
					option_values: [
						{ value: "Celery", text: "Celery" },
						{
							value: "Cereals containing gluten",
							text: "Cereals containing gluten",
						},
						{ value: "Crustaceans", text: "Crustaceans" },
						{ value: "Eggs", text: "Eggs" },
						{ value: "Lupin", text: "Lupin" },
						{ value: "Fish", text: "Fish" },
						{ value: "Milk", text: "Milk" },
						{ value: "Molluscs", text: "Molluscs" },
						{ value: "Mustard", text: "Mustard" },
						{ value: "Nuts", text: "Nuts" },
						{ value: "Peanuts", text: "Peanuts" },
						{ value: "Sesame seeds", text: "Sesame seeds" },
						{ value: "Soya", text: "Soya" },
						{ value: "Sulphur Dioxide", text: "Sulphur Dioxide" },
					],
				},
			],
		},
		// {
		// 	module_name: "Dietary",
		// 	module_tag: "dietary",
		// 	moduleOptions: [
		// 		{
		// 			option_name: "Diet",
		// 			option_tag: "diet",
		// 			option_type: "list",
		// 			option_values: [
		// 				{ value: "Vegan", text: "Vegan" },
		// 				{ value: "Vegaterian", text: "Vegaterian" },
		// 				{ value: "Halal", text: "Halal" },
		// 				{ value: "Kosher", text: "Kosher" },
		// 			],
		// 		},
		// 		{
		// 			option_name: "Override",
		// 			option_tag: "override",
		// 			option_type: "True/False",
		// 		},
		// 	],
		// },
		{
			module_name: "Age Check",
			module_tag: "ageCheck",
			moduleOptions: [
				{
					option_name: "Age",
					option_tag: "ageCheck",
					option_type: "list",
					option_values: [
						{ value: "16", text: "16" },
						{ value: "18", text: "18" },
						{ value: "21", text: "21" },
						{ value: "25", text: "25" },
					],
				},
			],
		},
		{
			module_name: "Discountable",
			module_tag: "discountable",
			moduleOptions: [
				{
					option_name: "Enable",
					option_tag: "discountable",
					option_type: "True/False",
				},
			],
		},
		{
			module_name: "Printer",
			module_tag: "printer",
			moduleOptions: [
				{
					option_name: "Printer Location",
					option_tag: "printer",
					option_type: "list",
					option_values: [
						{ value: 0, text: "None" },
						...data?.printers?.map((option) => ({
							value: option.printer_ref,
							text: option.printer_name,
						})),
					],
				},
			],
		},

		// Added and ready to use in the second fase, when Tag types is ready
		// {
		// 	module_name: "Location",
		// 	module_tag: "location",
		// 	moduleOptions: [
		// 		{
		// 			option_name: "Concept",
		// 			option_tag: "location",
		// 			option_type: "list",
		// 			option_values: [{ value: "", text: "" }],
		// 		},
		// 		{
		// 			option_name: "Region",
		// 			option_tag: "location",
		// 			option_type: "list",
		// 			option_values: [{ value: "", text: "" }],
		// 		},
		// 	],
		// },

		{
			module_name: "Available Online",
			module_tag: "availableOnline",
			moduleOptions: [
				{
					option_name: "Enable",
					option_tag: "online",
					option_type: "True/False",
				},
				{
					option_name: "Online Only",
					option_tag: "onlineOnly",
					option_type: "True/False",
				},
			],
		},
		{
			module_name: "Item Type",
			module_tag: "itemType",
			moduleOptions: [
				{
					option_name: "Type",
					option_tag: "type",
					option_type: "list",
					option_values: [
						{ value: 0, text: "General" },
						{ value: 1, text: "Extras" },
						{ value: 20, text: "Condiment" },
						{ value: 30, text: "Deposit" },
						{ value: 31, text: "Information" },
						{ value: 40, text: "Dummy" },
						{ value: 41, text: "Qualifier" },
						{ value: 42, text: "Other" },
					],
				},
			],
		},
		{
			module_name: "Price Type",
			module_tag: "priceType",
			moduleOptions: [
				{
					option_name: "Price Options",
					option_tag: "priceType",
					option_type: "list",
					option_values: [
						{ value: 0, text: "Non-priced" },
						{ value: 2, text: "Preset price" },
						{ value: 1, text: "Price check" },
					],
				},
			],
		},
		{
			module_name: "Analysis Category",
			module_tag: "analysis",
			moduleOptions: [
				{
					option_name: "Analysis Category",
					option_tag: "analysisCategory_number",
					option_type: "list",
					option_values: data.analysisCategory.map((option) => ({
						value: option.analysisCategory_number,
						text: option.analysisCategory_name,
					})),
				},
			],
		},
		{
			module_name: "VAT Rating",
			module_tag: "VAT",
			moduleOptions: [
				{
					option_name: "VAT Options",
					option_tag: "VAT",
					option_type: "list",
					option_values: [
						{
							value: "A",
							text: "A - Standard (20/20)",
							// added in title which is related tio the tool tip
							title: "Standard VAT rate (20% on both goods and services)",
						},
						{
							value: "B",
							text: "B - Zero (0/0)",
							title: "Zero-rated VAT (0% on both goods and services)",
						},
						{
							value: "C",
							text: "C - Reduced (5/5)",
							title: "Reduced VAT rate (5% on both goods and services)",
						},
						{
							value: "D",
							text: "D - Standard (20/0)",
							title: "Standard VAT rate on goods (20%), 0% on services",
						},
						{
							value: "E",
							text: "E - Reduced (5/0)",
							title: "Reduced VAT rate on goods (5%), 0% on services",
						},
						{
							value: "F",
							text: "F - Reduced (5/20)",
							title: "Reduced VAT rate on goods (5%), 20% on services",
						},
					], // has a rate so we show the friendly name of the options
				},
			],
		},
		{
			module_name: "Allow Modifer",
			module_tag: "allowModifer",
			moduleOptions: [
				{
					option_name: "Enable",
					option_tag: "allowModifer",
					option_type: "True/False",
				},
			],
		},
		{
			module_name: "Not Alone On Kp",
			module_tag: "notAloneOnKp",
			moduleOptions: [
				{
					option_name: "Enable",
					option_tag: "notAloneOnKp",
					option_type: "True/False",
				},
			],
		},
		{
			module_name: "kp Consolidate Top Item",
			module_tag: "kpConsolidateTopItem",
			moduleOptions: [
				{
					option_name: "Enable",
					option_tag: "kpConsolidateTopItem",
					option_type: "True/False",
				},
			],
		},
		{
			module_name: "Automatic Quantity (covers)",
			module_tag: "automaticQuantitycovers",
			moduleOptions: [
				{
					option_name: "Enable",
					option_tag: "automaticQuantitycovers",
					option_type: "True/False",
				},
			],
		},
		{
			module_name: "Free Text",
			module_tag: "freeText",
			moduleOptions: [
				{
					option_name: "Enable",
					option_tag: "freeText",
					option_type: "True/False",
				},
			],
		},
		{
			module_name: "Free Text and Description",
			module_tag: "freeText_+_Desc",
			moduleOptions: [
				{
					option_name: "Enable",
					option_tag: "freeText_+_Desc",
					option_type: "True/False",
				},
			],
		},
		{
			module_name: "Sales Tracking",
			module_tag: "salesTracking",
			moduleOptions: [
				{
					option_name: "Enable",
					option_tag: "salesTracking",
					option_type: "True/False",
				},
			],
		},
		{
			module_name: "EAN On Receipt",
			module_tag: "ean_On_Receipt",
			moduleOptions: [
				{
					option_name: "Enable",
					option_tag: "ean_On_Receipt",
					option_type: "True/False",
				},
			],
		},
		{
			module_name: "Follow On",
			module_tag: "followOn",
			moduleOptions: [
				{
					option_name: "Screen 1",
					option_tag: "followOn1",
					option_type: "list",
					option_values: [
						{ value: 0, text: "None" },
						...data.screens
							.filter(
								(i) =>
									i?.list_details?.ignoreThisList !== true &&
									i?.list_variant === 0
							)
							.map((option) => ({
								value: option.list_number,
								text: `${option.list_number} - ${option.list_name}`, // Concatenate list number and name
							})),
					],
				},
				{
					option_name: "Screen 2",
					option_tag: "followOn2",
					option_type: "list",
					option_values: [
						{ value: 0, text: "None" },
						...data.screens
							.filter(
								(i) =>
									i?.list_details?.ignoreThisList !== true &&
									i?.list_variant === 0
							)
							.map((option) => ({
								value: option.list_number,
								text: `${option.list_number} - ${option.list_name}`, // Concatenate list number and name
							})),
					],
				},
				{
					option_name: "Screen 3",
					option_tag: "followOn3",
					option_type: "list",
					option_values: [
						{ value: 0, text: "None" },
						...data.screens
							.filter(
								(i) =>
									i?.list_details?.ignoreThisList !== true &&
									i?.list_variant === 0
							)
							.map((option) => ({
								value: option.list_number,
								text: `${option.list_number} - ${option.list_name}`, // Concatenate list number and name
							})),
					],
				},
			],
		},
	];
	// console.log("what are the lists", data.screens);

	switch (module) {
		case "list":
			// const tagOptions = TagConfig("list").map((option) => ({
			//   label: option.prettyName, // The pretty name shown to the user
			//   value: option.code, // The associated code
			// }));
			return TagConfigData.map((tag) => ({
				label: tag.module_name,
				value: tag.module_tag,
			}));
			break;
		case "":
			return TagConfigData;
			break;
		default:
			// will filter and then just return the first element (there should only be one anyway)
			return TagConfigData.filter((tag) => tag.module_tag === module)[0];
	}
}
