import React from "react";
import colour from "../assets/images/MAXCLOUDCOLOUR.png";
import white from "../assets/images/MAXCLOUDWHITE.png";

const LogoIcon = (props) => {
	return (
		<img
			alt="Logo"
			src={props.src ? props.src : props.variant === "white" ? white : colour}
			{...props}
			style={{
				width: props.width ? props.width : "auto",
				height: props.height ? props.height : "auto",
				objectFit: "contain",
			}}
			onClick={props.onClick && props.onClick}
		/>
	);
};

export default LogoIcon;
