const mainlayouts = [
	{
		value: "1x1",
		row: 1,
		col: 1,
	},
	{
		value: "1x2",
		row: 2,
		col: 1,
	},
	{
		value: "1x3",
		row: 3,
		col: 1,
	},
	{
		value: "1x4",
		row: 4,
		col: 1,
	},
	{
		value: "1x5",
		row: 5,
		col: 1,
	},
	{
		value: "1x6",
		row: 6,
		col: 1,
	},
	{
		value: "1x7",
		row: 7,
		col: 1,
	},
	{
		value: "2x1",
		row: 1,
		col: 2,
	},
	{
		value: "2x2",
		row: 2,
		col: 2,
	},
	{
		value: "2x3",
		row: 3,
		col: 2,
	},
	// {
	// 	value: "2x4",
	// 	row: 4,
	// 	col: 2,
	// },
	{
		value: "2x5",
		row: 5,
		col: 2,
	},
	{
		value: "3x2",
		row: 2,
		col: 3,
	},
	{
		value: "3x3",
		row: 3,
		col: 3,
	},
	{
		value: "3x4",
		row: 4,
		col: 3,
	},
	{
		value: "4x4",
		row: 4,
		col: 4,
	},
	{
		value: "4x5",
		row: 5,
		col: 4,
	},
	{
		value: "5x4",
		row: 4,
		col: 5,
	},
	{
		value: "5x5",
		row: 5,
		col: 5,
	},
	{
		value: "5x6",
		row: 6,
		col: 5,
	},
	{
		value: "5x8",
		row: 8,
		col: 5,
	},
	{
		value: "5x10",
		row: 10,
		col: 5,
	},
	{
		value: "5x12",
		row: 12,
		col: 5,
	},
	{
		value: "6x6",
		row: 6,
		col: 6,
	},
	{
		value: "6x9",
		row: 9,
		col: 6,
	},
	{
		value: "7x7",
		row: 7,
		col: 7,
	},
	{
		value: "10x12",
		row: 12,
		col: 10,
	},
	// {
	// 	value: "2x6",
	// 	row: 6,
	// 	col: 2,
	// },

	// {
	// 	value: "3x1",
	// 	row: 1,
	// 	col: 3,
	// },
	// {
	// 	value: "4x1",
	// 	row: 1,
	// 	col: 4,
	// },
	// {
	// 	value: "5x1",
	// 	row: 1,
	// 	col: 5,
	// },
	// {
	// 	value: "6x1",
	// 	row: 1,
	// 	col: 6,
	// },
	// {
	// 	value: "7x1",
	// 	row: 1,
	// 	col: 7,
	// },
	// {
	// 	value: "2x2",
	// 	row: 2,
	// 	col: 2,
	// },
	// {
	// 	value: "3x2",
	// 	row: 2,
	// 	col: 3,
	// },
	// {
	// 	value: "4x2",
	// 	row: 2,
	// 	col: 4,
	// },
	// {
	// 	value: "5x2",
	// 	row: 2,
	// 	col: 5,
	// },

	// {
	// 	value: "2x3",
	// 	row: 3,
	// 	col: 2,
	// },
	// {
	// 	value: "3x3",
	// 	row: 3,
	// 	col: 3,
	// },
	// {
	// 	value: "3x4",
	// 	row: 4,
	// 	col: 3,
	// },
	// {
	// 	value: "4x3",
	// 	row: 3,
	// 	col: 4,
	// },
	// {
	// 	value: "4x4",
	// 	row: 4,
	// 	col: 4,
	// },
	// {
	// 	value: "4x5",
	// 	row: 5,
	// 	col: 4,
	// },
	// {
	// 	value: "4x6",
	// 	row: 4,
	// 	col: 6,
	// },
	// {
	// 	value: "5x4",
	// 	row: 4,
	// 	col: 5,
	// },
	// {
	// 	value: "2x5",
	// 	row: 5,
	// 	col: 2,
	// },
	// {
	// 	value: "3x5",
	// 	row: 5,
	// 	col: 3,
	// },
	// {
	// 	value: "4x5",
	// 	row: 5,
	// 	col: 4,
	// },
	// {
	// 	value: "5x5",
	// 	row: 5,
	// 	col: 5,
	// },
	// {
	// 	value: "5x6",
	// 	row: 6,
	// 	col: 5,
	// },

	// {
	// 	value: "7x1",
	// 	row: 7,
	// 	col: 1,
	// },
	// {
	// 	value: "5x8",
	// 	row: 8,
	// 	col: 5,
	// },

	// {
	// 	value: "5x10",
	// 	row: 10,
	// 	col: 5,
	// },
	// {
	// 	value: "5x12",
	// 	row: 12,
	// 	col: 5,
	// },
	// {
	// 	value: "6x6",
	// 	row: 6,
	// 	col: 6,
	// },
	// {
	// 	value: "6x9",
	// 	row: 9,
	// 	col: 6,
	// },
	// {
	// 	value: "7x7",
	// 	row: 7,
	// 	col: 7,
	// },
	// {
	// 	value: "10x12",
	// 	row: 12,
	// 	col: 10,
	// },
];

export default mainlayouts;
