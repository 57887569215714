import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	Chip,
	FormControlLabel,
	InputAdornment,
	InputLabel,
	ListItemText,
	Modal,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth0 } from "@auth0/auth0-react";
import GridContext from "../context/GridContext";
import layouts from "../data/mainlayouts";
import OptionSelect from "./OptionSelect";
import { Controller, useForm } from "react-hook-form";
import {
	NumberFormatCustom,
	generatePLUNumber,
	getTextColour,
} from "../../datagrids/utils";
import { DataGridContext } from "../../datagrids/data grid files/DataGridContext";
import { Colours } from "../../../assets/global/Theme-variable";
import { LoadingButton } from "@mui/lab";
import { modifiers } from "../data/modifiers";
import axios from "axios";
import SelectGroup from "../../../components/SelectGroup";
import RadioButtonsGroup from "../../../components/RadioGroup";
import { API_ENDPOINTS } from "../../../API/apiConfig";

export default function NewItemModal({
	setNewItemModalOpen,
	newItemModalOpen,
}) {
	const [isMod, setIsMod] = React.useState("Item");
	const { data, setData } = React.useContext(DataGridContext);

	const { getAccessTokenSilently } = useAuth0();
	const { setVariant, variant, openItem } = React.useContext(GridContext);

	const [isSaving, setIsSaving] = React.useState(false);

	let buttonIndex = variant?.list_details?.buttons?.findIndex(function (
		object
	) {
		return object?.id === openItem?.id;
	});

	const [plu, setPLU] = React.useState({
		plu_name: "",
		plu_details: {
			salesGroup: "",
			tags: [],
			price: "",
			priceModifiers: [],
		},
		plu_isDeleted: 0,
		plu_number: "",
		plu_ref: "",
		plu_uid: "",
		ref: "",
		uid: "",
		modifier: "",
	});

	const {
		register,
		control,
		setError,
		watch,
		getValues,
		resetField,
		reset,
		unregister,
		handleSubmit,
		isSubmitSuccessful,
		setValue,
		formState: { errors },
	} = useForm({
		defaultValues: React.useMemo(() => {
			return plu;
		}, [plu]),
		shouldUnregister: true,
		criteriaMode: "all",
	});

	useEffect(() => {
		reset();
	}, [isSubmitSuccessful, reset]);

	// const watchMod = watch(isMod);

	// const watchPLU = watch(plu);
	const pluName = watch("new_name", false);
	const price = watch(`plu_details.price`, false);
	const salesGroup = watch(`plu_details.salesGroup`, false);
	const tags = watch(`plu_details.tags`, false);

	useEffect(() => {
		reset(plu);
	}, [plu]);

	const resetForm = () => {
		setPLU({
			plu_name: "",
			new_name: "",

			plu_details: {
				salesGroup: "",
				tags: [],
				price: "",
				priceModifiers: [],
			},
			modifier: "",
		});
		reset();
	};
	const handlePLU = (event, newValue) => {
		event.preventDefault();
		const newPLU = {
			plu_name: newValue?.plu_name,
			// new_name: "",
			plu_number: newValue?.plu_number,
			plu_details: newValue?.plu_details,
		};
		setPLU(newPLU);
	};

	const modifiersFiltered = modifiers(data.modifier)?.filter(
		(mod) =>
			// band.priceband_details.isActive === true &&
			!data?.items?.find(
				(i) =>
					i?.plu_number?.slice(0, -1) === plu?.plu_number?.slice(0, -1) &&
					mod?.number === parseInt(i?.plu_number?.slice(-1))
			)
	);

	const handleSwitch = (event, newValue) => {
		// Handle the switch (toggling isMod) and reset other data if the switch is turned off.
		resetForm();
		setIsMod(newValue);

		//console.log("dataGridValues - on mod change", dataGridValues);
		if (!newValue) {
			resetForm();
		}
	};

	const handleMod = (event, newValue) => {
		console.log(event.target.value, newValue);
		setValue("new_name", `${event?.target?.value?.prefix} ${plu?.plu_name}`);
		setValue(
			"plu_number",
			JSON.stringify(
				parseInt(plu?.plu_number) + parseInt(event?.target.value?.number)
			)
		);
		// const mod = modifiersFiltered.find(
		// 	(m) => m.number === event.target.value?.number
		// );
		setValue("modifier", event.target.value);
	};

	const onSubmit = async () => {
		setIsSaving(true);

		// setIsLoading(true);
		const newItem = getValues();

		// const newRow = plu;
		// if (isMod) {
		// 	newRow.plu_number = JSON.stringify(
		// 		parseInt(plu?.plu_number) + parseInt(pluModifier?.number)
		// 	);
		// 	newRow.plu_name = modifierName;
		// }
		// delete newRow.plu_ref;
		const modCopy = [...mods];

		newItem.plu_name = newItem.new_name;
		delete newItem.new_name;
		delete newItem.modifier;
		delete newItem.plu_ref;
		delete newItem.plu_uid;
		newItem.plu_details.priceModifiers = [];
		const token = await getAccessTokenSilently();
		// const newData = [...data.items];

		const newRowData = {
			updates: [newItem],
		};

		const uniqueID = JSON.parse(data.items.at(-1).uid) + 1;

		if (isMod === "ItemWithMod") {
			mods?.map((mod, index) => {
				mod.uid = JSON.stringify(JSON.parse(uniqueID) + (index + 1));
				mod.plu_details.priceModifiers = [];
				// newData.push(mod);
				newRowData.updates.push(mod);
			});
		}
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};
		axios
			.post(API_ENDPOINTS.plu, newRowData, config) // USING ENDPOINT FROM CONFIG
			.then(function (response) {
				let newItem = response.data.plu[0];
				response.data.plu.forEach(
					(plu) => (
						(plu.uid = plu.plu_uid),
						(plu.plu_number = plu.plu_number.toString())
					)
				);

				setData({
					...data,
					items: [...data.items, ...response?.data?.plu],
					// itemsAll: [...data.itemsAll, ...response?.data?.plu],
				});
				setVariant((draft) => {
					draft.list_details.buttons[buttonIndex].functionValue = parseInt(
						newItem.plu_number
					);

					draft.list_details.buttons[buttonIndex].functionType = 21;
					draft.list_details.buttons[buttonIndex].text = newItem.plu_name;
				});
				setTimeout(() => {
					setIsSaving(false);
					setNewItemModalOpen(false);
					setPLU({
						plu_name: "",
						new_name: "",

						plu_details: {
							salesGroup: "",
							tags: [],
							price: "",
							priceModifiers: [],
						},
						modifier: "",
					});
					setIsMod("Item");
					setSelectedMods({});
					reset();
				}, 2000);
			})
			.catch(function (error) {
				console.log(error);
				setIsSaving(false);
				setError("root.serverError", {
					type: error?.response?.status,
				});
			});
	};

	const [selectedMods, setSelectedMods] = React.useState({});

	const handleChangeSelectedMods = (event) => {
		setSelectedMods({
			...selectedMods,
			[event.target.name]: event.target.checked,
		});
	};

	function mapFn(value) {
		const modObj = modifiers(data.modifier).find(
			(mod) => mod.number === parseInt(value)
		);
		const newPLU = {};
		newPLU.plu_name = `${modObj?.prefix} ${pluName}`;
		const lastPLU = data["itemsAll"].reduce((a, b) =>
			parseInt(a.plu_number) > parseInt(b.plu_number) ? a : b
		).plu_number;
		newPLU.plu_number = generatePLUNumber(lastPLU, modObj?.number);
		newPLU.plu_details = {
			price: parseFloat(price),
			salesGroup: salesGroup,
			tags: tags,
		};

		return newPLU;
	}

	function objectMap(obj, fn) {
		return Object.entries(obj).map(([key, value]) => value === true && fn(key));
	}

	const mods = objectMap(selectedMods, mapFn).filter(
		(mod) => typeof mod === "object"
	);

	const handleModPrice = (mod, event) => {
		mod.plu_details.price = parseFloat(event.target.value);
	};

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "10px",
		width: 450,
		maxHeight: "90vh",
		// height: "100%",
		// minHeight:
		// 	// 100 +
		// 	typeof modalColumns !== "undefined" ? modalColumns.length * 70 : 400,
		bgcolor: "white",
		boxShadow: 24,
		// p: 4,
		overflow: "hidden",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		// padding: 0,
		gap: 2,
		flexDirection: "column",
	};

	const style2 = {
		// position: "absolute",
		// top: "50%",
		// left: "50%",
		// transform: "translate(-50%, -50%)",
		// borderRadius: "20px",
		// width: 450,
		// maxHeight: "90vh",
		height: "100%",
		width: "100%",

		p: 4,
		overflow: "hidden",
		display: "flex",
		justifyContent: "flex-start",
		gap: 2,
		flexDirection: "column",
		/* hide scrollbar but allow scrolling */
		// "*::-webkit-scrollbar": {
		// 	width: "6px",
		// },
		// "*::-webkit-scrollbar-track": {
		// 	backgroundColor: "#f5f5f5",
		// },
		// "-webkit-scrollbar-thumb": {
		// 	borderRadius: "10px",
		// 	boxShadow: "inset 0 0 10px rgba(0,0,0,.3)",
		// 	// backgroundColor: "#f5f5f5",
		// },

		// -ms-overflow-style: 'none', /* for Internet Explorer, Edge */
		// scrollbarWidth: "none" /* for Firefox */,
		overflowY: "scroll",
		// "&-ms-overflow-style:": {
		// 	display: "none", // Hide the scrollbar for IE
		// },
		// "&-webkit-scrollbar": {
		// 	width: "6px",
		// },
		// "&-webkit-scrollbar-track": {
		// 	backgroundColor: "#f5f5f5",
		// },
		// "&-webkit-scrollbar-thumb": {
		// 	borderRadius: "10px",
		// 	boxShadow: "inset 0 0 10px rgba(0,0,0,.3)",
		// 	// backgroundColor: "#f5f5f5",
		// },

		"&::-webkit-scrollbar": {
			width: "6px",
		},
		"&::-webkit-scrollbar-track": {
			backgroundColor: "#f5f5f5",
		},
		"&::-webkit-scrollbar-thumb": {
			borderRadius: "10px",
			boxShadow: "inset 0 0 10px rgba(0,0,0,.3)",
			// backgroundColor: "#f5f5f5",
		},
	};

	return (
		<Modal open={newItemModalOpen}>
			<Box component="form" sx={style}>
				<Box sx={style2}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Typography variant="h3">New Item</Typography>
						<Button
							variant="contained"
							size="medium"
							sx={{ alignSelf: "flex-end" }}
							onClick={() => setNewItemModalOpen(!newItemModalOpen)}
						>
							X
						</Button>
					</Box>
					<Box
						sx={{
							mt: 2,
							mb: 2,
							display: "flex",
							gap: 2,
							flexDirection: "column",
							height: "100%",
							width: "100%",
						}}
					>
						{/* <CustomSwitch
						label="Item Modifier?"
						value={isMod}
						onChange={handleSwitch}
					/> */}
						<RadioButtonsGroup value={isMod} onChange={handleSwitch} />

						{isMod === "Modifier" && (
							<>
								<Autocomplete
									disablePortal
									id="combo-box-demo"
									options={data?.items?.filter(
										(option) =>
											option?.plu_number?.at(-1) === "0" &&
											option?.plu_isDeleted === 0
									)}
									size="small"
									sx={{ width: "100%" }}
									// displayEmpty
									freeSolo
									value={plu}
									onChange={handlePLU}
									renderInput={(params) => (
										<TextField
											{...params}
											label={"Item"}
											variant={"outlined"}
											InputLabelProps={{
												shrink: true,
											}}
											placeholder="Select Item"
										/>
									)}
									renderOption={(props, item) => (
										<li {...props} key={item.uid}>
											{item?.plu_name}
										</li>
									)}
									getOptionLabel={(option) => option?.plu_name || ""}
								/>
								<Box sx={{ width: "100%" }}>
									<Controller
										control={control}
										render={({
											field: {
												name,
												defaultValue,
												onChange,
												onBlur,
												value,
												ref,
											},
										}) => (
											<OptionSelect
												options={modifiersFiltered}
												value={
													value
														? modifiersFiltered.find(
																(m) => m.number === value.number
														  )
														: null
												}
												// defaultValue={defaultValue}
												type="modifierNewItemForm"
												label="Modifier"
												width="100%"
												name={name}
												onChange={handleMod}
												placeholder="Select Modifier"
												// ref={ref}

												// {...field}
											/>
										)}
										name="modifier"
										// defaultValue={modifiers[0]}
										rules={{ required: true }}
									/>
									{errors["modifier"] &&
										errors["modifier"].type === "required" && (
											<Typography sx={{ fontSize: 12, color: Colours.gsblue }}>
												Modifier is required.
											</Typography>
										)}
								</Box>
							</>
						)}
						<Box sx={{ width: "100%" }}>
							<Controller
								control={control}
								render={({
									field: { name, onChange, defaultValue, onBlur, value, ref },
								}) => (
									<TextField
										id="modifier-name"
										label="Name"
										value={value}
										name={name}
										onChange={onChange}
										size="small"
										sx={{ width: "100%" }}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								)}
								name="new_name"
								defaultValue=""
								rules={{
									required: true,
									pattern: /^[-a-zA-Z0-9@\s\*&+%£=!:;><)?(^\[\]{\}\\\/\.\_]+$/,
								}}
							/>
							{errors["new_name"] && errors["new_name"].type === "required" && (
								<Typography sx={{ fontSize: 12, color: Colours.gsblue }}>
									Name is required.
								</Typography>
							)}
							{errors["new_name"] && errors["new_name"].type === "pattern" && (
								<Typography sx={{ fontSize: 12, color: Colours.gsblue }}>
									This field cannot contain emoji's and ' or , characters
								</Typography>
							)}
						</Box>
						<Box sx={{ width: "100%" }}>
							<Controller
								name={`plu_details.price`}
								control={control}
								render={({ field: { name, onChange, onBlur, value, ref } }) => (
									<TextField
										label={"Base Price"}
										size="small"
										type="text"
										sx={{ width: "100%" }}
										InputLabelProps={{
											shrink: true,
										}}
										value={value}
										name={name}
										onChange={(event) => {
											event.target.value &&
												onChange(parseFloat(event.target.value));
										}}
										InputProps={{
											inputComponent: NumberFormatCustom,

											inputMode: "numeric",
											pattern: "[0-9]*",
											startAdornment: (
												<InputAdornment position="start">£</InputAdornment>
											),
										}}
									/>
								)}
							/>
							{errors["new_name"] && errors["new_name"].type === "required" && (
								<Typography sx={{ fontSize: 12, color: Colours.gsblue }}>
									Price is required.
								</Typography>
							)}
						</Box>
						<Box sx={{ width: "100%" }}>
							<Controller
								name={`plu_details.salesGroup`}
								control={control}
								rules={{ required: true }}
								render={({ field: { name, onChange, onBlur, value, ref } }) => (
									<Autocomplete
										id="sales-group"
										options={data?.categories.filter(
											(i) => i?.salesGroup_isDeleted === 0
										)}
										getOptionLabel={(option) =>
											option.salesGroup_number + " - " + option.salesGroup_name
										}
										// displayEmpty
										size="small"
										value={
											value
												? data.categories.find(
														(c) =>
															parseInt(c.salesGroup_number) === value ||
															c.salesGroup_number === value
												  )
												: null
											// value
										}
										name={name}
										// onChange={onChange}
										defaultValue={value ? value : null}
										onBlur={onBlur}
										ref={ref}
										onChange={(event, selectedOptions) => {
											selectedOptions &&
												onChange(parseInt(selectedOptions?.salesGroup_number));
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label={"Category"}
												variant={"outlined"}
												placeholder="Select"
												InputLabelProps={{
													shrink: true,
												}}
											/>
										)}
									/>
								)}
							/>
							{errors[`plu_details.salesGroup`] &&
								errors[`plu_details.salesGroup`].type === "required" && (
									<Typography sx={{ fontSize: 12, color: Colours.gsblue }}>
										Category is required.
									</Typography>
								)}
						</Box>
						<Controller
							name={`plu_details.tags`}
							control={control}
							render={({ field: { name, onChange, onBlur, value, ref } }) => (
								<Autocomplete
									multiple
									size="small"
									limitTags={4}
									options={data?.tags
										.filter((i) => i?.tag_isDeleted === 0)
										?.map((tag) => tag.tag_ref)}
									// shrink
									// displayEmpty
									// InputLabelProps={{ shrink: true }}
									filterSelectedOptions
									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											InputLabelProps={{
												shrink: true,
											}}
											label="Tags"
											placeholder="Search"
											inputProps={{
												...params.inputProps,
												autoComplete: "new-", // disable autocomplete and autofill
											}}
										/>
									)}
									renderOption={(props, item) => {
										const option = data?.tags?.find((t) => t?.tag_ref === item);
										return (
											<li {...props} key={option.tag_red}>
												<ListItemText>{option.tag_name}</ListItemText>
											</li>
										);
									}}
									renderTags={(value, getTagProps) =>
										value?.map((option, index) => {
											const tag = data?.tags?.find(
												(t) => t?.tag_ref === option
											);
											return (
												<Chip
													label={
														<Typography
															style={{
																whiteSpace: "normal",
																color:
																	getTextColour(
																		tag?.tag_details?.styling?.colour
																	)?.colour || "black",
																fontSize: 12,
															}}
														>
															{tag?.tag_name}
														</Typography>
													}
													{...getTagProps({ index })}
													deleteIcon={
														<CloseIcon
															color={
																getTextColour(tag?.tag_details?.styling?.colour)
																	?.colour || "white"
															}
														/>
													}
													sx={{
														// height: "100%",
														backgroundColor: tag?.tag_details?.styling?.colour,
														padding: "2px 4px 2px 8px",
														borderRadius: 2,

														"& svg": {
															fontSize: "12px",
															cursor: "pointer",
															padding: "4px",
															color: `${
																getTextColour(tag?.tag_details?.styling?.colour)
																	?.colour
															} !important`,
														},
													}}
												/>
											);
										})
									}
									onChange={(event, selectedOptions) => {
										onChange(selectedOptions);
									}}
									onBlur={onBlur}
									value={value}
								/>
							)}
						/>
						{isMod === "ItemWithMod" && (
							<>
								<SelectGroup
									label="Modifiers"
									values={modifiers(data.modifier)}
									onChange={handleChangeSelectedMods}
									selectedModifiers={selectedMods}
								/>
								<Box sx={{ width: "100%" }}>
									{mods.map((mod, index) => (
										<Box
											sx={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "space-between",
												alignItems: "center",
												height: 50,
											}}
											key={mod.number}
										>
											<Typography>{mod.plu_name}</Typography>
											<TextField
												label={"Price"}
												size="small"
												type="text"
												sx={{ width: 100 }}
												// InputLabelProps={{
												// 	shrink: true,
												// }}
												value={mod.plu_details.price}
												onChange={
													(e) => handleModPrice(mod, e)
													// (event) =>
													// (mod.plu_details.price = event.target.value)
												}
												InputProps={{
													inputComponent: NumberFormatCustom,

													inputMode: "numeric",
													pattern: "[0-9]*",
													startAdornment: (
														<InputAdornment position="start">£</InputAdornment>
													),
												}}
											/>
										</Box>
									))}
								</Box>
							</>
						)}
					</Box>

					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							justifySelf: "flex-end",
						}}
					>
						<Button
							variant="outlined"
							size="medium"
							sx={{ alignSelf: "flex-end" }}
							onClick={resetForm}
						>
							Reset form
						</Button>
						<LoadingButton
							size="small"
							onClick={handleSubmit(onSubmit)}
							loading={isSaving}
							variant={!isSaving ? "contained" : "outlined"}
							loadingPosition="start"
							startIcon={<SaveIcon />}
							disabled={isSaving}
						>
							<span>Save</span>
						</LoadingButton>
					</Box>
					{errors?.root?.serverError?.type === 400 && (
						<Box sx={{ alignSelf: "flex-end", mt: 2 }}>
							<Typography sx={{ fontSize: 12, color: Colours.red }}>
								Unable to save, please try again
							</Typography>
						</Box>
					)}
				</Box>
			</Box>
		</Modal>
	);
}

const style = {
	position: "absolute",

	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	borderRadius: "20px",
	width: 410,
	maxHeight: "90vh",
	overflow: "scroll",
	backgroundColor: "white",
	boxShadow: 24,
	p: 4,
	display: "flex",
	justifyContent: "flex-start",
	flexDirection: "column",
	"&:focus": {
		outline: "none",
	},
};
