import React, { useEffect, useState } from "react";
import cx from "classnames";
import { Colours } from "../assets/global/Theme-variable";
import { BiSolidColorFill } from "react-icons/bi";
import { SvgIcon } from "@mui/material";

export const CursorContext = React.createContext("cursorContext");

const SUPPORTED_CURSORS = [false, "pointer", "right", "left"];

const CursorProvider = ({ children }) => {
	const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
	const [cursor, setCursor] = useState(false);

	const onMouseMove = (event) => {
		const { pageX: x, pageY: y } = event;
		setMousePosition({ x, y });
	};

	useEffect(() => {
		document.addEventListener("mousemove", onMouseMove);

		return () => {
			document.removeEventListener("mousemove", onMouseMove);
		};
	});

	const { x, y } = mousePosition;

	const onCursor = (cursorType) => {
		cursorType =
			(SUPPORTED_CURSORS.includes(cursorType) && cursorType) || false;
		setCursor(cursorType);
	};

	return (
		<CursorContext.Provider value={{ onCursor }}>
			{!!cursor && (
				<svg
					width={50}
					height={50}
					viewBox="0 0 50 50"
					style={{
						zIndex: 9999,
						position: "absolute",
						pointerEvents: "none",
						left: `${x - 25}px`,
						top: `${y - 20}px`,
						strokeWidth: 1,
						transition: "transform .2s ease-in-out",
					}}
				>
					<filter id="shadow" x="0" y="0" width="150%" height="150%">
						<feDropShadow
							dx="1"
							dy="1"
							stdDeviation="1"
							floodColor="#969799"
							floodOpacity="1"
						/>
					</filter>
					<SvgIcon>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 30 30"
							fill="white"
						>
							<title>format-color-fill</title>
							<path
								filter="url(#shadow)"
								d="M19,11.5C19,11.5 17,13.67 17,15A2,2 0 0,0 19,17A2,2 0 0,0 21,15C21,13.67 19,11.5 19,11.5M5.21,10L10,5.21L14.79,10M16.56,8.94L7.62,0L6.21,1.41L8.59,3.79L3.44,8.94C2.85,9.5 2.85,10.47 3.44,11.06L8.94,16.56C9.23,16.85 9.62,17 10,17C10.38,17 10.77,16.85 11.06,16.56L16.56,11.06C17.15,10.47 17.15,9.5 16.56,8.94Z"
							/>
						</svg>
					</SvgIcon>
				</svg>
			)}
			{children}
		</CursorContext.Provider>
	);
};

export default CursorProvider;
