import { Box, InputAdornment, InputBase, styled } from "@mui/material";
import { NumberFormatCustom } from "../../utils";
import { useGridApiContext } from "@mui/x-data-grid";

export default function PriceInputField(props) {
	const apiRef = useGridApiContext();
	const { id, value, field } = props;

	return (
		<Box
			sx={{
				width: "100%",
				display: "flex",
				flexDirection: "column",
				// alignItems: "stretch",
			}}
		>
			<Input
				onChange={(e) => {
					const updatedValue = e.target.value;
					apiRef.current.setEditCellValue({
						id,
						field,
						value: updatedValue,
					});
				}}
				value={value}
				size={"small"}
				startAdornment={
					<InputAdornment
						sx={{ fontSize: "15px", pl: 1, pr: 0, m: 0 }}
						position="start"
					>
						£
					</InputAdornment>
				}
				origin={origin}
				inputComponent={NumberFormatCustom}
				inputProps={{
					inputMode: "numeric",
					pattern: "[0-9]*",
				}}
			/>
		</Box>
	);
}

const Input = styled(InputBase)(({ theme, origin, multiline }) => ({
	border: "none",
	height: "100%",

	"label + &": {
		marginTop: 0,
		marginBottom: 0,
	},

	"& .MuiInputBase-input": {
		border: "none",
		fontSize: 16,
		maxWidth: "100%",
		marginRight: 20,
		padding: "2px 2px",
		"&:disabled": {
			border: "none !important",
		},
		"& .MuiInput-underline:before": { borderBottom: "none !important" },
		"& .MuiInput-underline": { borderBottom: "none !important" },
	},
	"& .MuiInput-underline:before": { borderBottom: "none !important" },
	"& .MuiInput-underline": { borderBottom: "none !important" },
}));
