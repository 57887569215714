import React, { createContext, useState } from "react";

const CursorContext = createContext();

const CursorContextProvider = (props) => {
	const [cursor, setCursor] = useState({ active: false });

	return (
		<CursorContext.Provider value={{ cursor, setCursor }}>
			{props.children}
		</CursorContext.Provider>
	);
};

export { CursorContextProvider, CursorContext };
