import axios from "axios";
import { Box } from "@mui/system";
import {
	Button,
	Card,
	CardContent,
	FormControl,
	FormControlLabel,
	Skeleton,
	Snackbar,
	Switch,
	Typography,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { DataGridContext } from "./datagrids/data grid files/DataGridContext";
import React, { useEffect } from "react";
import MuiAlert from "@mui/material/Alert";
import RouterBreadcrumbs from "../components/Breadcrumbs";
import { APP_URL } from "../API/apiConfig";
import { Colours } from "../assets/global/Theme-variable";

export default function Admin() {
	const { getAccessTokenSilently } = useAuth0();
	const {
		setSnackbarOpen,
		isRetail,
		setIsRetail,
		setSnackbarMessage,
		snackbarOpen,
		snackbarMessage,
		data,
	} = React.useContext(DataGridContext);
	// get request

	const runClearCache = async () => {
		const token = await getAccessTokenSilently();

		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};
		axios
			.get(`${APP_URL}/api/pos/clearCache`, config)
			.then((response) => {
				if (response.status === 200) {
					setSnackbarMessage({
						message: ["Request was successful"],
						duration: 2000,
						severity: "success",
					});
				} else {
					setSnackbarMessage({
						message: ["ERROR Request unsuccessful."],
						duration: 4000,
						severity: "error",
					});
				}
				// Open the Snackbar
				setSnackbarOpen(true);
			})
			.catch((error) => {
				console.error("Error:", error);
				setSnackbarMessage({
					message: ["ERROR Request unsuccessful.", error.message],
					duration: 8000,
					severity: "error",
				});
				// Open the Snackbar
				setSnackbarOpen(true);
			});
	};

	//post request
	const runOrderbake = async () => {
		let postStatus = 0;
		const token = await getAccessTokenSilently();
		const config = {
			headers: { Authorization: `Bearer ${token}` },
			//updates: [updatedRow],
		};

		axios
			.post(`${APP_URL}/api/pos/orderBake`, {}, config)
			.then(function (response) {
				if (response.status === 200) {
					setSnackbarMessage({
						message: ["Request was successful"],
						duration: 4000,
						severity: "success",
					});
				} else {
					setSnackbarMessage({
						message: ["ERROR Request unsuccessful."],
						duration: 8000,
						severity: "error",
					});
				}
				// Open the Snackbar
				setSnackbarOpen(true);
			})
			.catch((error) => {
				console.error("Error:", error);
				setSnackbarMessage({
					message: ["ERROR Request unsuccessful."],
					duration: 8000,
					severity: "error",
				});
				// Open the Snackbar
				setSnackbarOpen(true);
			});
	};
	const [buttonCount, setButtonCount] = React.useState(0);
	useEffect(() => {
		let count = 0;
		const screens = data?.screens?.filter((i) => i?.list_isDeleted === 0);
		screens?.forEach(
			(screen) => (count += screen?.list_details?.buttons?.length)
		);
		setButtonCount(count);
	}, [data]);

	return (
		<Box
			sx={{
				maxWidth: "100%",
				maxHeight: "100%",

				display: "flex",
				flexDirection: "column",
				flex: 1,
				ml: 4,
				mr: 4,
			}}
		>
			<Box
				sx={{
					maxWidth: "100%",

					marginTop: 3,
					mb: 2,
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
				}}
			>
				<RouterBreadcrumbs />
			</Box>
			<Box
				sx={{
					width: "max-content",
					// height: "auto",
					padding: 10,

					boxShadow: 3,
					// height: 40,
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					alignItems: "center",
					gap: 4,
				}}
			>
				<Typography>Settings</Typography>
				<Button size="large" variant="outlined" onClick={runClearCache}>
					Clear Cache
				</Button>

				<Button size="large" variant="gradient" onClick={runOrderbake}>
					Send to Max-Suite
				</Button>
				<FormControl component="fieldset">
					<FormControlLabel
						value="top"
						control={
							<Switch
								checked={isRetail}
								onChange={(event) => {
									setIsRetail(event.target.checked);
								}}
								inputProps={{ "aria-label": "controlled" }}
							/>
						}
						label="Use Retail Settings"
						labelPlacement="top"
					/>
				</FormControl>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Typography sx={{ fontSize: 14, color: Colours.gsblue }}>
						Total Buttons:
					</Typography>
					{data.screens !== null ? (
						<Typography>{buttonCount}</Typography>
					) : (
						<Skeleton>
							<Typography>12345</Typography>
						</Skeleton>
					)}
				</Box>
			</Box>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={snackbarMessage.duration} // Adjust the duration as needed
				onClose={() => setSnackbarOpen(false)}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
			>
				<MuiAlert
					onClose={() => setSnackbarOpen(false)}
					severity={snackbarMessage.severity}
					elevation={6}
					variant="filled"
				>
					{snackbarMessage.message.map((message, index) => (
						<div key={index}>{message}</div>
					))}
				</MuiAlert>
			</Snackbar>
		</Box>
	);
}
