import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	InputLabel,
	Modal,
	Switch,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
// import layouts from "../../data/mainlayouts";

import { Controller, useForm, useFormContext } from "react-hook-form";
import { Slider, Stack } from "@mui/material";
import { DataGridContext } from "../../data grid files/DataGridContext";
import { autoFill, getTextColour, groupBy } from "../../utils";
import OptionSelect from "../../../grid/components/OptionSelect";
import mainlayouts from "../../../grid/data/mainlayouts";
import { Colours } from "../../../../assets/global/Theme-variable";

export default function NewListForm({ methods }) {
	const { data, setData } = React.useContext(DataGridContext);

	const categories = groupBy(
		data,
		data?.items?.filter((i) => i?.plu_isDeleted === 0),
		function (item) {
			return [item?.plu_details?.salesGroup];
		}
	);

	const layoutOpt = methods.watch("area");
	const { setValue, control, formState } = useFormContext();

	// const [minMax, setMinMax] = React.useState([0, 1]);

	const handleRangeChange = (event, newValue) => {
		// setMinMax(newValue);
		setValue("list_details[selMin]", newValue[0]);
		setValue("list_details[selMax]", newValue[1]); // ✅
	};

	const watchAutofill = methods.watch("autofilllist");
	const cols = methods.watch("list_details[cols]");
	const rows = methods.watch("list_details[rows]");
	const min = methods.watch("list_details[selMin]");
	const max = methods.watch("list_details[selMax]");
	const minMax = [min, max];
	const layout = { cols: cols, rows: rows };

	useEffect(() => {
		console.log("reset ue");
		// methods.reset();
		// setMinMax([0,1]);
	}, [methods.reset]);

	useEffect(() => {
		if (watchAutofill) {
			methods.register("group");
		} else {
			methods.unregister("group");
		}
	}, [methods.register, methods.unregister, watchAutofill]);

	useEffect(() => {
		if (!watchAutofill) {
			handleAutofill();
		}
	}, [cols, rows]);
	const listNames = data?.screens?.map((list) => list?.list_isDeleted === 0 &&  list?.list_name);

	const handleAutofill = (event) => {
		setValue("group", event?.target?.value);
		const group = methods.getValues("group");
		const items = group
			? group?.items?.filter(
					(item) => item.plu_number.charAt(item.plu_number.length - 1) === "0"
			  )
			: [];
		const cols = methods.getValues("list_details[cols]");
		const rows = methods.getValues("list_details[rows]");

		const cellCount = cols * rows;

		const pages =
			Math.round(items?.length / cellCount) > 0
				? Math.round(items?.length / cellCount)
				: 1;

		let buttonArr = [];
		for (let index = 1; index <= pages; index++) {
			buttonArr.push({
				page: index,
				min: cellCount * (index - 1),
				max: cellCount * index - 1,
			});
		}
		const maxCells = cellCount * pages - 1;

		setValue("list_details[pages]", pages);
		let buttons = [];
		for (let i = 0; i <= maxCells; i++) {
			const colour1 = data.colours[0].palette_details.colours.find(
				(col) => col.colour === "#000000"
			);

			const colour2 = getTextColour(colour1.colour);

			buttons.push({
				id: i,
				index: i,
				style: {
					background: colour1.index,
					foreground: colour2.index,
					alignmentV: 1,
					alignmentH: 1,
					font: "10B",
				},
				text: items[i]?.plu_name !== undefined ? items[i]?.plu_name : "",
				mx: 1,
				my: 1,
				pageNo: 1,
				size: 1,
				functionType: items[i]?.plu_number !== undefined ? 21 : 0,
				functionValue:
					items[i]?.plu_number !== undefined
						? parseInt(items[i]?.plu_number)
						: 0,
				hideText: 0,
			});
		}

		buttons.map((x, index) => {
			const page = buttonArr?.find(
				(button) => index >= button.min && index <= button.max
			);
			x.pageNo = page?.page;
		});
		setValue("list_details[buttons", buttons);
	};
	return (
		<Box
			sx={{
				mt: 2,
				mb: 2,
				display: "flex",
				gap: 2,
				flexDirection: "column",
				height: "100%",
				width: "100%",
			}}
		>
			{/* <Box>å */}
			<Box>
				<TextField
					id="outlined-controlled"
					label="List Name"
					size={"small"}
					type="align"
					name="name"
					InputLabelProps={{
						shrink: true,
					}}
					sx={{ width: "100%" }}
					{...methods.register("list_name", {
						validate: {
							required: (value) => {
								if (listNames.includes(value))
									return "A list with this name already exists";
								if (!value) return "Name is required";
							},
							inputText: (input) => {
								if (
									!input.match(
										/^[-a-zA-Z0-9@\s\*&+%£=!:;><)?(^\[\]{\}\\\/\.\_]+$/
									)
								)
									return "Name cannot contain special characters";
							},
						},
						shouldUnregister: true,
					})}
				/>
				{formState?.errors?.list_name && (
					<Typography sx={{ fontSize: 12, ml: 1, color: Colours.red }}>
						{formState?.errors?.list_name?.message}
					</Typography>
				)}
				{/* {formState?.errors?.list_name.modifier &&
							formState?.errors?.plus?.[0].modifier?.type === "required" && (
								<Typography sx={{ fontSize: 12, color: Colours.gsblue }}>
									Modifier is required.
								</Typography>
							)} */}
			</Box>

			<Controller
				render={({ field: { onChange, value, ref, onBlur }, ...props }) => (
					<Autocomplete
						key={`autocomplete_`}
						options={mainlayouts}
						value={
							// console.log(value)
							mainlayouts.find(
								(i) =>
									parseInt(i.col) === parseInt(layout?.cols) &&
									parseInt(i.row) === parseInt(layout?.rows)
							)
							// value
						}
						// defaultValue={mainlayouts[0]}
						sx={{ width: "100%" }}
						size="small"
						getOptionLabel={(o) => o.value}
						onChange={(e, newValue) => {
							setValue("list_details[cols]", newValue.col);
							setValue("list_details[rows]", newValue.row);
						}}
						renderInput={(params) => (
							<TextField
								// required
								// error={fieldError[item.masterField + "." + item.dataField2]}
								// helperText={
								// 	fieldHelperText[item.masterField + "." + item.dataField2]
								// }

								sx={{ width: "100%", marginTop: 1 }}
								{...params}
								label={"Layout"}
								variant={"outlined"}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						)}
					/>
				)}
				rules={{ required: true }}
				name={`list_details`}
			/>
			{formState?.errors?.list_details && (
				<Typography sx={{ fontSize: 12, ml: 1, color: Colours.red }}>
					{formState?.errors?.list_details?.message}
				</Typography>
			)}
			<Controller
				name="list_details.staydown"
				control={control}
				render={
					({ field }) => {
						console.log(field?.value);
						return (
							<FormControlLabel
								control={
									<Checkbox
										checked={field?.value && field?.value === 1 ? true : false}
									/>
								}
								label={<Typography sx={{ fontSize: 14 }}>Staydown</Typography>}
								// inputRef={register}
								{...field}
								onChange={(e, newValue) => {
									methods.setValue(
										"list_details.staydown",
										newValue === true ? 1 : 0
									);
								}}
							/>
						);
					}
					//    <Checkbox {...field} />
				}
			/>
			<Controller
				name="list_details"
				control={control}
				defaultValue={[0, 1]}
				render={(field) => (
					<Box
						sx={{
							width: "100%",
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-start",
							justifyContent: "center",
						}}
					>
						<Typography
							sx={{ fontSize: 14, textAlign: "center", mb: 2 }}
							id="input-slider"
							gutterBottom
						>
							Number of Selections
						</Typography>
						<Stack
							spacing={2}
							direction="row"
							sx={{ mb: 1, width: "100%" }}
							alignItems="center"
						>
							<Typography
								sx={{ fontSize: 12, textAlign: "center", lineHeight: 1 }}
							>
								{minMax[0]} min
							</Typography>
							<Slider
								// {...field}
								getAriaLabel={() => "Item selection range"}
								value={minMax}
								onChange={(event, newvalue) => {
									setValue("list_details[selMin]", newvalue[0]);
									setValue("list_details[selMax]", newvalue[1]);
								}}
								// valueLabelDisplay="auto"
								min={0}
								max={10}
							/>
							<Typography
								sx={{ fontSize: 12, textAlign: "center", lineHeight: 1 }}
							>
								{minMax[1]} max
							</Typography>
						</Stack>
					</Box>
				)}
			/>
			<Controller
				name="autofilllist"
				control={control}
				render={({ field }) => (
					<FormControlLabel
						// value={option.id}
						control={<Checkbox checked={field.value ?? null} />}
						label={
							<Typography sx={{ fontSize: 14 }}>
								Autofill List with Category?
							</Typography>
						}
						{...field}
					/>
				)}
			/>
			{watchAutofill && categories?.length > 0 && (
				<Controller
					control={control}
					render={({ field: { onChange, onBlur, value, ref } }) => (
						<OptionSelect
							options={categories}
							value={value ?? null}
							defaultoption={categories[0]}
							type="group"
							label={"Category"}
							width="100%"
							onChange={handleAutofill}
						/>
					)}
					name="group"
					// rules={{ required: true }}
				/>
			)}
		</Box>
	);
}
