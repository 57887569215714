const bottombarlayouts = [
	{
		no: 1,
		value: "1x10",
		col: 10,
		row: 1,
	},
	{
		no: 2,
		value: "1x12",
		col: 12,
		row: 1,
	},
];
export default bottombarlayouts;
