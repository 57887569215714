import { Colours } from "../../assets/global/Theme-variable";

export const dataGridStyle = {
	"& .textPrimary": {
		color: Colours.gsblue,
	},
	// "& .MuiDataGrid-root": {
	// 	border: "none",
	// 	height: "100%",
	// 	width: "100%",
	// 	flex: 1,
	// 	overflow: "scroll",
	// 	// maxWidth: "100%",
	// },
	// "& .MuiDataGrid-cell": {
	// 	padding: 0,
	// },
	// "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
	// 	py: 1,
	// },
	// "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
	// 	py: "15px",
	// },
	// "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
	// 	py: "22px",
	// },
	"& MuiTablePagination-root": {
		fontSize: 12,
	},
	"& .MuiDataGrid-cellContent": {
		fontSize: 13,
	},
	"& .MuiDataGrid-cell": {
		fontSize: 10,
	},
	"& .MuiDataGrid-columnHeaders": {
		// backgroundColor: `${Colours.white} !important`,
		fontSize: 14,
		// color: Colours.darkGrey,
		// height: 40,
		// p: 2,
		borderBottom: "none",
	},
	"& .MuiDataGrid-columnHeaderTitle .pricingHeader": {
		textOverflow: "clip",
		whiteSpace: "break-spaces",
		lineHeight: 2,
		p: 2,
		"&:hover": {
			whiteSpace: "normal",
		},
	},
	// "& .pricingHeader": {
	// 	textOverflow: "clip",
	// 	whiteSpace: "break-spaces",
	// 	lineHeight: 1.5,
	// 	"&:hover": {
	// 		whiteSpace: "normal",
	// 	},
	// },
	"& .MuiDataGrid-root": {
		border: "none",
		fontSize: 10,
	},

	// "& .MuiFormControl-root MuiTextField-root": {
	// 	padding: "15px",
	// },
	// "& .MuiDataGrid-iconSeparator": {
	// 	color: " #f0f0f0",
	// },

	"& MuiDataGrid-toolbarQuickFilter": {
		border: "none",
	},
	"& .MuiTablePagination-selectLabel": {
		fontSize: 13,
	},
	"& .MuiTablePagination-displayedRows": {
		fontSize: 13,
	},
	"& .MuiPaginationItem-root": {
		fontSize: 13,
	},
	"& .printerDetails": {
		px: 0,
	},
	"& .priceMod": {
		p: 0.1,
	},
	"& .MuiDataGrid-selectedRowCount": {
		fontSize: 13,
		color: Colours.gsblue,
		mt: 2,
	},

	"& .MuiDataGrid-pinnedColumnHeaders": {
		boxShadow: "none",
		backgroundColor: "transparent",
	},
	"& .MuiDataGrid-pinnedColumns": {
		boxShadow: "none",
		"& .MuiDataGrid-cell": {
			padding: 0,
		},
	},
	"& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
		borderRight: "1px solid #f0f0f0",
		borderBottom: "1px solid #f0f0f0",
	},
	"& .MuiDataGrid-columnHeader:last-child, .MuiDataGrid-cell:last-child": {
		borderRight: "none",
	},
	"& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
		// borderBottom: "1px solid #f0f0f0",
	},

	"& .MuiDataGrid-main": {
		border: "1px solid #f0f0f0",
		borderRadius: 2,
	},
	"& .MuiDataGrid-row": {
		// borderTopColor: "#f0f0f0",
		// borderTopStyle: "solid",
		// borderTopWidth: "1px",
		cursor: "pointer",
		"&:hover": {
			backgroundColor: "whitesmoke",
			"& .tableControl": {
				display: "flex",
				flexDirection: "row",
				margin: 0,
				padding: 0,
			},
		},
		// "&:first-of-type": {
		// 	borderTop: `1px solid ${Colours.lighterGrey}`,
		// },
		// Add styles for rows in edit mode
		"& .edit-mode": {
			backgroundColor: "#ffeeba", // Change this to your desired color
		},
	},
	"& .MuiDataGrid-cell:focus": {
		outline: "none",
	},
	"& .MuiDataGrid-cell:focus-within": {
		outline: "none",
	},
	// "& .MuiDataGrid-cell": {
	// 	color: Colours.darkGrey,
	// },
	"& .MuiDataGrid-cell--textRight": {
		justifyContent: "flex-start",
	},
	"& .MuiDataGrid-footerContainer": {
		borderTop: "none",
		// backgroundColor: `${Colours.lightBlue} !important`,
	},
	"& .MuiCheckbox-root": {
		color: `${Colours.gsblue} !important`,
	},
	"& .tableControl": {
		display: "none",
	},

	"& .MuiDataGrid-virtualScroller": {
		backgroundColor: `white !important`,
	},
	"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
		width: "0.4em",

		height: "0.4em",
	},
	"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
		background: "#f5f5f5",
	},
	"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
		borderRadius: "10px",
		// boxShadow: "inset 0 0 10px rgba(0,0,0,.3)",
		backgroundColor: "#D8D8D6",
	},
	"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
		background: "#555",
	},
	"& .MuiDataGrid-virtualScroller": {
		backgroundColor: `white !important`,

		// overflowX: "hidden",
	},
	// "& .firstRow": {
	// 	// display: state.stepIndex === 4 ? null : "none",
	// 	"& .tableControl": {
	// 		display: state.stepIndex === 4 ? "unset" : "none",
	// 	},
	// },
	"& .deletedRow": {
		// backgroundColor: alpha(Colours.red, 0.25),
		position: "relative",
		// content: '""',
		// left: 0,
		// top: "50%",
		// right: 0,
		// borderTop: "1px solid",
		// borderColor: "inherit",
		// back
		// width: 100%;
		// min-height: 100vh;
		// position: relative;
		backgroundImage: `repeating-linear-gradient(
			-45deg,
			#ececec,
			#ececec 2px,
			#ffffff 2px,
			#ffffff 10px
		)`,
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
	},
	"& .MuiDataGrid-cell": {
		whiteSpace: "normal !important",
		wordWrap: "break-word !important",
	},

	// maxWidth: "100%",
};
