import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	IconButton,
	Typography,
} from "@mui/material";
import React from "react";
import { Colours } from "../assets/global/Theme-variable";
import { DataGridContext } from "../views/datagrids/data grid files/DataGridContext";
import { useNavigate } from "react-router-dom";
import MainButton from "./Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { CiBeerMugFull } from "react-icons/ci";
import { motion } from "framer-motion";

export default function SocialIcon({ link }) {
	const navigate = useNavigate();

	return (
		<a target="_blank" rel="noopener noreferrer" href={link.url}>
			<Card
				sx={{
					width: "100%",
					aspectRatio: 1 / 1,
					backgroundColor: "#DFF2F8",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					// margin: 2,
					gap: 1,

					// padding: 1,
					borderRadius: 1,
					"&::before": {
						aspectRatio: 1 / 1,

						display: "flex",
						// alignSelf: "stretch",
						// flex: 1,
						padding: "100%",
					},
				}}
				elevation={3}
				// onClick={link.onClick}
				component={motion.div}
				//react-dom.development.js:86 Warning: React does not recognize the `whileHover``whiletap` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `whilehover` instead. If you accidentally passed it from a parent component, remove it from the DOM element.
				whilehover={{ scale: 1.1 }}
				whiletap={{ scale: 0.9 }}
			>
				{/* <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
					{stat.name}
				</Typography> */}

				<Box
					sx={{
						borderRadius: 23,
						// background: Colours.gsblue,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						// height: 46,
						// width: 46,
					}}
				>
					<link.icon
						color={Colours.gsblue}
						sx={{ fontSize: 28, color: Colours.gsblue }}
					/>
				</Box>
			</Card>
		</a>
	);
}
