import * as React from "react";
import { Unstable_NumberInput as BaseNumberInput } from "@mui/base/Unstable_NumberInput";
import { styled } from "@mui/system";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { Colours } from "../assets/global/Theme-variable";

export const NumberInput = React.forwardRef(function NumberInput(props, ref) {
	const { id, field, value } = props;

	const handleChange = async (event, val) => {
		console.log(id, field, val);
		console.log(event);
		await ref.current.setEditCellValue({
			id,
			field,
			value: val,
		});
		ref.current.stopCellEditMode({ id, field });
	};

	return (
		<BaseNumberInput
			slots={{
				root: StyledInputRoot,
				input: StyledInput,
				incrementButton: StyledButton,
				decrementButton: StyledButton,
			}}
			slotProps={{
				incrementButton: {
					children: <AddIcon fontSize="small" color={Colours.gsblue} />,
					className: "increment",
				},
				decrementButton: {
					children: <RemoveIcon fontSize="small" />,
				},
			}}
			inputProps={{
				inputMode: "numeric",
				pattern: "[0-9]*",
			}}
			min={0}
			max={10}
			value={value}
			onChange={(event, val) => handleChange(event, val)}
			{...props}
			// ref={ref}
		/>
	);
});

const grey = {
	50: "#F3F6F9",
	100: "#E5EAF2",
	200: "#DAE2ED",
	300: "#C7D0DD",
	400: "#B0B8C4",
	500: "#9DA8B7",
	600: "#6B7A90",
	700: "#434D5B",
	800: "#303740",
	900: "#1C2025",
};

const StyledInputRoot = styled("div")(
	({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  color: ${Colours.gsblue};
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  width: max-content;
  align-items: center;
`
);

const StyledInput = styled("input")(
	({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.375;
  color: ${Colours.darkGrey};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${
		theme.palette.mode === "dark" ? "rgba(0,0,0, 0.5)" : "rgba(0,0,0, 0.05)"
	};
  border-radius: 8px;
  margin: 0 4px;
  padding: 5px 2px;
  outline: 0;
  min-width: 0;
  width: 2rem;
  text-align: center;

  &:hover {
    border-color: ${Colours.gsblue};
  }

  &:focus {
    border-color: ${Colours.gsblue};
    box-shadow: 0 0 0 1px ${Colours.gsblue};
  }

  &:focus-visible {
    outline: 0;
  }
`
);

const StyledButton = styled("button")(
	({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  line-height: 1.5;
  border: none;
  color: ${Colours.gsblue};
  background-color: ${"white"};
  width: 22px;
  height: 22px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    cursor: pointer;
  
    color: ${Colours.gsgrey};
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
  }
`
);
