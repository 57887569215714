import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

export default function PermissionsToggle({ onChange, value, action }) {
	return (
		<ToggleButtonGroup
			value={value}
			exclusive
			onChange={(event, newValue) => onChange(event, newValue, action)}
			aria-label="text alignment"
			size="small"
		>
			<ToggleButton value={true} aria-label="allow access" color="success">
				<CheckIcon />
			</ToggleButton>
			<ToggleButton value={false} aria-label="dont allow access" color="error">
				<ClearIcon />
			</ToggleButton>
		</ToggleButtonGroup>
	);
}
