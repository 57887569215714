import colours from "./colours";

const listOfLists = [
	{
		list_details: {
			rows: 5,
			cols: 2,
			pages: 3,
			buttons: [
				{
					// id: 1,
					// index: 1,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 5,
						foreground: 8,
					},
					pageNo: 1,
					size: 1,
					text: "Musar",
				},
				{
					// id: 2,
					// index: 2,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 9,
						foreground: 7,
					},
					pageNo: 1,
					size: 1,
					text: "Margherita Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 1,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 4,
					// index: 4,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 21,
						foreground: 14,
					},
					pageNo: 1,
					size: 1,
					text: "Magners",
				},
				{
					// id: 5,
					// index: 5,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 32,
						foreground: 23,
					},
					pageNo: 1,
					size: 1,
					text: "Vodka",
				},
				{
					// id: 6,
					// index: 6,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 23,
						foreground: 21,
					},
					pageNo: 1,
					size: 1,
					text: "Wine ",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 1,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 1,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 1,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 1,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 2,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 2,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 2,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 2,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 2,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 2,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 2,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 2,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 2,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 2,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 3,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 3,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 3,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 3,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 3,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 3,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 3,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 3,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 3,
					size: 1,
					text: "Nutty Pizza",
				},
				{
					// id: 3,
					// index: 3,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 16,
						foreground: 17,
					},
					pageNo: 3,
					size: 1,
					text: "Nutty Pizza",
				},
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 2,
				// 	// index: 2,
				// 	functionType: 21,
				// 	functionValue: 482,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 9,
				// 		foreground: 7,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Margherita Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 4,
				// 	// index: 4,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 21,
				// 		foreground: 14,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Magners",
				// },
				// {
				// 	// id: 5,
				// 	// index: 5,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 32,
				// 		foreground: 23,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Vodka",
				// },
				// {
				// 	// id: 6,
				// 	// index: 6,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 23,
				// 		foreground: 21,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Wine ",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 1,
				// 	// index: 1,
				// 	functionType: 21,
				// 	functionValue: 281,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 5,
				// 		foreground: 8,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Musar",
				// },
				// {
				// 	// id: 2,
				// 	// index: 2,
				// 	functionType: 21,
				// 	functionValue: 482,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 9,
				// 		foreground: 7,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Margherita Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 4,
				// 	// index: 4,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 21,
				// 		foreground: 14,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Magners",
				// },
				// {
				// 	// id: 5,
				// 	// index: 5,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 32,
				// 		foreground: 23,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Vodka",
				// },
				// {
				// 	// id: 6,
				// 	// index: 6,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 23,
				// 		foreground: 21,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Wine ",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 2,
				// 	// index: 2,
				// 	functionType: 21,
				// 	functionValue: 482,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 9,
				// 		foreground: 7,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Margherita Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 4,
				// 	// index: 4,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 21,
				// 		foreground: 14,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Magners",
				// },
				// {
				// 	// id: 5,
				// 	// index: 5,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 32,
				// 		foreground: 23,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Vodka",
				// },
				// {
				// 	// id: 6,
				// 	// index: 6,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 23,
				// 		foreground: 21,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Wine ",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
				// {
				// 	// id: 3,
				// 	// index: 3,
				// 	functionType: 21,
				// 	functionValue: 21,
				// 	hideText: 0,
				// 	mx: 1,
				// 	my: 1,
				// 	style: {
				// 		font: "10B",
				// 		alignmentW: 0,
				// 		alignmentH: 0,
				// 		background: 16,
				// 		foreground: 17,
				// 	},
				// 	pageNo: 1,
				// 	size: 1,
				// 	text: "Nutty Pizza",
				// },
			],
		},
		location: "L2",
		pages: 1,
		rows: 4,
		tags: ["7"],
		list_isDeleted: 0,
		list_name: "New list",
		list_number: "1",
		list_ref: "1",
		uid: "1",
	},
	{
		list_details: {
			rows: 4,
			cols: 5,
			pages: 1,
			buttons: [
				{
					id: 1,
					index: 1,
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 5,
						foreground: 7,
					},
					pageNo: 1,
					size: 1,
					text: "Bulmers",
				},
				{
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 13,
						foreground: 12,
					},
					pageNo: 1,
					size: 1,
					text: "Old Mout",
				},
				{
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 23,
						foreground: 32,
					},
					pageNo: 1,
					size: 1,
					text: "Peroni",
				},
				{
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 23,
						foreground: 43,
					},
					pageNo: 1,
					size: 1,
					text: "Magners",
				},
				{
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 21,
						foreground: 34,
					},
					pageNo: 1,
					size: 1,
					text: "Vodka",
				},
				{
					functionType: 21,
					functionValue: 21,
					hideText: 0,
					mx: 1,
					my: 1,
					style: {
						font: "10B",
						alignmentW: 0,
						alignmentH: 0,
						background: 23,
						foreground: 21,
					},
					pageNo: 1,
					size: 1,
					text: "Wine ",
				},
			],
		},
		location: "L2",
		pages: 1,
		rows: 4,
		tags: ["7"],
		list_isDeleted: 0,
		list_name: "Next list",
		list_number: "2",
		list_ref: "2",
		uid: "1",
	},
];
// const listOfLists = [
// 	{
// 		uid: "1",
// 		name: "Bar",
// 		// list_number
// 		area: "Large List",
// 		list_details: {tags: ["1"], buttons: [{}]},
// 		// pages: 1
// 		pages: [
// 			{
// 				// name: "Bar",
// 				number: "1",
// 				layout: {
// 					value: "3x4",
// 					col: 3,
// 					row: 4,
// 				},
// 				config: [
// 					{
// 						id: 1,
// 						index: 1,
// 						colour: colours[26],
// 						text: "Page 1 Bar",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						funct: {name: "Budweiser", number: 1},
// 						textColour: "white",
// 						fontSize: 20,
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 2,
// 						index: 2,
// 						colour: colours[27],
// 						text: "House Red",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						funct: {name: "House Red", number: 1},
// 						textAlign: "center",
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},

// 					{
// 						id: 3,
// 						index: 3,
// 						colour: colours[28],
// 						text: "Prosecco",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						funct: {name: "Prosecco", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 4,
// 						index: 4,
// 						colour: colours[29],
// 						text: "Magners",
// 						row: 1,
// 						col: 1,
// 						textAlign: "center",
// 						buttonSize: "1x1",
// 						funct: {name: "Magners", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},

// 					{
// 						id: 5,
// 						index: 5,
// 						colour: colours[26],
// 						text: "Bulmers",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Bulmers", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 6,
// 						index: 6,
// 						colour: colours[29],
// 						text: "San Miguel",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						funct: {name: "San Miguel", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 7,
// 						index: 7,
// 						colour: colours[29],
// 						text: "Vodka",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Bar", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 8,
// 						index: 8,
// 						colour: colours[41],
// 						text: "Tequila",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Bar", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},

// 					{
// 						id: 9,
// 						index: 9,
// 						colour: colours[26],
// 						text: "Jack Daniels",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Bar", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 				],
// 			},
// 			{
// 				// name: "Drinks",
// 				number: "2",
// 				layout: {
// 					value: "3x4",
// 					col: 3,
// 					row: 4,
// 				},
// 				config: [
// 					{
// 						id: 1,
// 						index: 1,
// 						colour: colours[26],
// 						text: "Page 2 Drinks",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						funct: {name: "Budweiser", number: 1},
// 						textColour: "white",
// 						fontSize: 20,
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 2,
// 						index: 2,
// 						colour: colours[27],
// 						text: "House Red",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						funct: {name: "House Red", number: 1},
// 						textAlign: "center",
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},

// 					{
// 						id: 3,
// 						index: 3,
// 						colour: colours[28],
// 						text: "Prosecco",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						funct: {name: "Prosecco", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 4,
// 						index: 4,
// 						colour: colours[29],
// 						text: "Magners",
// 						row: 1,
// 						col: 1,
// 						textAlign: "center",
// 						buttonSize: "1x1",
// 						funct: {name: "Magners", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},

// 					{
// 						id: 5,
// 						index: 5,
// 						colour: colours[26],
// 						text: "Bulmers",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Bulmers", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 6,
// 						index: 6,
// 						colour: colours[29],
// 						text: "San Miguel",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						funct: {name: "San Miguel", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 7,
// 						index: 7,
// 						colour: colours[29],
// 						text: "Vodka",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Bar", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 8,
// 						index: 8,
// 						colour: colours[41],
// 						text: "Tequila",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Bar", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},

// 					{
// 						id: 9,
// 						index: 9,
// 						colour: colours[26],
// 						text: "Jack Daniels",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Bar", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 				],
// 			},
// 		],
// 	},
// 	{
// 		uid: "2",
// 		name: "Drinks",
// 		area: "Large List",
// 		list_details: {tags: ["3"]},
// 		pages: [
// 			{
// 				// name: "Bar",
// 				number: "1",
// 				layout: {
// 					value: "3x4",
// 					col: 3,
// 					row: 4,
// 				},
// 				config: [
// 					{
// 						id: 1,
// 						index: 1,
// 						colour: colours[26],
// 						text: "Page 1 Bar",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						funct: {name: "Budweiser", number: 1},
// 						textColour: "white",
// 						fontSize: 20,
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 2,
// 						index: 2,
// 						colour: colours[27],
// 						text: "House Red",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						funct: {name: "House Red", number: 1},
// 						textAlign: "center",
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},

// 					{
// 						id: 3,
// 						index: 3,
// 						colour: colours[28],
// 						text: "Prosecco",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						funct: {name: "Prosecco", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 4,
// 						index: 4,
// 						colour: colours[29],
// 						text: "Magners",
// 						row: 1,
// 						col: 1,
// 						textAlign: "center",
// 						buttonSize: "1x1",
// 						funct: {name: "Magners", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},

// 					{
// 						id: 5,
// 						index: 5,
// 						colour: colours[26],
// 						text: "Bulmers",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Bulmers", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 6,
// 						index: 6,
// 						colour: colours[29],
// 						text: "San Miguel",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						funct: {name: "San Miguel", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 7,
// 						index: 7,
// 						colour: colours[29],
// 						text: "Vodka",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Bar", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 8,
// 						index: 8,
// 						colour: colours[41],
// 						text: "Tequila",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Bar", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},

// 					{
// 						id: 9,
// 						index: 9,
// 						colour: colours[26],
// 						text: "Jack Daniels",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Bar", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 				],
// 			},
// 			{
// 				// name: "Drinks",
// 				number: "2",
// 				layout: {
// 					value: "3x4",
// 					col: 3,
// 					row: 4,
// 				},
// 				config: [
// 					{
// 						id: 1,
// 						index: 1,
// 						colour: colours[26],
// 						text: "Page 2 Drinks",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						funct: {name: "Budweiser", number: 1},
// 						textColour: "white",
// 						fontSize: 20,
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 2,
// 						index: 2,
// 						colour: colours[27],
// 						text: "House Red",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						funct: {name: "House Red", number: 1},
// 						textAlign: "center",
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},

// 					{
// 						id: 3,
// 						index: 3,
// 						colour: colours[28],
// 						text: "Prosecco",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						funct: {name: "Prosecco", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 4,
// 						index: 4,
// 						colour: colours[29],
// 						text: "Magners",
// 						row: 1,
// 						col: 1,
// 						textAlign: "center",
// 						buttonSize: "1x1",
// 						funct: {name: "Magners", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},

// 					{
// 						id: 5,
// 						index: 5,
// 						colour: colours[26],
// 						text: "Bulmers",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Bulmers", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 6,
// 						index: 6,
// 						colour: colours[29],
// 						text: "San Miguel",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						funct: {name: "San Miguel", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 7,
// 						index: 7,
// 						colour: colours[29],
// 						text: "Vodka",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Bar", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 8,
// 						index: 8,
// 						colour: colours[41],
// 						text: "Tequila",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Bar", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},

// 					{
// 						id: 9,
// 						index: 9,
// 						colour: colours[26],
// 						text: "Jack Daniels",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Bar", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 				],
// 			},
// 		],
// 	},
// 	{
// 		uid: "3",
// 		name: "Payment Methods",
// 		area: "Function List",
// 		list_details: {tags: ["3"]},
// 		pages: [
// 			{
// 				// name: "Bar",
// 				number: "1",
// 				layout: {
// 					value: "3x4",
// 					col: 3,
// 					row: 4,
// 				},
// 				config: [
// 					{
// 						id: 1,
// 						index: 1,
// 						colour: colours[26],
// 						text: "CASH",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						funct: {name: "Cash", number: 1},
// 						textColour: "white",
// 						fontSize: 20,
// 						buttonType: "Payment Method",
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 2,
// 						index: 2,
// 						colour: colours[27],
// 						text: "Peazi",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						funct: {name: "Peazi", number: 2},
// 						textAlign: "center",
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: "Payment Method",
// 						boldText: false,
// 						showText: true,
// 					},

// 					{
// 						id: 3,
// 						index: 3,
// 						colour: colours[28],
// 						text: "Charge to Account",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						funct: {name: "Charge to Account", number: 3},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: "Payment Method",
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 4,
// 						index: 4,
// 						colour: colours[29],
// 						text: "Deposit",
// 						row: 1,
// 						col: 1,
// 						textAlign: "center",
// 						buttonSize: "1x1",
// 						funct: {name: "Deposit", number: 4},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: "Payment Method",
// 						boldText: false,
// 						showText: true,
// 					},

// 					{
// 						id: 5,
// 						index: 5,
// 						colour: colours[26],
// 						text: "EFT Push",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "EFT Push", number: 5},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: "Payment Method",
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 6,
// 						index: 6,
// 						colour: colours[29],
// 						text: "Zero",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						funct: {name: "Zero", number: 6},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: "Payment Method",
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 7,
// 						index: 7,
// 						colour: colours[29],
// 						text: "Manual Cards",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Manual Cards", number: 7},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: "Payment Method",
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 8,
// 						index: 8,
// 						colour: colours[41],
// 						text: "Online Payment",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Online Payment", number: 9},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: "Payment Method",
// 						boldText: false,
// 						showText: true,
// 					},

// 					{
// 						id: 9,
// 						index: 9,
// 						colour: colours[26],
// 						text: "Gift Card",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Gift Card", number: 10},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: "Payment Method",
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 10,
// 						index: 10,
// 						colour: colours[27],
// 						text: "Cheque",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Cheque", number: 22},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: "Payment Method",
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 11,
// 						index: 11,
// 						colour: colours[21],
// 						text: "Manual Amex",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Manual Amex", number: 13},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: "Payment Method",
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 12,
// 						index: 12,
// 						colour: colours[22],
// 						text: "Direct Debit",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Direct Debit", number: 21},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: "Payment Method",
// 						boldText: false,
// 						showText: true,
// 					},
// 				],
// 			},
// 			{
// 				// name: "Drinks",
// 				number: "2",
// 				layout: {
// 					value: "3x4",
// 					col: 3,
// 					row: 4,
// 				},
// 				config: [
// 					{
// 						id: 1,
// 						index: 1,
// 						colour: colours[26],
// 						text: "Page 2 Drinks",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						funct: {name: "Budweiser", number: 1},
// 						textColour: "white",
// 						fontSize: 20,
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 2,
// 						index: 2,
// 						colour: colours[27],
// 						text: "House Red",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						funct: {name: "House Red", number: 1},
// 						textAlign: "center",
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},

// 					{
// 						id: 3,
// 						index: 3,
// 						colour: colours[28],
// 						text: "Prosecco",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						funct: {name: "Prosecco", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 4,
// 						index: 4,
// 						colour: colours[29],
// 						text: "Magners",
// 						row: 1,
// 						col: 1,
// 						textAlign: "center",
// 						buttonSize: "1x1",
// 						funct: {name: "Magners", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},

// 					{
// 						id: 5,
// 						index: 5,
// 						colour: colours[26],
// 						text: "Bulmers",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Bulmers", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 6,
// 						index: 6,
// 						colour: colours[29],
// 						text: "San Miguel",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						funct: {name: "San Miguel", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 7,
// 						index: 7,
// 						colour: colours[29],
// 						text: "Vodka",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Bar", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 8,
// 						index: 8,
// 						colour: colours[41],
// 						text: "Tequila",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Bar", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},

// 					{
// 						id: 9,
// 						index: 9,
// 						colour: colours[26],
// 						text: "Jack Daniels",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						funct: {name: "Bar", number: 1},
// 						fontSize: 20,
// 						textColour: "white",
// 						buttonType: 21,
// 						boldText: false,
// 						showText: true,
// 					},
// 				],
// 			},
// 		],
// 	},
// 	{
// 		uid: "4",
// 		name: "Bar",
// 		area: "Bottom Bar",
// 		list_details: {tags: ["1"]},
// 		pages: [
// 			{
// 				id: 6,
// 				// tags: ["6"],
// 				uid: "6",

// 				number: "6",
// 				// name: "Bottom Bar",
// 				// area: "Bottom Bar",
// 				layout: {
// 					value: "1x10",
// 					col: 10,
// 					row: 1,
// 				},
// 				// variant: [],
// 				config: [
// 					{
// 						id: 1,
// 						index: 1,
// 						colour: colours[26],
// 						text: "bar 2",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						textColour: "white",
// 						fontSize: 12,
// 						funct: {number: "134", name: "Room"},
// 						buttonType: "Function",
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 2,
// 						index: 2,
// 						colour: colours[27],
// 						text: "restaurant",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						fontSize: 12,
// 						textColour: "white",
// 						funct: {number: "134", name: "Room"},
// 						buttonType: "Function",
// 						boldText: false,
// 						showText: true,
// 					},

// 					{
// 						id: 3,
// 						index: 3,
// 						colour: colours[28],
// 						text: "table",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						function: "",
// 						fontSize: 12,
// 						textColour: "white",
// 						funct: {number: "134", name: "Room"},
// 						buttonType: "Function",
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 4,
// 						index: 4,
// 						colour: colours[29],
// 						text: "seat",
// 						row: 1,
// 						col: 1,
// 						textAlign: "center",
// 						buttonSize: "1x1",
// 						function: "",
// 						fontSize: 12,
// 						textColour: "white",
// 						funct: {number: "134", name: "Room"},
// 						buttonType: "Function",
// 						boldText: false,
// 						showText: true,
// 					},

// 					{
// 						id: 5,
// 						index: 5,
// 						colour: colours[26],
// 						text: "correct",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						function: "",
// 						fontSize: 12,
// 						textColour: "white",
// 						funct: {number: "134", name: "Room"},
// 						buttonType: "Function",
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 6,
// 						index: 6,
// 						colour: colours[27],
// 						text: "function",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						function: "",
// 						fontSize: 12,
// 						textColour: "white",
// 						funct: {number: "134", name: "Room"},
// 						buttonType: "Function",
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 7,
// 						index: 7,
// 						colour: colours[28],
// 						text: "promo",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						function: "",
// 						fontSize: 12,
// 						textColour: "white",
// 						funct: {number: "134", name: "Room"},
// 						buttonType: "Function",
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 8,
// 						index: 8,
// 						colour: colours[29],
// 						text: "pay",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						function: "",
// 						fontSize: 12,
// 						textColour: "white",
// 						funct: {number: "134", name: "Room"},
// 						buttonType: "Function",
// 						boldText: false,
// 						showText: true,
// 					},

// 					{
// 						id: 9,
// 						index: 9,
// 						colour: colours[26],
// 						text: "exit",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						function: "",
// 						fontSize: 12,
// 						textColour: "white",
// 						funct: {number: "134", name: "Room"},
// 						buttonType: "Function",
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 10,
// 						index: 10,
// 						colour: colours[28],
// 						text: "empty",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",

// 						function: "",
// 						fontSize: 12,
// 						textColour: "black",
// 						funct: {number: "134", name: "Room"},
// 						buttonType: "Function",
// 						boldText: false,
// 						showText: true,
// 					},

// 					{
// 						id: 11,
// 						index: 11,
// 						colour: colours.darkGrey,
// 						text: "empty",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						function: "",
// 						fontSize: 12,
// 						textColour: "black",
// 						funct: {number: "134", name: "Room"},
// 						buttonType: "Function",
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 12,
// 						index: 12,
// 						colour: colours.sage,
// 						text: "empty",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						function: "",
// 						fontSize: 12,
// 						textColour: "black",
// 						funct: {number: "134", name: "Room"},
// 						buttonType: "Function",
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 13,
// 						index: 13,
// 						colour: colours.darkGrey,
// 						text: "empty",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						function: "",
// 						fontSize: 12,
// 						textColour: "black",
// 						funct: {number: "134", name: "Room"},
// 						buttonType: "Function",
// 						boldText: false,
// 						showText: true,
// 					},
// 					{
// 						id: 14,
// 						index: 14,
// 						colour: colours.darkGrey,
// 						text: "empty",
// 						row: 1,
// 						col: 1,
// 						buttonSize: "1x1",
// 						textAlign: "center",
// 						function: "",
// 						fontSize: 12,
// 						boldText: true,
// 						textColour: "black",
// 						funct: {number: "134", name: "Room"},
// 						buttonType: "Function",
// 						showText: true,
// 					},
// 				],
// 			},
// 		],
// 	},
// 	// {
// 	// 	id: 1,
// 	// 	uid: "1",

// 	// 	// number: 1,
// 	// 	name: "Bar",
// 	// 	area: "Large List",
// 	// 	tags: ["1"],
// 	// 	layout: {
// 	// 		value: "3x3",
// 	// 		col: 3,
// 	// 		row: 3,
// 	// 	},
// 	// 	variant: [
// 	// 		{
// 	// 			name: "Manchester",
// 	// 			number: "1",

// 	// 			layout: {
// 	// 				value: "3x3",
// 	// 				col: 3,
// 	// 				row: 3,
// 	// 			},
// 	// 			config: [
// 	// 				{
// 	// 					id: 1,
// 	// 					index: 1,
// 	// 					colour: colours[26],
// 	// 					text: "List 1 Manchester",
// 	// 					row: 1,
// 	// 					col: 1,
// 	// 					buttonSize: "1x1",
// 	// 					textAlign: "center",
// 	// 					funct: {name: "Budweiser", number: "1"},
// 	// 					textColour: "white",
// 	// 					fontSize: 12,
// 	// 					buttonType: 21,
// 	// 					boldText: false,
// 	// 					showText: true,
// 	// 				},
// 	// 				{
// 	// 					id: 2,
// 	// 					index: 2,
// 	// 					colour: colours[27],
// 	// 					text: "House Red",
// 	// 					row: 1,
// 	// 					col: 1,
// 	// 					buttonSize: "1x1",
// 	// 					funct: {name: "Budweiser", number: "1"},
// 	// 					textAlign: "center",
// 	// 					fontSize: 12,
// 	// 					textColour: "white",
// 	// 					buttonType: 21,
// 	// 					boldText: false,
// 	// 					showText: true,
// 	// 				},

// 	// 				{
// 	// 					id: 3,
// 	// 					index: 3,
// 	// 					colour: colours[28],
// 	// 					text: "Prosecco",
// 	// 					row: 1,
// 	// 					col: 1,
// 	// 					buttonSize: "1x1",
// 	// 					textAlign: "center",
// 	// 					funct: {name: "Budweiser", number: "1"},
// 	// 					fontSize: 12,
// 	// 					textColour: "white",
// 	// 					buttonType: 21,
// 	// 					boldText: false,
// 	// 					showText: true,
// 	// 				},
// 	// 				{
// 	// 					id: 4,
// 	// 					index: 4,
// 	// 					colour: colours[29],
// 	// 					text: "Magners",
// 	// 					row: 1,
// 	// 					col: 1,
// 	// 					textAlign: "center",
// 	// 					buttonSize: "1x1",
// 	// 					funct: {name: "Budweiser", number: "1"},
// 	// 					fontSize: 12,
// 	// 					textColour: "white",
// 	// 					buttonType: 21,
// 	// 					boldText: false,
// 	// 					showText: true,
// 	// 				},

// 	// 				{
// 	// 					id: 5,
// 	// 					index: 5,
// 	// 					colour: colours[26],
// 	// 					text: "Bulmers",
// 	// 					row: 1,
// 	// 					col: 1,
// 	// 					buttonSize: "1x1",
// 	// 					textAlign: "center",

// 	// 					funct: {name: "Budweiser", number: "1"},
// 	// 					fontSize: 12,
// 	// 					textColour: "white",
// 	// 					buttonType: 21,
// 	// 					boldText: false,
// 	// 					showText: true,
// 	// 				},
// 	// 				{
// 	// 					id: 6,
// 	// 					index: 6,
// 	// 					colour: colours[29],
// 	// 					text: "San Miguel",
// 	// 					row: 1,
// 	// 					col: 1,
// 	// 					buttonSize: "1x1",
// 	// 					textAlign: "center",
// 	// 					funct: {name: "Budweiser", number: "1"},
// 	// 					fontSize: 12,
// 	// 					textColour: "white",
// 	// 					buttonType: 21,
// 	// 					boldText: false,
// 	// 					showText: true,
// 	// 				},
// 	// 				{
// 	// 					id: 7,
// 	// 					index: 7,
// 	// 					colour: colours[29],
// 	// 					text: "Vodka",
// 	// 					row: 1,
// 	// 					col: 1,
// 	// 					buttonSize: "1x1",
// 	// 					textAlign: "center",

// 	// 					funct: {name: "Budweiser", number: "1"},
// 	// 					fontSize: 12,
// 	// 					textColour: "white",
// 	// 					buttonType: 21,
// 	// 					boldText: false,
// 	// 					showText: true,
// 	// 				},
// 	// 				{
// 	// 					id: 8,
// 	// 					index: 8,
// 	// 					colour: colours[41],
// 	// 					text: "Tequila",
// 	// 					row: 1,
// 	// 					col: 1,
// 	// 					buttonSize: "1x1",
// 	// 					textAlign: "center",

// 	// 					funct: {name: "Budweiser", number: "1"},
// 	// 					fontSize: 12,
// 	// 					textColour: "white",
// 	// 					buttonType: 21,
// 	// 					boldText: false,
// 	// 					showText: true,
// 	// 				},

// 	// 				{
// 	// 					id: 9,
// 	// 					index: 9,
// 	// 					colour: colours[26],
// 	// 					text: "Jack Daniels",
// 	// 					row: 1,
// 	// 					col: 1,
// 	// 					buttonSize: "1x1",
// 	// 					textAlign: "center",

// 	// 					funct: {name: "Budweiser", number: "1"},
// 	// 					fontSize: 12,
// 	// 					textColour: "white",
// 	// 					buttonType: 21,
// 	// 					boldText: false,
// 	// 					showText: true,
// 	// 				},
// 	// 			],
// 	// 		},
// 	// 		{
// 	// 			name: "Liverpool",
// 	// 			number: "2",

// 	// 			layout: {
// 	// 				value: "3x4",
// 	// 				col: 3,
// 	// 				row: 4,
// 	// 			},
// 	// 			config: [
// 	// 				{
// 	// 					id: 1,
// 	// 					index: 1,
// 	// 					colour: colours[26],
// 	// 					text: "List 1 Liverpool",
// 	// 					row: 1,
// 	// 					col: 1,
// 	// 					buttonSize: "1x1",
// 	// 					textAlign: "center",
// 	// 					funct: {name: "Budweiser", number: 1},
// 	// 					textColour: "white",
// 	// 					fontSize: 12,
// 	// 					buttonType: 21,
// 	// 					boldText: false,
// 	// 					showText: true,
// 	// 				},
// 	// 				{
// 	// 					id: 2,
// 	// 					index: 2,
// 	// 					colour: colours[27],
// 	// 					text: "House Red",
// 	// 					row: 1,
// 	// 					col: 1,
// 	// 					buttonSize: "1x1",
// 	// 					funct: {name: "House Red", number: 1},
// 	// 					textAlign: "center",
// 	// 					fontSize: 12,
// 	// 					textColour: "white",
// 	// 					buttonType: 21,
// 	// 					boldText: false,
// 	// 					showText: true,
// 	// 				},

// 	// 				{
// 	// 					id: 3,
// 	// 					index: 3,
// 	// 					colour: colours[28],
// 	// 					text: "Prosecco",
// 	// 					row: 1,
// 	// 					col: 1,
// 	// 					buttonSize: "1x1",
// 	// 					textAlign: "center",
// 	// 					funct: {name: "Prosecco", number: 1},
// 	// 					fontSize: 12,
// 	// 					textColour: "white",
// 	// 					buttonType: 21,
// 	// 					boldText: false,
// 	// 					showText: true,
// 	// 				},
// 	// 				{
// 	// 					id: 4,
// 	// 					index: 4,
// 	// 					colour: colours[29],
// 	// 					text: "Magners",
// 	// 					row: 1,
// 	// 					col: 1,
// 	// 					textAlign: "center",
// 	// 					buttonSize: "1x1",
// 	// 					funct: {name: "Magners", number: 1},
// 	// 					fontSize: 12,
// 	// 					textColour: "white",
// 	// 					buttonType: 21,
// 	// 					boldText: false,
// 	// 					showText: true,
// 	// 				},

// 	// 				{
// 	// 					id: 5,
// 	// 					index: 5,
// 	// 					colour: colours[26],
// 	// 					text: "Bulmers",
// 	// 					row: 1,
// 	// 					col: 1,
// 	// 					buttonSize: "1x1",
// 	// 					textAlign: "center",

// 	// 					funct: {name: "Bulmers", number: 1},
// 	// 					fontSize: 12,
// 	// 					textColour: "white",
// 	// 					buttonType: 21,
// 	// 					boldText: false,
// 	// 					showText: true,
// 	// 				},
// 	// 				{
// 	// 					id: 6,
// 	// 					index: 6,
// 	// 					colour: colours[29],
// 	// 					text: "San Miguel",
// 	// 					row: 1,
// 	// 					col: 1,
// 	// 					buttonSize: "1x1",
// 	// 					textAlign: "center",
// 	// 					funct: {name: "San Miguel", number: 1},
// 	// 					fontSize: 12,
// 	// 					textColour: "white",
// 	// 					buttonType: 21,
// 	// 					boldText: false,
// 	// 					showText: true,
// 	// 				},
// 	// 				{
// 	// 					id: 7,
// 	// 					index: 7,
// 	// 					colour: colours[29],
// 	// 					text: "Vodka",
// 	// 					row: 1,
// 	// 					col: 1,
// 	// 					buttonSize: "1x1",
// 	// 					textAlign: "center",

// 	// 					funct: {name: "Bar", number: 1},
// 	// 					fontSize: 12,
// 	// 					textColour: "white",
// 	// 					buttonType: 21,
// 	// 					boldText: false,
// 	// 					showText: true,
// 	// 				},
// 	// 				{
// 	// 					id: 8,
// 	// 					index: 8,
// 	// 					colour: colours[41],
// 	// 					text: "Tequila",
// 	// 					row: 1,
// 	// 					col: 1,
// 	// 					buttonSize: "1x1",
// 	// 					textAlign: "center",

// 	// 					funct: {name: "Bar", number: 1},
// 	// 					fontSize: 12,
// 	// 					textColour: "white",
// 	// 					buttonType: 21,
// 	// 					boldText: false,
// 	// 					showText: true,
// 	// 				},

// 	// 				{
// 	// 					id: 9,
// 	// 					index: 9,
// 	// 					colour: colours[26],
// 	// 					text: "Jack Daniels",
// 	// 					row: 1,
// 	// 					col: 1,
// 	// 					buttonSize: "1x1",
// 	// 					textAlign: "center",

// 	// 					funct: {name: "Bar", number: 1},
// 	// 					fontSize: 12,
// 	// 					textColour: "white",
// 	// 					buttonType: 21,
// 	// 					boldText: false,
// 	// 					showText: true,
// 	// 				},
// 	// 			],
// 	// 		},
// 	// 	],
// 	// 	config: [
// 	// 		{
// 	// 			id: 1,
// 	// 			index: 1,
// 	// 			colour: colours[26],
// 	// 			text: "List 1",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			funct: {name: "Classic Margherita Pizza", number: 1},
// 	// 			textColour: "white",
// 	// 			fontSize: 12,
// 	// 			buttonType: 21,
// 	// 			boldText: true,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 2,
// 	// 			index: 2,
// 	// 			colour: colours[27],
// 	// 			text: "House Red",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			funct: {name: "House Red", number: 1},
// 	// 			textAlign: "center",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: true,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 3,
// 	// 			index: 3,
// 	// 			colour: colours[28],
// 	// 			text: "Prosecco",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			funct: {name: "Prosecco", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: true,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 4,
// 	// 			index: 4,
// 	// 			colour: colours[29],
// 	// 			text: "Magners",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			textAlign: "center",
// 	// 			buttonSize: "1x1",
// 	// 			funct: {name: "Magners", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: true,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 5,
// 	// 			index: 5,
// 	// 			colour: colours[26],
// 	// 			text: "Bulmers",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			funct: {name: "Bulmers", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: true,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 6,
// 	// 			index: 6,
// 	// 			colour: colours[29],
// 	// 			text: "San Miguel",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			funct: {name: "San Miguel", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: true,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 7,
// 	// 			index: 7,
// 	// 			colour: colours[29],
// 	// 			text: "Vodka",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			funct: {name: "Bar", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: true,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 8,
// 	// 			index: 8,
// 	// 			colour: colours[41],
// 	// 			text: "Tequila",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			funct: {name: "Bar", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: true,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 9,
// 	// 			index: 9,
// 	// 			colour: colours[26],
// 	// 			text: "Jack Daniels",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			funct: {name: "Bar", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: true,
// 	// 			showText: true,
// 	// 		},
// 	// 	],
// 	// },
// 	// {
// 	// 	id: 2,
// 	// 	tags: ["2"],
// 	// 	uid: "2",

// 	// 	// number: 2,
// 	// 	name: "Kitchen",
// 	// 	area: "Large List",
// 	// 	layout: {
// 	// 		value: "3x3",
// 	// 		col: 3,
// 	// 		row: 3,
// 	// 	},
// 	// 	variant: [],
// 	// 	config: [
// 	// 		{
// 	// 			id: 1,
// 	// 			index: 1,
// 	// 			colour: colours[26],
// 	// 			text: "List 2",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			funct: {name: "Pizza", number: 1},
// 	// 			textColour: "white",
// 	// 			fontSize: 12,
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 2,
// 	// 			index: 2,
// 	// 			colour: colours[27],
// 	// 			text: "Pasta",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			funct: {name: "Pasta", number: 1},
// 	// 			textAlign: "center",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 3,
// 	// 			index: 3,
// 	// 			colour: colours[28],
// 	// 			text: "Sirloin",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			funct: {name: "Sirloin", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 4,
// 	// 			index: 4,
// 	// 			colour: colours[29],
// 	// 			text: "Fillet",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			textAlign: "center",
// 	// 			buttonSize: "1x1",
// 	// 			funct: {name: "Fillet", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 5,
// 	// 			index: 5,
// 	// 			colour: colours[26],
// 	// 			text: "Bulmers",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			funct: {name: "Bulmers", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 		},
// 	// 		{
// 	// 			id: 6,
// 	// 			index: 6,
// 	// 			colour: colours[29],
// 	// 			text: "San Miguel",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			funct: {name: "San Miguel", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 		},
// 	// 		{
// 	// 			id: 7,
// 	// 			index: 7,
// 	// 			colour: colours[29],
// 	// 			text: "Vodka",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			funct: {name: "Bar", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 8,
// 	// 			index: 8,
// 	// 			colour: colours[41],
// 	// 			text: "Tequila",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			funct: {name: "Bar", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 9,
// 	// 			index: 9,
// 	// 			colour: colours[26],
// 	// 			text: "Jack Daniels",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			funct: {name: "Bar", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 	],
// 	// },
// 	// {
// 	// 	id: 3,
// 	// 	tags: ["3"],
// 	// 	uid: "3",

// 	// 	// number: 3,
// 	// 	name: "Drinks",
// 	// 	area: "Large List",
// 	// 	layout: {
// 	// 		value: "3x3",
// 	// 		col: 3,
// 	// 		row: 3,
// 	// 	},
// 	// 	variant: [],
// 	// 	config: [
// 	// 		{
// 	// 			id: 1,
// 	// 			index: 1,
// 	// 			colour: colours[26],
// 	// 			text: "List 3",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			funct: {name: "Budweiser", number: 1},
// 	// 			textColour: "white",
// 	// 			fontSize: 12,
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 2,
// 	// 			index: 2,
// 	// 			colour: colours[27],
// 	// 			text: "House Red",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			funct: {name: "House Red", number: 1},
// 	// 			textAlign: "center",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 3,
// 	// 			index: 3,
// 	// 			colour: colours[28],
// 	// 			text: "Prosecco",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			funct: {name: "Prosecco", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 4,
// 	// 			index: 4,
// 	// 			colour: colours[29],
// 	// 			text: "Magners",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			textAlign: "center",
// 	// 			buttonSize: "1x1",
// 	// 			funct: {name: "Magners", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,

// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 5,
// 	// 			index: 5,
// 	// 			colour: colours[26],
// 	// 			text: "Bulmers",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			funct: {name: "Bulmers", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 6,
// 	// 			index: 6,
// 	// 			colour: colours[29],
// 	// 			text: "San Miguel",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			funct: {name: "San Miguel", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 7,
// 	// 			index: 7,
// 	// 			colour: colours[29],
// 	// 			text: "Vodka",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			funct: {name: "Bar", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 8,
// 	// 			index: 8,
// 	// 			colour: colours[41],
// 	// 			text: "Tequila",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			funct: {name: "Bar", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 9,
// 	// 			index: 9,
// 	// 			colour: colours[26],
// 	// 			text: "Jack Daniels",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			funct: {name: "Classic Margherita Pizza", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			showText: true,

// 	// 			boldText: false,
// 	// 		},
// 	// 	],
// 	// },
// 	// {
// 	// 	id: 4,
// 	// 	tags: ["4"],
// 	// 	uid: "4",

// 	// 	// number: 4,
// 	// 	name: "Soft Drinks",
// 	// 	area: "Large List",
// 	// 	layout: {
// 	// 		value: "3x3",
// 	// 		col: 3,
// 	// 		row: 3,
// 	// 	},
// 	// 	variant: [],
// 	// 	config: [
// 	// 		{
// 	// 			id: 1,
// 	// 			index: 1,
// 	// 			colour: colours[26],
// 	// 			text: "List 4",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			funct: {name: "Coke", number: 1},
// 	// 			textColour: "white",
// 	// 			fontSize: 12,
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 2,
// 	// 			index: 2,
// 	// 			colour: colours[27],
// 	// 			text: "Diet Coke",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			funct: {name: "Diet Coke", number: 1},
// 	// 			textAlign: "center",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 3,
// 	// 			index: 3,
// 	// 			colour: colours[28],
// 	// 			text: "Lemonade",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			funct: {name: "Lemonade", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 4,
// 	// 			index: 4,
// 	// 			colour: colours[29],
// 	// 			text: "Diet Lemonade",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			textAlign: "center",
// 	// 			buttonSize: "1x1",
// 	// 			funct: {name: "Diet Lemonade", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 5,
// 	// 			index: 5,
// 	// 			colour: colours[26],
// 	// 			text: "Coffee",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			funct: {name: "Coffee", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 6,
// 	// 			index: 6,
// 	// 			colour: colours[29],
// 	// 			text: "Tea",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			funct: {name: "Tea", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 7,
// 	// 			index: 7,
// 	// 			colour: colours[29],
// 	// 			text: "Splash Lime",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			funct: {name: "Splash Lime", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 8,
// 	// 			index: 8,
// 	// 			colour: colours[41],
// 	// 			text: "Splash Blackcurrant",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			funct: {name: "Splash Blackcurrant", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 9,
// 	// 			index: 9,
// 	// 			colour: colours[26],
// 	// 			text: "Soda",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			funct: {name: "Soda", number: 1},
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			buttonType: 21,
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 	],
// 	// },
// 	// {
// 	// 	id: 5,
// 	// 	tags: ["5"],
// 	// 	uid: "5",

// 	// 	// number: 5,
// 	// 	name: "Bottom Bar",
// 	// 	area: "Bottom Bar",
// 	// 	layout: {
// 	// 		value: "1x10",
// 	// 		col: 10,
// 	// 		row: 1,
// 	// 	},
// 	// 	variant: [],
// 	// 	config: [
// 	// 		{
// 	// 			id: 1,
// 	// 			index: 1,
// 	// 			colour: colours[26],
// 	// 			text: "home",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			textColour: "white",
// 	// 			fontSize: 12,
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 2,
// 	// 			index: 2,
// 	// 			colour: colours[27],
// 	// 			text: "restaurant",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 3,
// 	// 			index: 3,
// 	// 			colour: colours[28],
// 	// 			text: "table",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 4,
// 	// 			index: 4,
// 	// 			colour: colours[29],
// 	// 			text: "seat",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			textAlign: "center",
// 	// 			buttonSize: "1x1",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 5,
// 	// 			index: 5,
// 	// 			colour: colours[26],
// 	// 			text: "correct",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 6,
// 	// 			index: 6,
// 	// 			colour: colours[27],
// 	// 			text: "function",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 7,
// 	// 			index: 7,
// 	// 			colour: colours[28],
// 	// 			text: "promo",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 8,
// 	// 			index: 8,
// 	// 			colour: colours[29],
// 	// 			text: "pay",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 9,
// 	// 			index: 9,
// 	// 			colour: colours[26],
// 	// 			text: "exit",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 10,
// 	// 			index: 10,
// 	// 			colour: colours[28],
// 	// 			text: "empty",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "black",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 11,
// 	// 			index: 11,
// 	// 			colour: colours.darkGrey,
// 	// 			text: "empty",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "black",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 12,
// 	// 			index: 12,
// 	// 			colour: colours.sage,
// 	// 			text: "empty",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "black",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 13,
// 	// 			index: 13,
// 	// 			colour: colours.darkGrey,
// 	// 			text: "empty",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "black",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 		},
// 	// 		{
// 	// 			id: 14,
// 	// 			index: 14,
// 	// 			colour: colours.darkGrey,
// 	// 			text: "empty",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "black",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 	],
// 	// },
// 	// {
// 	// 	id: 6,
// 	// 	tags: ["6"],
// 	// 	uid: "6",

// 	// 	// number: 6,
// 	// 	name: "Bottom Bar",
// 	// 	area: "Bottom Bar",
// 	// 	layout: {
// 	// 		value: "1x10",
// 	// 		col: 10,
// 	// 		row: 1,
// 	// 	},
// 	// 	variant: [],
// 	// 	config: [
// 	// 		{
// 	// 			id: 1,
// 	// 			index: 1,
// 	// 			colour: colours[26],
// 	// 			text: "bar 2",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			textColour: "white",
// 	// 			fontSize: 12,
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 2,
// 	// 			index: 2,
// 	// 			colour: colours[27],
// 	// 			text: "restaurant",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 3,
// 	// 			index: 3,
// 	// 			colour: colours[28],
// 	// 			text: "table",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 4,
// 	// 			index: 4,
// 	// 			colour: colours[29],
// 	// 			text: "seat",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			textAlign: "center",
// 	// 			buttonSize: "1x1",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 5,
// 	// 			index: 5,
// 	// 			colour: colours[26],
// 	// 			text: "correct",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 6,
// 	// 			index: 6,
// 	// 			colour: colours[27],
// 	// 			text: "function",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 7,
// 	// 			index: 7,
// 	// 			colour: colours[28],
// 	// 			text: "promo",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 8,
// 	// 			index: 8,
// 	// 			colour: colours[29],
// 	// 			text: "pay",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 9,
// 	// 			index: 9,
// 	// 			colour: colours[26],
// 	// 			text: "exit",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 10,
// 	// 			index: 10,
// 	// 			colour: colours[28],
// 	// 			text: "empty",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "black",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 11,
// 	// 			index: 11,
// 	// 			colour: colours.darkGrey,
// 	// 			text: "empty",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "black",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 12,
// 	// 			index: 12,
// 	// 			colour: colours.sage,
// 	// 			text: "empty",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "black",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 13,
// 	// 			index: 13,
// 	// 			colour: colours.darkGrey,
// 	// 			text: "empty",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "black",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 14,
// 	// 			index: 14,
// 	// 			colour: colours.darkGrey,
// 	// 			text: "empty",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			boldText: true,
// 	// 			textColour: "black",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			showText: true,
// 	// 		},
// 	// 	],
// 	// },
// 	// {
// 	// 	id: 7,
// 	// 	tags: [],
// 	// 	uid: "7",

// 	// 	// number: 6,
// 	// 	name: "Pay",
// 	// 	area: "Standard List",
// 	// 	layout: {
// 	// 		value: "3x3",
// 	// 		col: 3,
// 	// 		row: 3,
// 	// 	},
// 	// 	variant: [],
// 	// 	config: [
// 	// 		{
// 	// 			id: 1,
// 	// 			index: 1,
// 	// 			colour: colours[26],
// 	// 			text: "bar 2",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			textColour: "white",
// 	// 			fontSize: 12,
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 2,
// 	// 			index: 2,
// 	// 			colour: colours[27],
// 	// 			text: "restaurant",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 3,
// 	// 			index: 3,
// 	// 			colour: colours[28],
// 	// 			text: "table",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 4,
// 	// 			index: 4,
// 	// 			colour: colours[29],
// 	// 			text: "seat",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			textAlign: "center",
// 	// 			buttonSize: "1x1",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 5,
// 	// 			index: 5,
// 	// 			colour: colours[26],
// 	// 			text: "correct",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 6,
// 	// 			index: 6,
// 	// 			colour: colours[27],
// 	// 			text: "function",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 7,
// 	// 			index: 7,
// 	// 			colour: colours[28],
// 	// 			text: "promo",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 8,
// 	// 			index: 8,
// 	// 			colour: colours[29],
// 	// 			text: "pay",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 9,
// 	// 			index: 9,
// 	// 			colour: colours[26],
// 	// 			text: "exit",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 10,
// 	// 			index: 10,
// 	// 			colour: colours[28],
// 	// 			text: "empty",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "black",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 11,
// 	// 			index: 11,
// 	// 			colour: colours.darkGrey,
// 	// 			text: "empty",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "black",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 12,
// 	// 			index: 12,
// 	// 			colour: colours.sage,
// 	// 			text: "empty",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "black",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 13,
// 	// 			index: 13,
// 	// 			colour: colours.darkGrey,
// 	// 			text: "empty",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "black",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 14,
// 	// 			index: 14,
// 	// 			colour: colours.darkGrey,
// 	// 			text: "empty",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			boldText: true,
// 	// 			textColour: "black",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			showText: true,
// 	// 		},
// 	// 	],
// 	// },
// 	// {
// 	// 	id: 8,
// 	// 	tags: [],
// 	// 	uid: "8",

// 	// 	// number: 6,
// 	// 	name: "Function",
// 	// 	area: "Function List",
// 	// 	layout: {
// 	// 		value: "3x3",
// 	// 		col: 5,
// 	// 		row: 3,
// 	// 	},
// 	// 	variant: [],
// 	// 	config: [
// 	// 		{
// 	// 			id: 1,
// 	// 			index: 1,
// 	// 			colour: colours[26],
// 	// 			text: "bar 2",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			textColour: "white",
// 	// 			fontSize: 12,
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 2,
// 	// 			index: 2,
// 	// 			colour: colours[27],
// 	// 			text: "restaurant",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 3,
// 	// 			index: 3,
// 	// 			colour: colours[28],
// 	// 			text: "table",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 4,
// 	// 			index: 4,
// 	// 			colour: colours[29],
// 	// 			text: "seat",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			textAlign: "center",
// 	// 			buttonSize: "1x1",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 5,
// 	// 			index: 5,
// 	// 			colour: colours[26],
// 	// 			text: "correct",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 6,
// 	// 			index: 6,
// 	// 			colour: colours[27],
// 	// 			text: "function",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 7,
// 	// 			index: 7,
// 	// 			colour: colours[28],
// 	// 			text: "promo",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 8,
// 	// 			index: 8,
// 	// 			colour: colours[29],
// 	// 			text: "pay",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 9,
// 	// 			index: 9,
// 	// 			colour: colours[26],
// 	// 			text: "exit",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "white",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 10,
// 	// 			index: 10,
// 	// 			colour: colours[28],
// 	// 			text: "empty",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",

// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "black",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},

// 	// 		{
// 	// 			id: 11,
// 	// 			index: 11,
// 	// 			colour: colours.darkGrey,
// 	// 			text: "empty",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "black",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 12,
// 	// 			index: 12,
// 	// 			colour: colours.sage,
// 	// 			text: "empty",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "black",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 13,
// 	// 			index: 13,
// 	// 			colour: colours.darkGrey,
// 	// 			text: "empty",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			textColour: "black",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			boldText: false,
// 	// 			showText: true,
// 	// 		},
// 	// 		{
// 	// 			id: 14,
// 	// 			index: 14,
// 	// 			colour: colours.darkGrey,
// 	// 			text: "empty",
// 	// 			row: 1,
// 	// 			col: 1,
// 	// 			buttonSize: "1x1",
// 	// 			textAlign: "center",
// 	// 			function: "",
// 	// 			fontSize: 12,
// 	// 			boldText: true,
// 	// 			textColour: "black",
// 	// 			funct: {number: "134", name: "Room"},
// 	// 			buttonType: "Function",
// 	// 			showText: true,
// 	// 		},
// 	// 	],
// 	// },
// ];

export default listOfLists;
