import { AddToPhotosOutlined } from "@mui/icons-material/";
import {
	BsColumnsGap,
	BsGeoAlt,
	BsGrid1X2,
	BsPeople,
	BsPrinter,
	BsTags,
	SlSizeFullscreen,
} from "react-icons/bs";
import { CiBeerMugFull, CiRuler } from "react-icons/ci";
import { RiGridFill, RiMoneyPoundBoxLine } from "react-icons/ri";
import { MdOutlineBallot } from "react-icons/md";

import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import ArchiveIcon from "@mui/icons-material/Archive";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import { BiSolidDrink } from "react-icons/bi";
import { BiPound } from "react-icons/bi";
import { BiGridAlt } from "react-icons/bi";
import { BiPrinter } from "react-icons/bi";
import { BiCabinet } from "react-icons/bi";
import { BiGroup } from "react-icons/bi";
import { BiPurchaseTag } from "react-icons/bi";
import { BiMapPin } from "react-icons/bi";

const Menuitems = [
	{
		title: "Items",
		icon: BiSolidDrink,
		href: "/items",
		iconSize: 20,
		dbName: "items",
	},
	{
		title: "Pricing",
		icon: BiPound,
		href: "/pricing",
		iconSize: 20,
		dbName: "pricing",
	},
	{
		title: "Screens",
		icon: BiGridAlt,
		href: "/screens",
		iconSize: 20,
		dbName: "screens",
	},
	{
		title: "Staff",
		icon: BiGroup,
		href: "/staff",
		iconSize: 20,
		dbName: "servers",
	},
	{
		title: "Manage Categories",
		icon: BiCabinet,
		selectedIcon: BiCabinet,
		dropdownIcon: MdOutlineBallot,
		mainTitle: "Categories",
		// subDropdownTitle: "Categories",
		// href: "/categories",
		iconSize: 20,
		dbName: "salesgroups",
		children: [
			{
				title: "Major Categories",
				href: "/majorcategories",
				icon: LibraryBooksOutlinedIcon, // updated icon
				iconSize: 25,
				dbName: "majorcategories",
			},
			{
				title: "Categories",
				href: "/categories",
				icon: LibraryBooksOutlinedIcon, // updated icon
				iconSize: 25,
				dbName: "categories",
			},
			// ... add more sub-categories here ...
		],
	},

	{
		title: "Tags",
		icon: BiPurchaseTag,
		href: "/tags",
		iconSize: 20,
		dbName: "tags",
	},
	{
		title: "Printers",
		icon: BiPrinter,
		href: "/printers",
		dbName: "printers",
		iconSize: 20,
	},
	{
		title: "Sites",
		icon: BiMapPin,
		href: "/sites",
		iconSize: 20,
		dbName: "sites",
	},

	// {
	// 	title: "Sizes",
	// 	icon: CiRuler,
	// 	href: "/sizes",
	// 	iconSize: 27,
	// },

	// {
	// 	title: "Designer",
	// 	icon: BsGrid1X2,
	// 	href: "/designer",
	// 	iconSize: 22,
	// 	dbName: "list",
	// },

	// {
	// 	title: "DGC Tags",
	// 	icon: AddToPhotosOutlined,
	// 	href: "/dataGridControllerTags",
	// },
	// {
	// 	title: "DGC Items",
	// 	icon: AddToPhotosOutlined,
	// 	href: "/dataGridControllerItems",
	// },
	// {
	// 	title: "DGC Item Types",
	// 	icon: AddToPhotosOutlined,
	// 	href: "/dataGridControllerItemTypes",
	// },
];

export default Menuitems;
