import * as React from "react";

import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { SortableList } from "./SortableList";
import { nanoid } from "nanoid";
import Rule from "./Rule";
import { useFormContext } from "react-hook-form";

export default function PermissionsModal({
	rows,
	rowId,
	permissionList,
	permissionObject,
	setPermissionObj,
}) {
	const handleAddRule = (event, newExpanded) => {
		const rules = [...permissionObject];
		rules.push({ page: [], actions: [], allow: true, id: nanoid() });
		setPermissionObj(rules);
	};
	const { setValue, setError, reset } = useFormContext();
	const [error, setErrors] = React.useState([]);
	// console.log(permissionObject);
	// const errors = [];

	React.useEffect(() => {
		const errors = [];

		permissionObject?.forEach((rule, index) => {
			// console.log("start");
			for (let i = index + 1; i <= permissionObject?.length; i++) {
				// console.log(rule, permissionObject[i]);
				if (
					(permissionObject[i]?.page?.includes(rule?.page[0]) ||
						permissionObject[i]?.page?.includes("All") ||
						permissionObject[i]?.page?.includes("*")) &&
					(permissionObject[i]?.actions?.includes(rule?.actions[0]) ||
						permissionObject[i]?.actions?.includes("All") ||
						permissionObject[i]?.actions?.includes("*")) &&
					permissionObject[i]?.allow !== rule?.allow
				) {
					// console.log(
					// 	!errors.some((i) => i?.rule?.id === permissionObject[i]?.id)
					// );
					if (!errors.includes(rule)) {
						console.log("here", rule);
						errors.push(permissionObject[i]?.id);
					}
				}
				//  else {
				// 	setError(null);
				// }
			}
			// console.log(errors);
			setValue("policy_details.rules", permissionObject);
			setErrors(errors);
			// setError("policy_details.rules", { type: "custom", message: errors });
		});
	}, [permissionObject, setPermissionObj]);

	// React.useEffect(() => {
	// 	setPermissionObj([]);
	// }, [reset]);

	const handleEditRule = (newValue, i) => {
		const rules = [...permissionObject];
		const test = newValue.find((val) => val === "*");
		if (test !== undefined && newValue.at(-1) === "*") {
			rules[i].page = [test];
			// rules[i].api = [test];
		} else if (test !== undefined && newValue.at(0) === "*") {
			rules[i].page = newValue.filter((val) => val !== "*");
			// rules[i].api = newValue.filter((val) => val !== "all");
		} else {
			rules[i].page = newValue;
			// rules[i].api = newValue;
		}
		setPermissionObj(rules);
	};

	const handleEditActions = (event, i) => {
		const rules = [...permissionObject];
		const value = event.target.value;
		const test = value.find((val) => val === "*");

		if (test !== undefined) {
			rules[i].actions = [test];
		} else {
			rules[i].actions = event.target.value;
		}
		if (test !== undefined && value.at(-1) === "*") {
			rules[i].actions = [test];
		} else if (test !== undefined && value.at(0) === "*") {
			rules[i].actions = value.filter((val) => val !== "*");
		} else {
			rules[i].actions = value;
		}
		setPermissionObj(rules);
	};

	const handleEditAllow = (event, newValue, i) => {
		const rules = [...permissionObject];
		if (newValue !== null) {
			rules[i].allow = Boolean(newValue);

			setPermissionObj(rules);
		}
	};

	const handleRuleDelete = (event, i) => {
		const rules = [...permissionObject].filter((rule, index) => index !== i);

		setPermissionObj(rules);
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "flex-start",
				alignItems: "flex-start",
				width: "100%",
				height: "100%",
				overflow: "auto",
				"&::-webkit-scrollbar": {
					width: "6px",
				},
				"&::-webkit-scrollbar-track": {
					backgroundColor: "#f5f5f5",
				},
				"&::-webkit-scrollbar-thumb": {
					borderRadius: "10px",
					boxShadow: "inset 0 0 10px rgba(0,0,0,.3)",
					// backgroundColor: "#f5f5f5",
				},
			}}
		>
			<Typography>Rules</Typography>

			{permissionObject?.length > 0 ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "flex-start",
						width: "100%",
						height: "100%",
						overflow: "auto",
						mb: 2,
						"&::-webkit-scrollbar": {
							width: "6px",
						},
						"&::-webkit-scrollbar-track": {
							backgroundColor: "#f5f5f5",
						},
						"&::-webkit-scrollbar-thumb": {
							borderRadius: "10px",
							boxShadow: "inset 0 0 10px rgba(0,0,0,.3)",
							// backgroundColor: "#f5f5f5",
						},
					}}
				>
					<SortableList
						items={permissionObject}
						onChange={setPermissionObj}
						renderItem={(rule, i) => (
							<SortableList.Item id={rule.id}>
								<Rule
									rule={rule}
									handleEditActions={handleEditActions}
									handleEditRule={handleEditRule}
									handleRuleDelete={handleRuleDelete}
									handleEditAllow={handleEditAllow}
									permissionObject={permissionObject}
									error={error}
								/>
							</SortableList.Item>
						)}
					/>
				</Box>
			) : (
				<Box
					sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
				>
					<Typography sx={{ fontSize: 13 }}>
						No rules specified. Click 'add new' to create your first rule.
					</Typography>
				</Box>
			)}
			{/* </Box> */}
			<Button
				size="medium"
				sx={{ alignSelf: "flex-end" }}
				onClick={handleAddRule}
				variant="outlined"
			>
				<AddIcon style={{ fontSize: "20px" }} /> Add New
			</Button>
		</Box>
	);
}
