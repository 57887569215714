import * as React from "react";
import Select from "@mui/material/Select";
import { MenuItem } from "@mui/material";

export const EditInputCell = React.forwardRef(function EditInputCell(
	props,
	ref
) {
	const { id, value, field, valueOptions, width, valueText } = props;

	const handleChange = async (event) => {
		await ref.current.setEditCellValue({
			id,
			field,
			value: event.target.value,
		});
		ref.current.stopCellEditMode({ id, field });
	};

	return (
		<Select
			value={value}
			renderValue={(o) => (o.valueText ? o.valueText : valueText)}
			onChange={handleChange}
			size="small"
			style={{ width: width ? width : "100%" }}
			MenuProps={{
				style: {
					maxHeight: 500,
					// Set the maximum height for the menu
				},
			}}
		>
			{valueOptions.map((option) => (
				<MenuItem key={option.value} value={option.value}>
					{option.text}
				</MenuItem>
			))}
		</Select>
	);
});
