import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/base/Button";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { alpha, styled } from "@mui/material/styles";
import { InputLabel } from "@mui/material";
import { Colours } from "../assets/global/Theme-variable";
import { Input, inputClasses } from "@mui/base";

const CustomInput = React.forwardRef(function CustomInput(props, ref) {
	const { slots, onChange, defaultValue, ...other } = props;
	// console.log(price);

	return (
		<Input
			slots={{
				root: StyledInputRoot,
				input: StyledInputElement,
				...slots,
			}}
			{...other}
			ref={ref}
			onChange={props.onChange}
			defaultValue={defaultValue}
			sx={{ fontSize: 12 }}
		/>
	);
});

// CustomInput.propTypes = {
// 	/**
// 	 * The components used for each slot inside the InputBase.
// 	 * Either a string to use a HTML element or a component.
// 	 * @default {}
// 	 */
// 	slots: PropTypes.shape({
// 		input: PropTypes.elementType,
// 		root: PropTypes.elementType,
// 		textarea: PropTypes.elementType,
// 	}),
// };

export default function InputAdornments(props) {
	return (
		<Box
			sx={{
				width: "100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "stretch",
			}}
		>
			<InputLabel shrink htmlFor={props.defaultValue}>
				{props.label}
			</InputLabel>
			<CustomInput
				id="outlined-start-adornment"
				startAdornment={<InputAdornment>£</InputAdornment>}
				defaultValue={props.defaultValue}
				onChange={props.onChange}
			/>
		</Box>
	);
}

const StyledInputRoot = styled("div")(
	({ theme }) => `
  border-radius: 4px;
  font-family: 'DM Sans';
  background-color: ${
		theme.palette.mode === "light" ? Colours.fieldBackground : "#1A2027"
	};

  display: flex;
  align-items: center;
  justify-content: center;


  &.${inputClasses.focused} {
    box-shadow: 0 0 0 3px ${alpha(theme.palette.primary.main, 0.25)};
  }

  &:hover {
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const StyledInputElement = styled("input")(
	({ theme }) => `
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  flex-grow: 1;
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 6px;
  outline: 0;
`
);

const InputAdornment = styled("div")`
	margin-left: 8px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
`;
