import { motion, AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
import colours from "../views/grid/data/colours";
import { Colours } from "../assets/global/Theme-variable";
import { CircularProgress } from "@mui/material";

const divVariants = {
	square: {
		// background: `linear-gradient(45deg, #00c6ff, #0072ff)`,
		// background: `linear-gradient(45deg,${Colours.gsblue}, #00c6ff)`,
		// background: Colours.gsblue,
		// color: "white",
	},
	rounded: {
		// background: "linear-gradient(45deg, #5127EE, #5C36F5)",
		// background: `linear-gradient(45deg, #00c6ff, ${Colours.gsblue})`,

		// background: Colours.gsblue,
		// color: "white",
		width: "80%",
	},
	newChanges: {
		scale: [1, 2, 2, 1, 1],
		rotate: [0, 0, 180, 180, 0],
		borderRadius: ["0%", "0%", "50%", "50%", "0%"],

		transition: {
			duration: 2,
			ease: "easeInOut",
			times: [0, 0.2, 0.5, 0.8, 1],
			repeat: Infinity,
			repeatDelay: 1,
		},
	},
	hover: {
		scale: 1.05,
		transition: { duration: 0.5 },
	},
};

export default function AnimatedButton({
	newChanges,
	onClick,
	saveText,
	setSaveText,
	saveButtonVariants,
}) {
	const [borderRadius, setBorderRadius] = useState(true);

	const handleClick = () => {
		// onClick();
		// setBorderRadius(!borderRadius);
		// setSaveText(
		// 	// saveText === "Save" || saveText === "Subscribe" ? type : "Save"
		// 	saveText !== saveButtonVariants[0] ? saveText
		// );
		// setTimeout(() => {
		// 	setSaveText(saveButtonVariants.static);
		// }, 10000);
	};

	return (
		<motion.div
			drag
			style={divStyle}
			variants={divVariants}
			transition={{ duration: 0.5 }}
			animate={borderRadius ? "square" : "rounded"}
			onClick={onClick}
			// chnaged to lower case to fix the error message react-dom.development.js:86 Warning: React does not recognize the `whileHover` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `whilehover` instead. If you accidentally passed it from a parent component, remove it from the DOM element.
			//hover animation doesnt work with it in lowercase so have changed it back and will relook at the warning
			whileHover="hover"
		>
			<AnimatePresence>
				{
					// (
					// saveText === "Save" || saveText === "Subscribe")
					saveText === saveButtonVariants.static && (
						<motion.p
							key="save"
							initial={{ y: 0, opacity: 1 }}
							exit={{ y: 0, opacity: 0 }}
							style={{ position: "absolute" }}
						>
							{saveText}
						</motion.p>
					)
				}

				{saveText === saveButtonVariants.loading && (
					// <motion.div
					// 	key="saved"
					// 	initial={{ y: 20, opacity: 0 }}
					// 	animate={{ y: 0, opacity: 1 }}
					// 	transition={{ delay: 0.5 }}
					// 	style={{
					// 		position: "absolute",
					// 		display: "flex",
					// 		alignItems: "center",
					// 	}}
					// >
					<CircularProgress
						size={24}
						sx={{
							color: "white",
							position: "absolute",
							top: "50%",
							left: "50%",
							marginTop: "-12px",
							marginLeft: "-12px",
						}}
					/>
					// </motion.div>
				)}
				{saveText === saveButtonVariants.success && (
					<motion.div
						key="saved"
						initial={{ y: 20, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						transition={{ delay: 0.5 }}
						style={{
							position: "absolute",
							display: "flex",
							alignItems: "center",
						}}
					>
						{}
						<FaCheck
							style={{ marginRight: "8", height: "15px", width: "15px" }}
						/>
						<p>{saveText}</p>
					</motion.div>
				)}
			</AnimatePresence>
		</motion.div>
	);
}

const divStyle = {
	width: "100%",
	height: "100%",
	fontSize: 14,
	// background: "linear-gradient(45deg, #00c6ff, colours.gsblue)",
	background: Colours.gsblue,
	transition: "0.4s",
	backgroundImage:
		"linear-gradient(to right, #2a9ec5 0%, #77bbd7  51%, #2a9ec5  100%)",
	backgroundSize: "200% auto",

	// #6FADA2,
	"&:hover": {
		backgroundPosition: "right center",
		boxShadow: "0 3px 5px 2px rgba(217, 220, 222, .61)",
	},
	borderRadius: 8,
	display: "flex",
	color: "white",
	justifyContent: "center",
	alignItems: "center",
	cursor: "pointer",
	// position: "relative",
	// boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
};
