import React, { useState } from "react";
import jsonp from "jsonp";
import {
	Box,
	Button,
	Card,
	CircularProgress,
	Input,
	Typography,
} from "@mui/material";
import { LayoutGroup, motion } from "framer-motion";
import AnimatedButton from "./AnimatedButton";
import { useMailChimp } from "../services/useMailchimp";
// import LoadingButton from "@mui/lab/LoadingButton";

export default function MailChipForm() {
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);

	const { subscribe, status, error, value } = useMailChimp(
		"https://gs-systems.us17.list-manage.com/subscribe/post?u=6160edbea3eacdf882010be2b&amp;id=9537272c54&amp;f_id=007555e0f0"
	);

	const onSubmit = (e) => {
		// 	e.preventDefault();
		// 	console.log("here");
		// 	setLoading(true);
		// 	setSaveText(saveButtonVariants.loading);
		// 	const url =
		// 		"https://gs-systems.us17.list-manage.com/subscribe/post?u=6160edbea3eacdf882010be2b&amp;id=9537272c54&amp;f_id=007555e0f0"; // you can use .env file to replace this

		// 	jsonp(`${url}&EMAIL=${email}`, { param: "c" }, (_, data) => {
		// 		setLoading(false);
		// 		console.log("here ", data);
		// 		alert(data);
		// 		setSaveText(saveButtonVariants.success);
		// 	});
		// 	// setLoading(false);
		// 	// setTimeout(() => {
		// 	// 	setLoading(false);
		// 	// 	setSaveText(saveButtonVariants.static);
		// 	// }, 5000);
		// };
		if (!email) return;

		subscribe({
			EMAIL: email,
		});

		if (error) {
			setSaveText(saveButtonVariants.error);
		} else if (value) {
			setSaveText(saveButtonVariants.success);
		}
	};

	const saveButtonVariants = {
		static: "Subscribe",
		loading: "Subscribing",
		success: "Success",
		error: "Error",
	};
	const [saveText, setSaveText] = React.useState(saveButtonVariants.static);

	const button = {
		animate: {
			opacity: 1,
			scale: [1, 1.1, 1],
			transition: {
				duration: 1,
				repeat: 1,
				repeatType: "loop",
			},
		},
		rest: { scale: 1, opacity: 1 },
		pressed: { scale: 0.95 },
	};

	return (
		<Box
			sx={{
				width: "100%",
				// aspectRatio: 1 / 1,
				// backgroundColor: "#DFF2F8",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "flex-start",
				// padding: 2,
				borderRadius: 1,
				// "&::before": {
				// 	display: "flex",
				// 	alignSelf: "stretch",
				// 	flex: 1,
				// 	padding: "100%",
				// },
			}}
			elevation={0}
			// onClick={link.onClick}
			// component={motion.div}
			//react-dom.development.js:86 Warning: React does not recognize the `whileHover``whileTap` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `whilehover` instead. If you accidentally passed it from a parent component, remove it from the DOM element.
			whilehover={{ scale: 1.1 }}
			whiletap={{ scale: 0.9 }}
		>
			<Typography
				sx={{ fontSize: 12, paddingTop: 0, userSelect: "none", mb: 1 }}
				color="text.secondary"
			>
				Keep updated with the latest GS Systems tech, services &amp; industry
				insights
			</Typography>
			<Box
				sx={{
					display: "flex",
					width: "100%",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "space-between",
				}}
			>
				<Input
					value={email}
					placeholder="Enter your email"
					onChange={(e) => setEmail(e.target.value)}
					sx={{ width: "70%", mr: 1 }}
				/>

				<motion.div
					// transition={spring}
					variants={button}
					// key={newChanges}
					custom={loading}
					// initial="rest"
					animate={loading ? "animate" : "rest"}
					// whileHover="hover"
					// whileTap="pressed"
					style={{
						display: "flex",
						flex: 1,
						height: "100%",
						justifyContent: "center",
						alignItems: "center",
						width: "30%",
					}}
				>
					<AnimatedButton
						setSaveText={setSaveText}
						saveText={saveText}
						onClick={onSubmit}
						newChanges={loading}
						saveButtonVariants={saveButtonVariants}
						loading={loading}
					/>
				</motion.div>
			</Box>
			<Box sx={{ alignSelf: "flex-end" }}>
				{status.error && (
					<Typography variant="tinyText">{status.error}</Typography>
				)}
				{error && <Typography variant="tinyText">{error}</Typography>}
				{value && value.includes("You're already subscribed") ? (
					<Typography variant="tinyText">
						Email address is already signed up
					</Typography>
				) : value ? (
					<Typography variant="tinyText">{value}</Typography>
				) : null}
			</Box>
		</Box>
	);
}
