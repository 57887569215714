import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "./config";
import React, { useState } from "react";
import {
	Box,
	Button,
	Collapse,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { linkContext } from "./context";
import { useFetch } from "./useFetch";
import axios from "axios";
import { Colours } from "../../assets/global/Theme-variable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { API_ORIGIN, APP_URL } from "../../API/apiConfig";

function isPrimary(primaryUserId, identity) {
	return (
		identity.provider !== primaryUserId.split("|")[0] ||
		identity.user_id !== primaryUserId.split("|")[1]
	);
}

export default function LinkedAccounts() {
	const { apiOrigin = APP_URL, audience } = getConfig(); //API FROM CONFIG
	const domain = process.env.REACT_APP_API_SERVER_URL;

	const { getAccessTokenSilently, user, getAccessTokenWithPopup } = useAuth0();
	const { loginWithPopup, getIdTokenClaims } = useAuth0(linkContext);
	const [linkError, setLinkError] = useState(null);
	const { loading, error, refetch, loggedInUser } = useFetch();

	const authenticateUser = async () => {
		await loginWithPopup({
			authorizationParams: {
				max_age: 0,
				scope: "openid",
			},
			appState: {
				returnTo: `${window.location.origin}`,
			},
		});
		return await getIdTokenClaims();
	};

	const linkAccount = async () => {
		const token = await getAccessTokenSilently();

		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		// const {sub} = user;

		const {
			__raw: targetUserIdToken,
			email_verified,
			email,
			sub,
		} = await authenticateUser();

		console.log(sub, email, email_verified);
		if (!email_verified) {
			setLinkError(
				`Account linking is only allowed to a verified account. Please verify your email ${email}.`
			);
			return;
		}

		const provider = sub.split("|")[0];
		// const secondaryId = sub.split("|")[1];
		const secondaryId = sub;

		const body = {
			user_id: user.sub,
			data: { user_id: secondaryId, provider: provider },
		};

		await axios
			.post(`${apiOrigin}/api/pos/updateIdentities`, body, config)
			.then((response) => {
				console.log("fe res", response);
			})
			.catch((error) => {
				console.log("fe err", error);
			});
		refetch();
	};

	const unlinkAccount = async (profile, i) => {
		const token = await getAccessTokenSilently();
		const { provider, user_id } = profile;

		const body = {
			user_id: user.sub,
			data: { user_id: user_id, provider: provider },
		};

		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};
		console.log(body);
		await axios
			.post(`${apiOrigin}/api/pos/deleteIdentity`, body, config)
			.then((response) => {
				console.log("fe res", response);
			})
			.catch((error) => {
				console.log("fe err", error);
			});
		refetch();
	};

	const canLink = !loading && !error && loggedInUser?.email_verified === true;

	function Row({ i, identity }) {
		return (
			<TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
				<TableCell component="th" scope="row">
					{identity.connection}
				</TableCell>
				<TableCell align="left">{identity.user_id}</TableCell>
				<TableCell>{identity.profileData.email}</TableCell>

				<TableCell align="left">
					<Button onClick={() => unlinkAccount(identity, i)}>Unlink</Button>
				</TableCell>
			</TableRow>
		);
	}

	return (
		<Box
			sx={{ mt: 4, maxWidth: "100%", display: "flex", flexDirection: "column" }}
		>
			<Box
				sx={{
					width: "100%",
					mb: 2,
				}}
			>
				<Typography
					variant="h3"
					sx={{ fontSize: 16, color: Colours.gsblue, justifySelf: "center" }}
				>
					Linked Accounts
				</Typography>
			</Box>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell align="left">connection</TableCell>
						<TableCell align="left">User ID</TableCell>

						<TableCell align="left">Email</TableCell>
						<TableCell align="left">actions</TableCell>
					</TableRow>
				</TableHead>
				{error ? (
					<TableBody>
						<TableRow>
							<TableCell align="center" colSpan={6}>
								Failed to load
							</TableCell>
						</TableRow>
					</TableBody>
				) : linkError ? (
					<TableBody>
						<TableRow>
							<TableCell
								align="center"
								colSpan={6}
								toggle={() => setLinkError(null)}
							>
								{linkError}
							</TableCell>
						</TableRow>
					</TableBody>
				) : loggedInUser?.email_verified === false ? (
					<TableBody>
						<TableRow>
							<TableCell
								align="center"
								colSpan={6}
							>{`The email ${loggedInUser.email} is not verified. Account linking is only allowed for verified emails.`}</TableCell>
						</TableRow>
					</TableBody>
				) : loggedInUser?.identities.filter((identity) =>
						isPrimary(loggedInUser.user_id, identity)
				  ).length === 0 ? (
					<TableBody>
						<TableRow>
							<TableCell align="center" colSpan={6} sx={{}}>
								No linked accounts found.
							</TableCell>
						</TableRow>
					</TableBody>
				) : (
					<TableBody>
						{loggedInUser?.identities
							?.filter((identity) => isPrimary(loggedInUser.user_id, identity))
							.map((identity, i) => (
								<Row i={i} identity={identity} />
							))}
					</TableBody>
				)}
			</Table>
			{/* {canLink ? (
				<Button
					variant="contained"
					onClick={linkAccount}
					sx={{ mt: 4, width: "15%", alignSelf: "flex-end" }}
				>
					Link Account
				</Button>
			) : null} */}
		</Box>
	);
}
