import { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { getConfig } from "./config";
import { API_ORIGIN, APP_URL } from "../../API/apiConfig";

export const useFetch = (url) => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [loggedInUser, setLoggedInUser] = useState(null);
	const { apiOrigin = APP_URL, audience } = getConfig();
	const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();

	const refetch = useCallback(async () => {
		// console.log("refetching");
		try {
			const token = await getAccessTokenSilently();
			// console.log(token);
			const config = {
				headers: { Authorization: `Bearer ${token}` },
			};

			const body = {
				userid: user.sub,
			};

			await axios
				.post(`${apiOrigin}/api/pos/auth0user`, body, config)
				.then((response) => {
					// console.log("fe res useFetch", response);
					setLoggedInUser(response.data);
				})
				.catch((error) => {
					console.log("fe err useFetch", error);
				});
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}, [getAccessTokenSilently]);

	useEffect(() => {
		refetch();
		// console.log("fetch");
	}, [refetch]);

	return {
		error,
		loading,
		loggedInUser,
		refetch,
		isAuthenticated,
	};
};
