import { FormControl } from "@mui/base";
import { Box, InputLabel } from "@mui/material";
import React from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Colours } from "../assets/global/Theme-variable";
import { InputAdornment, TextField, TextareaAutosize } from "@mui/material";
import { NumberFormatCustom } from "../views/datagrids/utils";

const TextAreaAutoSize = React.forwardRef((props, ref) => {
	const { inputRef, onChange, placeholder, value, rows, ...other } = props;
	return (
		<Textarea
			ref={ref}
			aria-label="minimum height"
			minRows={3}
			maxRows={3}
			value={value}
			placeholder={placeholder}
			// placeholder="Minimum 3 rows"
			{...other}
			onChange={onChange}
		/>
	);
});

const Textarea = styled(TextareaAutosize)(
	({ theme }) => `
  `
);

export default function CustomTextField({
	label,
	onChange,
	defaultValue,
	disabled,
	value,
	type,
	origin,
	multiline,
	placeholder,
	width,
}) {
	return (
		<Box
			sx={{
				width: width ? width : "100%",
				display: "flex",
				flexDirection: "column",
				// alignItems: "stretch",
			}}
		>
			{
				<InputLabel shrink htmlFor={defaultValue}>
					{label}
				</InputLabel>
			}
			<Input
				placeholder={
					defaultValue ? defaultValue : placeholder ? placeholder : "-"
				}
				onChange={onChange}
				value={value}
				rows={multiline ? 3 : null}
				multiline={multiline}
				disabled={disabled}
				size={"small"}
				label={label}
				// placeholder={placeholder && placeholder}
				// contentEditable={disabled}
				startAdornment={
					type === "price" && (
						<InputAdornment
							sx={{ fontSize: "15px", pl: 1, pr: 0, m: 0 }}
							position="start"
						>
							£
						</InputAdornment>
					)
				}
				origin={origin}
				inputComponent={
					type === "price"
						? NumberFormatCustom
						: multiline
						? TextAreaAutoSize
						: null
				}
				inputProps={{
					inputMode: type === "price" ? "numeric" : null,
					pattern: type === "price" ? "[0-9]*" : null,
				}}
			/>
		</Box>
	);
}

const Input = styled(InputBase)(({ theme, origin, multiline }) => ({
	border: "1px solid",
	borderRadius: 4,
	borderColor:
		origin === "datagrid"
			? "white"
			: theme.palette.mode === "light"
			? "#E0E3E7"
			: "#2D3843",
	transition: theme.transitions.create([
		"border-color",
		"background-color",
		"box-shadow",
	]),
	"&:focus-within": {
		boxShadow:
			origin === "datagrid"
				? "none"
				: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
		borderColor: origin === "datagrid" ? "none" : theme.palette.primary.main,
	},
	height: multiline,
	"label + &": {
		marginTop: 0,
		marginBottom: 0,
	},
	"& .MuiInputBase-multiline": { textWrap: "wrap" },
	"& 	.MuiInputBase-inputMultiline": { textWrap: "wrap" },
	// "& .MuiInput": {
	// 	border: "1px solid",
	// 	borderColor:
	// 		origin === "datagrid"
	// 			? "white"
	// 			: theme.palette.mode === "light"
	// 			? "#E0E3E7"
	// 			: "#2D3843",
	// },
	"& .MuiInputBase-input": {
		borderRadius: "4px",

		// position: "relative",
		// backgroundColor: origin === "datagrid" ? "white" : Colours.fieldBackground,
		// border: "none !important",
		// border: "1px solid",
		// borderColor:
		// 	origin === "datagrid"
		// 		? "white"
		// 		: theme.palette.mode === "light"
		// 		? "#E0E3E7"
		// 		: "#2D3843",

		// height: multiline && 100,
		// width: multiline && 200,
		fontSize: 16,
		maxWidth: "100%",
		marginRight: origin === "datagrid" ? 20 : 0,
		padding: origin === "datagrid" ? "2px 2px" : "10px 12px 10px 5px",
		// transition: theme.transitions.create([
		// 	"border-color",
		// 	"background-color",
		// 	"box-shadow",
		// ]),
		// "&:focus": {
		// 	boxShadow:
		// 		origin === "datagrid"
		// 			? "none"
		// 			: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
		// 	borderColor: origin === "datagrid" ? "none" : theme.palette.primary.main,
		// },
		"&:disabled": {
			border: "none !important",
		},
		"& .MuiInput-underline:before": { borderBottom: "none !important" },
		"& .MuiInput-underline": { borderBottom: "none !important" },
	},
	"& .MuiInput-underline:before": { borderBottom: "none !important" },
	"& .MuiInput-underline": { borderBottom: "none !important" },
}));
