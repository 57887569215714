import React from "react";
import "./spinner.css";
import {Box, Typography} from "@mui/material";
import {Player, Controls} from "@lottiefiles/react-lottie-player";

const Spinner = () => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "center",
				width: "100%",
			}}
		>
			<Player
				autoplay
				loop
				src="https://lottie.host/dbb1fc67-4c44-4fa2-8735-06ba4bc43b9c/UXQpOXj6zK.json"
				style={{width: "500px"}}
			>
				<Controls
					visible={false}
					// buttons={["play", "repeat", "frame", "debug"]}
				/>
			</Player>
		</Box>
	);
};
export default Spinner;
