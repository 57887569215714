import * as React from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { getTextColour } from "../views/datagrids/utils";

function Tag(props) {
	const { label, ...other } = props;

	return (
		<div {...other}>
			<Typography
				sx={{
					fontSize: props.square === "true" ? 12 : 10,
					textAlign: "center",
					color:
						props.colour === "#2a9ec5"
							? "white"
							: getTextColour(props.colour).colour,
				}}
			>
				{label}
			</Typography>
		</div>
	);
}

export const StyledTag = styled(Tag)(
	(props) => `
  display: flex;
  align-items: center;
  justify-content:center;
  height: 24px;
  width: ${props.square === "true" ? "24px" : "auto"};
  margin:  ${props.square === "true" ? "4px" : "2px"};
  line-height: 22px;
  background-color: ${props.colour};
  border: 1px solid ${props.colour};
  border-radius: 6px;
  box-sizing: content-box;
  padding: ${props.square === "true" ? "0px" : "2px 6px 2px 6px"};
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${props.colour};
    background-color: ${props.colour};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
);
