import * as React from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import {
	// Link as RouterLink,
	Route,
	Routes,
	useLocation,
	useNavigate,
} from "react-router-dom";
import { Colours } from "../assets/global/Theme-variable";
import { Link } from "@mui/material";

function LinkRouter(props) {
	const {
		to,
		unsavedChanges,
		saveChanges,
		discard,
		breadcrumb,
		setErrorMessage,
	} = props;
	const navigate = useNavigate();
	const handleClick = () => {
		if (unsavedChanges) {
			// setErrorMessage({
			// 	open: true,
			// 	text: `You have unfinished changes. Please save before continuing.`,
			// });
			setErrorMessage({
				open: true,
				text: `You have unfinished changes. Please save before continuing.`,
				button1text: "Discard Changes",
				// button1func: navigate(discard),
				button2text: "Save Changes",
				button2func: saveChanges,
			});
		} else {
			navigate(to);
		}
	};
	return (
		<Link component="button" color={Colours.gsblue} onClick={handleClick}>
			{breadcrumb}
		</Link>
	);
	// <Link {...props} component={RouterLink} />;
}

function Page(props) {
	const location = useLocation();
	const pathnames = location.pathname.split("/").filter((x) => x);
	const { unsavedChanges, setErrorMessage } = props;

	const breadcrumbNameMap = {
		"/site": "Site",
		"/designer": "Designer",
		"/items": "Items",
		"/sites": "Sites",
		"/pricing": "Pricing",
		"/tags": "Tags",
		"/staff": "Staff",
		"/majorcategories": "Major Categories",
		"/categories": "Categories",
		"/printers": "Printers",
		"/configuration": "Configuration",
		"/screens": "Screens",
		"/profile": "User Settings",
		"/admin": "Admin",
		"/permissions": "Permissions",
	};

	const lastBreadCrumb = {
		pricebands: "Price Bands",
		analysisCategories: "Analysis Categories",

		paymentMethods: "Payment Methods",
		macros: "Macros",
	};

	return (
		<Breadcrumbs aria-label="breadcrumb">
			<LinkRouter underline="hover" color="inherit" to="/">
				Home
			</LinkRouter>
			{pathnames.map((value, index) => {
				const last = index === pathnames.length - 1;
				const to = `/${pathnames.slice(0, index + 1).join("/")}`;
				const num = `${pathnames.slice(1)}`;

				return last ? (
					<Typography
						color={Colours.gsblue}
						sx={{ userSelect: "none" }}
						key={to}
						variant="h1"
					>
						{lastBreadCrumb[num]
							? lastBreadCrumb[num]
							: num
							? num
							: breadcrumbNameMap[to]}
					</Typography>
				) : (
					<LinkRouter
						underline="hover"
						color="inherit"
						to={to}
						key={to}
						breadcrumb={breadcrumbNameMap[to]}
						unsavedChanges={unsavedChanges}
						setErrorMessage={setErrorMessage}
						{...props}
					/>
					// 	{breadcrumbNameMap[to]}
					// </LinkRouter>
				);
			})}
		</Breadcrumbs>
	);
}

export default function RouterBreadcrumbs(props) {
	return (
		<Box sx={{ display: "flex", flexDirection: "column", width: 360 }}>
			<Routes>
				<Route path="*" element={<Page {...props} />} />
			</Routes>
		</Box>
	);
}
