import {
	Autocomplete,
	Box,
	Button,
	Collapse,
	Fade,
	FormControlLabel,
	IconButton,
	LinearProgress,
	MenuItem,
	Modal,
	Popover,
	Select,
	Stack,
	Switch,
	TextField,
	Typography,
} from "@mui/material";
import { dataGridStyle } from "../../styles";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
	Controller,
	FormProvider,
	useFieldArray,
	useForm,
} from "react-hook-form";
import { DataGridContext } from "../../data grid files/DataGridContext";
import {
	DataGrid,
	gridVisibleColumnDefinitionsSelector,
	useGridApiRef,
	gridExpandedRowCountSelector,
} from "@mui/x-data-grid";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { API_ENDPOINTS } from "../../../../API/apiConfig";
import format from "date-fns/format";

import DisplayGrid from "./DisplayGrid";

export default function HistoryModal({
	modalConfig,
	setModalConfig,
	gridList,
	contextName,
	table,
	apiRef,
	config,
}) {
	const methods = useForm({
		defaultValues: modalConfig?.editRow?.row,
	});
	const { getAccessTokenSilently } = useAuth0();
	const [rows, setRows] = React.useState([]);
	const [loading, setIsLoading] = React.useState(true);
	const [selectedRow, setSelectedRow] = React.useState({});
	const [gridDisplay, setGridDisplay] = React.useState(null);
	const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

	const [cellModesModel, setCellModesModel] = React.useState({});
	const { data, setData } = React.useContext(DataGridContext);

	const getVisibleColumns = () => {
		const visCol = {};
		for (let i = 0; i < gridList.columns.length; i++) {
			const field = gridList.columns[i].field;
			const visible = gridList.columns[i].historyModalVisible;
			Object.assign(visCol, { [field]: visible });
		}
		return visCol;
	};
	const columns = getVisibleColumns();

	const [columnVisibilityModel, setColumnVisibilityModel] =
		React.useState(columns);
	// console.log(columns);

	const [coordinates, setCoordinates] = React.useState({
		rowIndex: 0,
		colIndex: 0,
	});

	// React.useEffect(() => {
	// 	const { rowIndex, colIndex } = coordinates;
	// 	apiRef.current.scrollToIndexes(coordinates);
	// 	const id = gridExpandedSortedRowIdsSelector(apiRef)[rowIndex];
	// 	const column = gridVisibleColumnDefinitionsSelector(apiRef)[colIndex];
	// 	apiRef.current.setCellFocus(id, column.field);
	// }, [apiRef, coordinates]);

	const handleCellFocus = React.useCallback(( event) => {
		event.defaultMuiPrevented = true;
		// console.log(params.id)
		// console.log('here CF', rows.find((i) => i.uid === params.id))
		// setRowSelectionModel([params.id]);
				// setSelectedRow(rows.find((i) => i.uid === params.row.uid));


		setSelectedRow(event.row);
	}, []);

	// console.log(apiRef.current);
	const cellMode = React.useMemo(() => {
		if (!selectedRow) {
			return "view";
		}
		const { id, field } = selectedRow;
		return cellModesModel[id]?.[field]?.mode || "view";
	}, [cellModesModel, selectedRow]);

	const handleCellKeyDown = React.useCallback(
		(params, event) => {
			setRowSelectionModel([params.id]);
			setSelectedRow(rows.find((i) => i.uid === params.row.uid));

		},
		[]
	);



	const handleCellEditStop = React.useCallback((params, event) => {
		event.defaultMuiPrevented = true;
	}, []);

	const getHistory = async () => {
		const token = await getAccessTokenSilently();

		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		axios
			.get(
				`${API_ENDPOINTS.getHistory}?table=${table}&ref=${parseInt(
					modalConfig.row.row[`${table}_ref`]
				)}`,
				config
			)
			.then(function (response) {
				console.log(
					"res",
					response.data.sort(
						(a, b) =>
							Date.parse(b[`${table}_auditTime`]) -
							Date.parse(a[`${table}_auditTime`])
					)
				);
				setRows(
					response.data.sort(
						(a, b) =>
							Date.parse(b[`${table}_auditTime`]) -
							Date.parse(a[`${table}_auditTime`])
					)
				);
				setSelectedRow(response.data[0]);
				setRowSelectionModel([
					response.data.find((i) => i[`${table}_isCurrent`] === 1).uid,
				]);
				// handleClick("bottom");
				// handleScrollToCell(
				// response.data.find((i) => i[`${table}_isCurrent`] === 1).uid
				// );
				// handleAddRow(
				// 	response.data.find((i) => i[`${table}_isCurrent`] === 1).uid
				// );
				setIsLoading(false);
			})
			.catch(function (error) {
				console.log("res", error);
			});
	};

	// console.log(apiRef?.current);
	// React.useEffect(() => {
	// 	const { rowIndex, colIndex } = coordinates;
	// 	apiRef?.current?.scrollToIndexes(coordinates);
	// 	const id = gridExpandedSortedRowIdsSelector(apiRef)[rowIndex];
	// 	const column = gridVisibleColumnDefinitionsSelector(apiRef)[colIndex];
	// 	console.log(id, column.field);
	// 	apiRef?.current?.setCellFocus(id, column.field);
	// }, [setCoordinates]);

	// const handleClick = (position) => () => {
	// 	const maxRowIndex = gridExpandedRowCountSelector(apiRef) - 1;
	// 	const maxColIndex = gridVisibleColumnDefinitionsSelector(apiRef).length - 1;
	// 	console.log(maxRowIndex, maxColIndex);
	// 	setCoordinates((coords) => {
	// 		switch (position) {
	// 			case "top":
	// 				return { ...coords, rowIndex: Math.max(0, coords.rowIndex - 1) };
	// 			case "bottom":
	// 				return {
	// 					...coords,
	// 					rowIndex: parseInt(
	// 						rows.find((i) => i[`${table}_isCurrent`] === 1).uid
	// 					),
	// 				};
	// 			case "left":
	// 				return { ...coords, colIndex: Math.max(0, coords.colIndex - 1) };
	// 			case "right":
	// 				return {
	// 					...coords,
	// 					colIndex: Math.min(maxColIndex, coords.colIndex + 1),
	// 				};
	// 			default:
	// 				return { ...coords, rowIndex: 0, colIndex: 0 };
	// 		}
	// 	});
	// 	apiRef?.current?.scrollToIndexes(coordinates);
	// };
	// React.useEffect(() => {
	// 	console.log("here", coordinates);
	// 	apiRef.current.scrollToIndexes(coordinates);
	// 	apiRef.current.setCellFocus(coordinates.rowIndex, `${table}_name`);
	// }, [apiRef, coordinates]);

	// const handleAddRow = (uid) => {
	// 	// apiRef.current.startRowEditMode({ id: uid });
	// 	setTimeout(() => {
	// 		setCoordinates({ rowIndex: parseInt(uid), colIndex: 0 });
	// 	}, 5000);
	// };

	React.useEffect(() => {
		if (modalConfig?.row?.row) {
			getHistory();
		}
	}, [modalConfig.open]);

	React.useEffect(() => {
		// console.log('setting selected row', rows.find((i) => i.uid === rowSelectionModel[0]))
		// rowSelectionModel[0] &&
		setSelectedRow(rows.find((i) => i.uid === rowSelectionModel[0]));
	}, [rowSelectionModel]);

	const [rowModesModel, setRowModesModel] = React.useState({});

	// const handleEditClick = (id) => () => {
	// 	setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
	// };

	const processRowUpdate = (newRow) => {
		const updatedRow = { ...newRow, isNew: false };
		setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
		return updatedRow;
	};

	// const handleRowModesModelChange = (newRowModesModel) => {
	// 	setRowModesModel(newRowModesModel);
	// };

	// const [anchorEl, setAnchorEl] = React.useState(null);

	// const handlePopoverOpen = (event, row) => {
	// 	// setTimeout(() => {
	// 	console.log(row);
	// 	setGridDisplay(row);
	// 	setAnchorEl(event.currentTarget);
	// 	// console.log("Delayed for 1 second.");
	// 	// }, 1000);
	// };

	// const handleRowOver = (e) => {
	// 	console.log(e.currentTarget.dataset);
	// 	const rowId = e.currentTarget.dataset.id;
	// 	const row = rows.find((el) => el.uid === rowId);
	// 	setSelectedRow(row);
	// 	setAnchorEl(e.currentTarget);

	// 	console.log(rowId, row);
	// };

	// const handleRowLeave = (e) => {
	// 	setAnchorEl(null);
	// };

	// const handlePopoverClose = () => {
	// 	// setTimeout(() => {
	// 	setAnchorEl(null);
	// 	console.log("Delayed for 1 second.");
	// 	// }, 1000);
	// 	setAnchorEl(null);
	// };
	// const [delayHandler, setDelayHandler] = React.useState(null);

	// const handlePopoverClose = (event) => {
	// 	// setDelayHandler(
	// 	// setTimeout(() => {
	// 	console.log("here");
	// 	// const yourData = // whatever your data is
	// 	setAnchorEl(null);
	// 	// setData(yourData)
	// 	// }, 1500)
	// 	// );
	// };

	// console.log(selectedRow);

	// const open = Boolean(anchorEl);

	const dataColumnsBuilder = [...gridList.columns];
	// const dateAdapter = new AdapterDateFns({ locale });

	dataColumnsBuilder.push(
		{
			field: `${table}_isCurrent`,
			headerName: "Current",
			type: "boolean",
			flex: 1,
			defaultvalue: "", // what is inside modal fields
			editable: false,
			sortable: true,
			disableExport: true,
			// filterable:
			//     checkPermissions(userPermission, {
			//         page: "tags",
			//         // allow: true,
			//         action: "undelete",
			//         strict: true,
			//     }) === true,
			// filterOperators: getGridBooleanOperators(),
			// allowToggle:
			//     checkPermissions(userPermission, {
			//         page: "tags",
			//         // allow: true,
			//         action: "undelete",
			//         strict: true,
			//     }) === true,
			// visible:
			//     checkPermissions(userPermission, {
			//         page: "tags",
			//         // allow: true,
			//         action: "undelete",
			//         strict: true,
			//     }) === true,
			valueGetter: (value) => {
				if (value === 1 || value === "1") {
					value = true;
				} else if (value === 0 || value === "0") {
					value = false;
				} else {
					value = null;
				}
				return value;
			},
		},
		{
			field: `${table}_auditTime`,
			headerName: "Last modified",
			type: "text",
			flex: 1.5,
			defaultvalue: "", // what is inside modal fields
			editable: false,
			sortable: true,
			disableExport: true,
			// ...GRID_DATETIME_COL_DEF,
			// resizable: false,

			// filterOperators: getGridDateOperators(true).map((item) => ({
			//   ...item,
			//   InputComponent: GridFilterDateInput,
			//   InputComponentProps: { showTime: true },
			// })),
			valueFormatter: (value) => {
				if (value) {
					return format(value, "h:mma do MMM yy");

					// return new Date(value);
				}
				return "";
			},
			// filterable:
			//     checkPermissions(userPermission, {
			//         page: "tags",
			//         // allow: true,
			//         action: "undelete",
			//         strict: true,
			//     }) === true,
			// filterOperators: getGridBooleanOperators(),
			// allowToggle:
			//     checkPermissions(userPermission, {
			//         page: "tags",
			//         // allow: true,
			//         action: "undelete",
			//         strict: true,
			//     }) === true,
			// visible:
			//     checkPermissions(userPermission, {
			//         page: "tags",
			//         // allow: true,
			//         action: "undelete",
			//         strict: true,
			//     }) === true,
			// valueGetter: (params) => {
			// 	if (params.value === 1 || params.value === "1") {
			// 		params.value = true;
			// 	} else if (params.value === 0 || params.value === "0") {
			// 		params.value = false;
			// 	} else {
			// 		params.value = null;
			// 	}
			// 	return params.value;
			// },
		}
	);
	// dataColumnsBuilder.map((column) => (column["editable"] = false));

	const { setNewData } = React.useContext(DataGridContext);

	const onSubmit = async () => {
		let form = methods.getValues();

		if (gridList.gridPage === "printers") {
			let obj = {};
			form.modalValues.map((val) => {
				obj[val.Site] = val.Printers;
			});
			form.printer_details.sites = obj;
		}
		if (gridList.gridPage === "items") {
			form = form.plus;
		}
		processRowUpdate(form, modalConfig.editRow.row, modalConfig.isNew);
		if (modalConfig.isNew) {
			setNewData(true);
		}
		// setModalVisible(false);
	};

	const makeCurrent = async (newRow) => {
		const token = await getAccessTokenSilently();

		// copy item history
		const rowsNew = [...rows];

		// find index of original current row
		const originalCurrent = rows.findIndex(
			(i) => i[`${table}_isCurrent`] === 1
		);
		const originalCurrentObj = rows.find((i) => i[`${table}_isCurrent`] === 1);
		// find index of new current row

		const newCurrent = rows.findIndex((i) => i.uid === newRow.uid);
		// change original to not current
		// rowsNew[originalCurrent][`${table}_isCurrent`] = 0;
		// // change new to  current
		// console.log(originalCurrentObj);
		// console.log(newCurrent);
		// rowsNew[newCurrent][`${table}_isCurrent`] = 1;
		newRow[`${table}_isCurrent`] = 1;
		newRow[`${table}_isDeleted`] = 0;
		console.log(newRow);
		const newRowData = {
			updates: [newRow],
		};
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};
		let postStatus;
		axios
			.post(API_ENDPOINTS[table], newRowData, config)
			.then(function (response) {
				// postStatus = response.status;
				console.log(response);
				if (response.status === 200) {
					// let updatedData;
					// if (isNew === true) {
					const updatedRow = response?.data[table][0];
					updatedRow.uid = response?.data[table][0][`${table}_uid`];
					console.log(data[contextName]);
					const newData = [...data[contextName]];
					// .filter(
					// 	(i) => i.uid !== rowsNew[originalCurrent].uid
					// );
					const indexToReplace = newData.findIndex(
						(row) => row.uid === originalCurrentObj.uid
					);
					// newRow[`${table}_auditTime`] =
					// 	response?.data[table][0][`${table}_auditTime`];
					newData[indexToReplace] = updatedRow;
					console.log(newData);
					console.log(indexToReplace);
					const updatedData = {
						...data,
						[contextName]: [...newData],
					};
					// } else {
					// updatedData = {
					// 	...data,
					// 	[contextName]: data[contextName],
					// };
					rowsNew[originalCurrent][`${table}_isCurrent`] = 0;
					// change new to  current
					// console.log(originalCurrent);
					rowsNew[newCurrent][`${table}_isCurrent`] = 1;
					setRows(rowsNew);
					setData(updatedData);

					// setSnackbar({
					// 	children: "successfully saved",
					// 	severity: "success",
					// });
				} else {
					// setSnackbar({
					// 	children: "Error, couldn't save data",
					// 	severity: "error",
					// });
				}
			})
			.catch(function (error) {
				console.log(error);
				postStatus = error.response.status;
				console.log(error.response.data.error);
				// setSnackbar({
				// 	children: error?.response?.data?.error
				// 		? error?.response?.data?.error + " - couldn't save data"
				// 		: "Error - couldn't save data",
				// 	severity: "error",
				// });
			});
	};

	const [open, setOpen] = React.useState(false);

	const handleDoubleCellClick = React.useCallback((params, event) => {
		event.defaultMuiPrevented = true;
	}, []);

	return (
		<>
			<FormProvider {...methods}>
				<Modal
					open={modalConfig.open}
					sx={{ overflow: "hidden" }}
					onClose={() => {
						setModalConfig({ open: false, row: "" });
						setRows([]);
						setRowSelectionModel([]);
					}}
				>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Box sx={style}>
							<Box sx={style2}>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
										height: "10%",
										pb: 2,
									}}
								>
									<Typography variant="h3">
										{gridList.modalTitle} History
									</Typography>
									<IconButton
										aria-label="close"
										sx={{ alignSelf: "flex-end" }}
										onClick={() => {
											setModalConfig({
												row: "",
												open: false,
											});
											setRows([]);
											setIsLoading(true);
											setSelectedRow(null);
											setOpen(false);
										}}
									>
										<CloseIcon />
									</IconButton>
								</Box>
								{table === "list" && (
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
											alignItems: "center",
											height: "10%",
											pb: 2,
										}}
									>
										<FormControlLabel
											control={
												<Switch
													checked={open}
													onChange={() => setOpen(!open)}
												/>
											}
											label={<Typography variant="switch">Snapshot</Typography>}
										/>
									</Box>
								)}
								<Box
									sx={{
										// ...dataGridStyle,
										height: "70%",
										flex: 1,
										width: "100%",
										display: "flex",
										flexDirection: "row",
										justifyContent: "flex-start",
										alignItems: "flex-start",
										gap: open && 4,
									}}
								>
									<DataGrid
										columns={dataColumnsBuilder}
										rows={rows}
										apiRef={apiRef}
										editMode="row"
										getRowId={(row) => row?.uid}
										getRowHeight={() => "auto"}
										loading={loading}
										showCellVerticalBorder
										showCellHorizontalBorder
										onCellKeyDown={(params)=>handleCellKeyDown(params)}
										rowModesModel={cellModesModel}
										
										onRowEditStop={handleCellEditStop}
										onRowClick={(params) => handleCellFocus(params)}
										onRowModesModelChange={null}
										columnVisibilityModel={columnVisibilityModel}
										onColumnVisibilityModelChange={(newModel) =>
											setColumnVisibilityModel(newModel)
										}
										onRowDoubleClick={handleDoubleCellClick}
										hideFooterSelectedRowCount
										onRowSelectionModelChange={(newRowSelectionModel) =>
											{ setRowSelectionModel(newRowSelectionModel) }
										}
										initialState={{
											sorting: {
												sortModel: [
													{ field: `${table}_isCurrent`, sort: "desc" },
												],
											},
										}}
										rowSelectionModel={rowSelectionModel}
										sx={{
											...dataGridStyle,
											"& .MuiDataGrid-root": {
												height: "100%",
											},
										}}
										slots={{
											// toolbar: EditToolbar,
											footer: CustomFooter,
											loadingOverlay: LinearProgress,
										}}
										slotProps={{
											footer: {
												// cellMode,
												selectedRow,
												// setSelectedRow,
												// cellModesModel,
												// setCellModesModel,
												makeCurrent,
												table,
											},
											// row: {
											// 	onFocus: handleCellFocus,
											// },
											// row: {
											// 	onMouseEnter: handleRowOver,
											// 	onMouseLeave: handleRowLeave,
											// },
										}}
									/>
									{/* <GridDrawer
										open={open}
										setOpen={setOpen}
										selectedRow={rows[index]}
									/>
                                     */}
									{table === "list" && (
										<Collapse orientation="horizontal" in={open}>
											<Box
												sx={{
													width: "100%",
													height: "100%",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<DisplayGrid selectedRow={selectedRow} />
											</Box>
										</Collapse>
									)}
								</Box>
								{/* <CustomFooter
									makeCurrent={makeCurrent}
									selectedRow={selectedRow}
									table={table}
								/> */}
							</Box>
						</Box>
					</form>
				</Modal>
			</FormProvider>
		</>
	);
}

export const CustomFooter = (props) => {
	const { makeCurrent, selectedRow, table } = props;
	return (
		// <GridFooterContainer>
		<Box
			sx={{
				width: "100%",
				display: "flex",
				justifyContent: "flex-end",
				alignItems: "flex-end",
				// pr: 2,
				height: "10%",
				pt: 2,
			}}
		>
			{/* <Box> */}
			<Button
				variant="contained"
				disabled={!selectedRow || selectedRow[`${table}_isCurrent`] === 1}
				onClick={() => makeCurrent(selectedRow)}
				sx={{ justifySelf: "flex-end" }}
			>
				Make Current
			</Button>
			{/* <GridPagination {...paginationProps} ActionsComponent={Pagination} /> */}
			{/* </Box> */}
		</Box>
		// </GridFooterContainer>
	);
};

const style2 = {
	height: "100%",
	width: "100%",

	p: 4,
	overflow: "hidden",
	display: "flex",
	justifyContent: "flex-start",
	// gap: 2,
	flexDirection: "column",

	overflowY: "scroll",

	"&::-webkit-scrollbar": {
		width: "3px",
	},
	"&::-webkit-scrollbar-track": {
		backgroundColor: "#f5f5f5",
	},
	"&::-webkit-scrollbar-thumb": {
		borderRadius: "10px",
		boxShadow: "inset 0 0 10px rgba(0,0,0,.3)",
		// backgroundColor: "#f5f5f5",
	},
	"& .MuiDataGrid-root": {
		border: "none",
		height: "100%",
		// width: "100%",
		flex: 1,
		overflow: "auto",
		whiteSpace: "normal !important",
		wordWrap: "break-word !important",
		fontSize: 13,
		// maxWidth: "100%",
	},
	"& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
		outline: "none !important",
	},
	"& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
		{
			outline: "none !important",
		},
	// "& .MuiDataGrid-footerContainer": {
	// 	height: "30px",
	// 	minHeight: "30px",
	// },
};

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	borderRadius: "10px",
	width: 1000,
	height: "80vh",
	// height: "100%",
	// minHeight:
	// 	// 100 +
	// 	typeof modalColumns !== "undefined" ? modalColumns.length * 70 : 400,
	bgcolor: "white",
	boxShadow: 24,
	// p: 4,
	overflow: "auto",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	// padding: 0,
	gap: 2,
	flexDirection: "column",
};
