import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import { Button, Typography } from "@mui/material";
import NotRegistered from "./NotRegistered";
import Verify from "./Verify";
import { useErrorBoundary } from "react-error-boundary";
import GradientText from "../components/GradientText";

export function MyFallbackComponent({ error }) {
	const { resetBoundary } = useErrorBoundary();

	console.log("fallback boundary", error);


	return (
		<>
			{error === "notVerified" ? (
				<NotRegistered />
			) : error === "verifyEmail" ? (
				<Verify />
			) : (
				<Box
					role="alert"
					sx={{
						width: "100vw",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						gap: 2,
					}}
				>
					{error && (
						<>
							<GradientText text="Oops!" />

							<Typography sx={{ mt: 2, fontSize: 18 }}>
								MaxCloud encountered an error.
							</Typography>

							<Typography sx={{ mb: 2, fontSize: 14 }}>
								Please refresh and try again, or contact
								support@gs-systems.co.uk
							</Typography>
						</>
					)}
					<Button variant="contained" onClick={resetBoundary}>
						Try again
					</Button>
					{error && (
						<Typography
							sx={{
								fontSize: 10,
								position: "absolute",
								textAlign: "center",
								bottom: 10,
								left: 0,
								right: 0,
							}}
						>
							Error: {error.message ? error.message : error}
						</Typography>
					)}
				</Box>
			)}
		</>
	);
}
