import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";

export const Auth0ProviderWithNavigate = ({ children, ...props }) => {
	// const navigate = useNavigate();

	const domain = process.env.REACT_APP_AUTH0_DOMAIN;
	const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
	const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;

	// if (!(domain && clientId && redirectUri)) {
	// 	return null;
	// }

	// const onRedirectCallback = (appState) => {
	// 	navigate((appState && appState.returnTo) || window.location.pathname);
	// };

	const { mainProviderConfig, linkProviderConfig } = props;

	return (
		<Auth0Provider
			{...mainProviderConfig}
			// onRedirectCallback={onRedirectCallback}
		>
			<Auth0Provider {...linkProviderConfig}>{children}</Auth0Provider>
		</Auth0Provider>
	);
};
