import React from "react";
import { Controller, useFormContext, useFieldArray } from "react-hook-form";
import {
	Autocomplete,
	Box,
	Button,
	Card,
	Collapse,
	IconButton,
	MenuItem,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { DataGridContext } from "../../data grid files/DataGridContext";
import { Colours } from "../../../../assets/global/Theme-variable";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import Module from "./Module";
import IntegratedColourPicker from "../../../grid/components/IntegratedColourPicker";

const TagModules = ({ methods, table, modalConfig }) => {
	const { control, getValues, formState, watch } = useFormContext();

	const { data, setData, setSnackbarMessage, setSnackbarOpen, setNewData } =
		React.useContext(DataGridContext);
	const { fields, append, remove } = useFieldArray({
		control,
		name: "tag_details.modules",
	});

	const handleAppend = (value) => {
		append(value);
	};

	const handleRemove = (index) => {
		remove(index);
	};

	const watchFieldArray = watch("tag_details.modules");
	const controlledFields =
		fields &&
		watchFieldArray &&
		fields?.map((field, index) => {
			return {
				...field,
				...watchFieldArray[index],
			};
		});

	const [expanded, setExpanded] = React.useState(
		controlledFields.map((x) => x.id)
	);

	const ExpandMore = styled((props) => {
		const { expand, ...other } = props;
		return <IconButton {...other} />;
	})(({ theme, expand }) => ({
		transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	}));

	const handleExpandClick = (element) => {
		if (expanded.includes(element)) {
			const newIndexes = expanded.filter((i) => i !== element);
			setExpanded(newIndexes);
		} else {
			setExpanded([...expanded, element]);
		}
	};

	return (
		<>
			{/* <ul> */}
			<Box sx={{ width: "100%" }}>
				<Box sx={{ width: "100%", display:'flex', flexDirection:'row', alignItems:'space-between', gap: 2}}>
				<Controller
				render={({ field: { onChange, value, ref, onBlur }, ...props }) => (
					<IntegratedColourPicker
						selectedColour={value}
						handleColourChange={onChange}
						type="tagField"
						label="Colour"
					/>
				)}
				name={`tag_details.styling.colour`}
			/>
				<Box sx={{width:'100%'}}>
					<Controller
						control={control}
						render={({
							field: { name, onChange, defaultValue, onBlur, value, ref },
						}) => (
							<TextField
								id="tag-name"
								label="Name"
								// value={
								// 	`modalConfig?.editRow?.row?.${table}_name` &&
								// 	`modalConfig?.editRow?.row?.${table}_name`
								// }
								// defaultValue={
								// 	`modalConfig?.editRow?.row?.${table}_name` &&
								// 	`modalConfig?.editRow?.row?.${table}_name`
								// }
								value={value}
								name={name}
								onChange={onChange}
								size="small"
								sx={{ width: "100%" }}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						)}
						// name="plu_name"
						name="tag_name"
						// defaultValue=""
						rules={{
							pattern: {
								value: /^[-a-zA-Z0-9@\s\*&+%£=!:;><)?(^\[\]{\}\\\/\.\_]+$/,
								message: "Can't contain emojis or special characters",
							},
							required: {
								value: true,
								message: "Tag Name is required",
							},
							validate: (value) => {
								if (modalConfig.isNew === true) {
									return (
										!data?.tags?.some((user) => user.tag_name === value) ||
										"A tag with this name already exists"
									);
								}
							},
						}}
					/>
					{formState.errors?.tag_name && (
						<Typography sx={{ color: Colours.gsblue, fontSize: 12 }}>
							{formState?.errors.tag_name?.message}
						</Typography>
					)}
					</Box>
				
				</Box>
			</Box>
			{/* <Controller
				render={({ field: { onChange, value, ref, onBlur }, ...props }) => (
					<IntegratedColourPicker
						selectedColour={value}
						handleColourChange={onChange}
						type="tagField"
						label="Colour"
					/>
				)}
				name={`tag_details.styling.colour`}
			/> */}
			{controlledFields &&
				controlledFields?.map((item, index) => {
					return (
						// <li key={item.id} className="input-container">
						<Card
							variant="outlined"
							elevation={0}
							key={item.id}
							sx={{ backgroundColor: "none", pl: 2, pr: 2, width: "100%" }}
						>
							<Box
								sx={{
									width: "100%",
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
									// height: 60,
									mb: expanded.includes(item.id) && 2,
								}}
							>
								<Typography sx={{ pl: 2 }}>Tag {index + 1}</Typography>

								<ExpandMore
									expand={expanded.includes(item)}
									onClick={() => handleExpandClick(item.id)}
									aria-expanded={expanded.includes(item.id)}
									aria-label="show more"
								>
									<ExpandMoreIcon />
								</ExpandMore>
							</Box>
							<Collapse
								in={expanded.includes(item.id)}
								timeout="auto"
								// unmountOnExit
							>
								<Stack>
									<Module item={item} control={control} index={index} />
									<IconButton
										color={Colours.gsblue}
										sx={{ alignSelf: "flex-end", color: Colours.gsblue,  }}
										onClick={() => handleRemove(index)}
									>
										<DeleteIcon />
									</IconButton>
								</Stack>
							</Collapse>
						</Card>
						// </li>
					);
				})}

			<Button
				size="medium"
				sx={{ alignSelf: "flex-end" }}
				onClick={() => handleAppend()}
			>
				<AddIcon style={{ fontSize: "20px" }} /> Add New Module
			</Button>
		</>
	);
};

export default TagModules;
