import {
	Box,
	Button,
	Checkbox,
	InputLabel,
	Modal,
	Switch,
	TextField,
	Typography,
} from "@mui/material";
import React from "react";
import CustomTextField from "./CustomTextField";
import { Colours } from "../assets/global/Theme-variable";
import SelectList from "./SelectList";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	borderRadius: "20px",
	width: 400,
	minHeight: 400,
	bgcolor: "white",
	boxShadow: 24,
	p: 4,
	display: "flex",
	flexDirection: "column",
};

export default function ContactModal({
	modalVisible,
	setModalVisible,
	onSubmit,
}) {
	return (
		<Modal open={modalVisible}>
			<Box sx={style}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						mt: 2,
					}}
				>
					<Typography variant="h3" sx={{ colour: Colours.gsblue }}>
						Get in touch
					</Typography>
					<Button
						variant="contained"
						size="medium"
						sx={{ alignSelf: "flex-end" }}
						onClick={() => setModalVisible(!modalVisible)}
					>
						X
					</Button>
				</Box>

				<Typography sx={{ fontSize: 12, mt: 2, textAlign: "center" }}>
					If you require support with any of your EPoS technology or GS Systems
					software, you can contact us by phone or send us an email. Our support
					services operate 24 hours a day, 365 days a year and we can provide
					technical support over the phone, via remote access or with an on-site
					visit.
				</Typography>
				<Typography sx={{ fontSize: 12, mt: 2, textAlign: "center" }}>
					Not only can we provide technical support, we’re also here to help
					with advice or guidance on growing your business. Need a hand updating
					your EPoS with a new promotion, or looking for ways to improve the
					margin on your best-selling product? Our expert team can help. <br />
					<br />
					Email us at:
					<Typography
						sx={{ fontSize: 12, color: Colours.gsblue, textAlign: "center" }}
					>
						support@gs-systems.co.uk
					</Typography>
					or call us on:
					<Typography
						sx={{ fontSize: 12, color: Colours.gsblue, textAlign: "center" }}
					>
						0161 483 5595
					</Typography>
				</Typography>
			</Box>
		</Modal>
	);
}
