const PaymentScreenFuncType = [
	{ val: 0, name: "Blank" },
	// { val: 21, name: "Item" },
	{ val: 12, name: "Navigate" },
	{ val: 3, name: "Modifier" },
	{ val: 20, name: "Macro" },
	{ val: 1, name: "Function" },
	{ val: 2, name: "Payment Method" },
	{ val: 6, name: "Control" },

	{ val: 13, name: "Preset Server" },
];

export default PaymentScreenFuncType;
