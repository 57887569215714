import React, { useState } from "react";
import { motion } from "framer-motion";
import { useSortable } from "@dnd-kit/sortable";
import GridContext from "../context/GridContext";
import { getTextColour } from "../../datagrids/utils";
import { Box, Tooltip, Typography } from "@mui/material";
import { DataGridContext } from "../../datagrids/data grid files/DataGridContext";
import { checkPermissions } from "../../AccessControl";
import { PriorityHigh } from "@mui/icons-material";
import { CursorContext } from "../../../components/CursorProvider";
import withCursor from "../../../components/withCursor";
import { Colours } from "../../../assets/global/Theme-variable";

const NewGridItem = ({
	item,
	id,
	activeId,
	forbidDrag,
	setForbidDrag,
	open,
	setOpen,
	textAlign,
	selectedColour,
	selectedTextColour,
	handleOpen,
	disableSelection,
	draggingItem,
	disabled,
	// setDraggingItem,
	editBar,
	key,
	errorMessage,
	showText,
	...props
}) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isSorting,
		over,
		isDragging,
	} = useSortable({ id: id });

	const { data, setData, userPermission } = React.useContext(DataGridContext);
	const { variant } = React.useContext(GridContext);

	const overItem = variant?.list_details?.buttons.find(
		(b) => b?.id.toString() === activeId
	);

	const {
		setItems,
		setList,
		setLayout,
		setPage,
		setItem,
		openItem,
		setVariant,
		list,
		setNewChanges,
		closeDialog,
		setPageArr,
		bulkBackground,
		bulkForeground,
		setAllButtons,
		copyStyles,
		setCopyStyles,
		selectedItems,
		setSelectedItems,
		setVariantOptions,
		setErrorMessage,
		setListName,
		handleRevertChanges,
		handleSubmitLayout,
		newChanges,
	} = React.useContext(GridContext);

	// console.log(item);
	const { onCursor } = React.useContext(CursorContext);
	let background =
		data?.colours[0]?.palette_details?.colours.find(
			(col) => col?.index === item?.style?.background
		) || data.colours[0].palette_details.colours[59];

	let foreground =
		data?.colours[0]?.palette_details?.colours.find(
			(col) => col?.index === item?.style?.foreground
		) || data.colours[0].palette_details.colours[63];

	const itemAnimation = {
		hidden: { opacity: 0 },
		show: { opacity: 1 },
	};

	const style = {
		gridColumn: `span ${item.mx}`,
		gridRow: `span ${item.my}`,
		display: item.my === 0 || item.mx === 0 ? "none" : null,
		width: "100%",
		height: "100%",
		overflowY: "hidden",
		willChange: "transform",

		// cursor:
		// 	disableSelection !== true &&
		// 	!checkPermissions(userPermission, {
		// 		page: "lists",
		// 		allow: false,
		// 		action: "edit",
		// 	}) &&
		// 	"pointer",
		// cursor: copyStyles === true ? "none" : "pointer",
		boxSizing: "border-box",
		justifyContent: "center",
		alignItems: "center",
		transform: "none",
		transform: isSorting
			? undefined
			: transform
			? `translate(${transform.x}px, ${transform.y}px)`
			: "none",
		// transition,

		// "&::before": {
		// 	display: "flex",
		// 	alignSelf: "stretch",
		// 	flex: 1,
		// 	padding: "100%",
		// },
	};

	const [clicks, setClicks] = useState(0);

	React.useEffect(() => {
		let singleClickTimer;
		if (clicks === 1) {
			singleClickTimer = setTimeout(function () {
				if (copyStyles === true) {
					setVariant((draft) => {
						const buttonIndex = draft.list_details.buttons.findIndex(
							(button) => button.id === item.id
						);
						const newItem = { ...item };
						draft.list_details.buttons[buttonIndex].style.background =
							bulkBackground?.index;
						draft.list_details.buttons[buttonIndex].style.foreground =
							bulkForeground?.index;
						setNewChanges(true);
						setSelectedItems([...selectedItems, item]);
					});
				} else {
					handleOpen(item);
				}
				setClicks(0);
			}, 200);
		} else if (clicks === 2) {
			handleDoubleClick();
			setClicks(0);
		}
		return () => clearTimeout(singleClickTimer);
	}, [clicks]);

	let timer;

	const handleClick = (e) => {
		e.preventDefault();
		if (e.type === "click" && disableSelection === false) {
			setClicks(clicks + 1);
		}
	};

	const handleDoubleClick = (event) => {
		if (disableSelection === true) {
			setErrorMessage({
				open: true,
				text: "You are currently editing a different list area, to edit this list, please change to a list using this area",
			});
		} else {
			if (item.functionType === 12) {
				let newList = data?.screens?.find(
					(l) => JSON.parse(l?.list_number) === item?.functionValue
				);
				if (newChanges) {
					setErrorMessage({
						open: true,
						text: `You have unfinished changes to list ${list.list_number} -  ${list.list_name} . Please save your changes before continuing.`,
						button2text: "Save",
						button2func: handleSubmitLayout,
						button1text: "Cancel",
						button1func: closeDialog,
					});
				} else {
					setList(newList);
					setVariant(newList);
					setVariantOptions(
						data?.screens?.filter(
							(screen) => screen.list_number === newList.list_number
						)
					);
					setItem(newList.list_details.buttons[0]);
				}
			}
		}
	};
	let pluDoesNotExist =
		item?.functionType === 21 &&
		item?.functionValue &&
		item.mx !== 0 &&
		item.my !== 0
			? !data?.items
					?.filter((i) => i.plu_isDeleted === 0)
					.find(
						(plu) =>
							parseInt(plu.plu_number) === item.functionValue &&
							item.mx !== 0 &&
							item.my !== 0
					)
			: item?.functionType === 1 &&
			  item?.functionValue &&
			  item.mx !== 0 &&
			  item.my !== 0
			? !data?.function
					?.filter((i) => i.function_isDeleted === 0)
					.find(
						(plu) =>
							parseInt(plu.function_number) === item.functionValue &&
							item.mx !== 0 &&
							item.my !== 0
					)
			: item?.functionType === 12 && item?.functionValue
			? !data?.screens
					?.filter((i) => i.list_isDeleted === 0)
					.find(
						(plu) =>
							parseInt(plu.list_number) === item.functionValue &&
							item.mx !== 0 &&
							item.my !== 0
					)
			: item?.functionType === 3 && item?.functionValue
			? !data?.modifier
					?.filter((i) => i.modifier_isDeleted === 0)
					.find(
						(plu) =>
							parseInt(plu.modifier_number) === item.functionValue &&
							item.mx !== 0 &&
							item.my !== 0
					)
			: item?.functionType === 2 && item?.functionValue
			? !data?.paymentMethod
					?.filter((i) => i.paymentMethod_isDeleted === 0)
					.find(
						(plu) =>
							parseInt(plu.paymentMethod_number) === item.functionValue &&
							item.mx !== 0 &&
							item.my !== 0
					)
			: item?.functionType === 20 && item?.functionValue
			? !data?.macro
					?.filter((i) => i.macro_isDeleted === 0)
					.find(
						(plu) =>
							parseInt(plu.macro_number) === item.functionValue &&
							item.mx !== 0 &&
							item.my !== 0
					)
			: item?.functionType === 13 && item?.functionValue
			? !data.presetServers?.find(
					(server) =>
						server?.value === item?.functionValue &&
						item.mx !== 0 &&
						item.my !== 0
			  )
			: null;
	
	let pluDoesNotExist2 =
		item?.functionType === 21 && item.functionValue.length > 0
			? data?.items?.find(
					(plu) => parseInt(plu.plu_number) === item.functionValue
			  )
			: item?.functionType === 1 && item.functionValue.length > 0
			? data?.function?.find(
					(plu) => parseInt(plu.function_number) === item.functionValue
			  )
			: item?.functionType === 12 && item.functionValue.length > 0
			? data?.screens?.find(
					(plu) => parseInt(plu.list_number) === item.functionValue
			  )
			: item?.functionType === 3 && item.functionValue.length > 0
			? data?.modifier?.find(
					(plu) => parseInt(plu.modifier_number) === item.functionValue
			  )
			: item?.functionType === 2 && item.functionValue.length > 0
			? data?.paymentMethod?.find(
					(plu) => parseInt(plu.paymentMethod_number) === item.functionValue
			  )
			: item?.functionType === 20 && item.functionValue.length > 0
			? data?.macro?.find(
					(plu) => parseInt(plu.macro_number) === item.functionValue
			  )
			: item?.functionType === 13 && item.functionValue.length > 0
			? data.presetServers?.find(
					(server) => server?.value === item?.functionValue
			  )
			: null;

	// console.log(pluDoesNotExist);

	const text =
		item?.functionType === 21
			? "item"
			: item?.functionType === 1
			? "function"
			: item?.functionType === 12
			? "screen"
			: item?.functionType === 3
			? "modifier"
			: item?.functionType === 2
			? "payment method"
			: item?.functionType === 20
			? "macro"
			: item?.functionType === 13
			? "preset server"
			: null;
	const button = {
		rest: { scale: 1 },
		hover: { scale: 1.2 },
		pressed: { scale: 0.95 },
	};

	return (
		<motion.div
			// layoutId={id}
			transition={{
				type: "spring",
				duration: activeId ? 0 : 0.6,
				// duration: 0.6,
			}}
			// initial="rest"
			// transition={{
			// 	ease: "linear",
			// 	duration: 2,
			// 	x: { duration: 1 },
			// }}

			variants={itemAnimation}
			ref={setNodeRef}
			style={style}
			{...attributes}
			{...listeners}
		>
			<Box
				sx={{
					display: "flex",
					alignItems:
						item.style.alignmentV === 1
							? "center"
							: item.style.alignmentV === 0
							? "flex-start"
							: "flex-end",
					justifyContent: "center",
					width: "100%",
					height: "100%",
					backgroundColor:
						over?.id === item?.id &&
						overItem?.mx === item?.mx &&
						overItem?.my === item?.my
							? Colours.sage
							: over?.id === item?.id &&
							  (overItem?.mx !== item?.mx || overItem?.my !== item?.my)
							? Colours.red
							: background?.colour
							? background?.colour
							: "black",
					opacity: isDragging ? 0 : 1,

					textAlign: "center",
				}}
				// onMouseEnter={() => (copyStyles === true ? onCursor("left") : null)}
				// onMouseLeave={copyStyles === true ? onCursor : null}
				onClick={(event) =>
					// checkPermissions(userPermission, {
					// 	page: "items",
					// 	// allow: false,
					// 	action: "edit",
					// }) &&
					handleClick(event)
				}
			>
				{pluDoesNotExist && (
					// <Box sx={{ position: "absolute", top: 2, right: 2 }}>
					<motion.div
						className="circle"
						animate={{
							scale: [1.2, 1, 1.2],
							transition: {
								duration: 1.5,
								repeat: "Infinity",
								repeatType: "loop",
							},
						}}
						variants={button}
						initial="rest"
						whilehover="hover"
						whiletap="pressed"
						style={{ position: "absolute", top: 2, right: 2 }}
					>
						<Tooltip
							title={`The ${text} assigned to this button does not exist`}
						>
							<PriorityHigh
								sx={{
									fontSize: 16,
									color: getTextColour(background.colour).colour,
								}}
							/>
						</Tooltip>
						{/* </Box> */}
					</motion.div>
				)}

				<Typography
					// className="card-title"
					sx={{
						color: foreground?.colour || "white",
						// color: "pink",
						lineHeight: 1,
						fontSize: JSON.parse(item.style.font.match(/\d+/)[0]),
						// alignSelf:
						// 	item.style.alignmentV === 1
						// 		? "center"
						// 		: item.style.alignmentV === 0
						// 		? "flex-start"
						// 		: "flex-end",

						fontWeight:
							item.style.font.replace(/[0-9]/g, "") === "B" ? 700 : 400,
						textAlign: item.style.alignmentH === 1 ? "center" : "left",
						userSelect: "none",
						display: item.hideText === 1 ? "none" : null,
						overflow: "hidden",
						whiteSpace: "pre-line",
						textOverflow: "ellipsis",
					}}
				>
					{item.functionType === 13
						? `Server ${parseInt(item.functionValue)}`
						: item?.text?.replaceAll("|", "\n")}
				</Typography>
			</Box>
		</motion.div>
		// </Box>
	);
};

export default withCursor(NewGridItem);
