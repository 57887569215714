import React from "react";
import { useLocation, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import {
	Box,
	Drawer,
	useMediaQuery,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Avatar,
	Typography,
	IconButton,
	Link,
	Button,
} from "@mui/material";
import { Colours, SidebarWidth } from "../../../assets/global/Theme-variable";
import LogoIcon from "../../../components/LogoIcon";
import Menuitems from "./data";
import { useAuth0 } from "@auth0/auth0-react";
import UserBadge from "../../../components/UserBadge";
import AccessControl from "../../../views/AccessControl";
import { DataGridContext } from "../../../views/datagrids/data grid files/DataGridContext";
import { LayoutGroup, motion } from "framer-motion";
import { BiChevronRight } from "react-icons/bi";

const Sidebar = (props) => {
	const [open, setOpen] = React.useState(true);
	const { pathname } = useLocation();
	const pathDirect = pathname;
	const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
	const { logout, user, isAuthenticated } = useAuth0();
	const domain = process.env.REACT_APP_AUTH0_DOMAIN;
	const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
	const domain2 = process.env.REACT_APP_API_SERVER_URL;
	const [active, setActive] = React.useState(null);
	const { userPermission } = React.useContext(DataGridContext);
	const [categoriesOpen, setCategoriesOpen] = React.useState(false);
	const navigate = useNavigate();

	const handleClick = (index) => {
		if (index === "categories") {
			setCategoriesOpen((prevOpen) => !prevOpen);
			setOpen(index);
		} else if (open === index) {
			setOpen((prevopen) => !prevopen);
		} else {
			setOpen(index);
		}
	};
	const handleLogout = () => {
		logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		});
	};

	const SidebarContent = (
		<Box
			sx={{
				// p: 3,
				// height: "calc(100vh - 40px)",
				height: "100%",
				// width: "100%",

				// height: "90vh",
				// flex: 1,
				backgroundColor: "white",
				// margin: 2,
				mr: 0,
				// borderRadius: 5,
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				alignItems: "center",
				pt: 4,
				pb: 4,
			}}
		>
			{/* <Link to="/"> */}
			<Box
				sx={{
					display: "flex",
					alignItems: "Center",
					justifyContent: "center",
					// mt: 4,
					mb: 4,
				}}
				onClick={() => navigate("/")}
			>
				<LogoIcon width={140} />
			</Box>
			{/* </Link> */}

			<List sx={{ width: "100%", flex: 1, justifyContent: "flex-start" }}>
				<LayoutGroup>
					{Menuitems.map((item, index) => {
						return (
							<AccessControl
								key={index}
								userPermissions={userPermission}
								unallowedPermissions={{
									page: item.dbName,
									// allow: false,
									action: "view",
									strict: false,
								}}
								renderNoAccess={() => <></>}
							>
								<React.Fragment key={index}>
									{item.children ? (
										<React.Fragment key={index}>
											<ListItem
												onClick={() => handleClick("categories")}
												onMouseEnter={() => setActive(item.href)}
												onMouseLeave={() => setActive(null)}
												// selected={categoriesOpen}
												key={item}
												selected={
													pathDirect === "/majorcategories" ||
													pathDirect === "/categories"
												}
												sx={{
													width: "100%",
													// pb: 0,
													// ml: 1,

													...(pathDirect === "/majorcategories" && {
														backgroundColor: (theme) =>
															`${Colours.white}!important`,
													}),
													...(pathDirect === "/categories" && {
														backgroundColor: (theme) =>
															`${Colours.white}!important`,
													}),
												}}
											>
												<ListItemIcon
													sx={{
														color:
															active === item.href
																? Colours.gsblue
																: Colours.gsgrey,
														ml: 1,
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														mr: 0.5,
													}}
												>
													{categoriesOpen ? (
														// Different icon when selected
														<item.selectedIcon size={item.iconSize} />
													) : (
														// Original icon when not selected
														<item.icon size={item.iconSize} />
													)}
												</ListItemIcon>
												<Typography
													sx={{
														color:
															active === item.href
																? Colours.gsblue
																: Colours.gsgrey,
														fontWeight: 200,
														fontSize: 14,
														userSelect: "none",
														cursor: "pointer",
													}}
												>
													{item.title}
												</Typography>
											</ListItem>

											{categoriesOpen ? (
												<motion.div layout>
													<List
														sx={{ ml: 2, mt: 0, pt: 0, position: "relative" }}
													>
														{item.children.map((subItem, subIndex) => (
															<ListItem
																key={subIndex}
																component={NavLink}
																to={subItem.href}
																selected={pathDirect === subItem.href}
																onMouseEnter={() => setActive(subItem.href)}
																onMouseLeave={() => setActive(null)}
																sx={{
																	color:
																		active === subItem.href
																			? Colours.gsblue
																			: Colours.gsgrey,
																	width: "100%",

																	alignItems: "flex-end",

																	margin: 0,
																	padding: "0px 5px 10px 15px",
																	position: "relative",
																	// }
																	"&::before, ::after": {
																		content: '""',
																		left: 25,
																		position: "absolute",
																		right: "auto",
																	},
																	"&::before": {
																		borderLeft: `1px solid ${Colours.gsgrey}`,
																		borderBottom: `1px solid ${Colours.gsgrey}`,
																		borderBottomLeftRadius: 5,

																		height: "115%",

																		width: 25,
																	},

																	"& span": {
																		// -moz-border-radius:'5px',
																		// -webkit-border-radius:'5px',
																		// borderBottom: "1px solid #999",
																		position: "relative",
																		left: 45,
																		top: 9,
																		display: "inline-block",
																		textDecoration: "none",
																	},

																	"&::last-child::before": {
																		height: "30px",
																	},

																	...(pathDirect === subItem.href && {
																		backgroundColor: (theme) =>
																			`white !important`,
																	}),
																}}
															>
																<span>
																	<Typography
																		sx={{
																			color:
																				active === subItem.href
																					? Colours.gsblue
																					: Colours.gsgrey,
																			fontWeight: 200,
																			fontSize: 13,
																			...(pathDirect === subItem.href && {
																				color: Colours.gsblue,
																			}),
																		}}
																	>
																		{subItem.title}
																	</Typography>
																</span>
															</ListItem>
														))}
													</List>
												</motion.div>
											) : null}
										</React.Fragment>
									) : (
										<motion.div
											layout
											// style={{ height: isOpen ? "100px" : "500px" }}
											//   onClick={() => setOpen(!isOpen)}
											transition={{ duration: 0.2 }}
											variants={{
												hidden: {},
												show: {
													transition: {
														staggerChildren: 0.1,
														delayChildren: 0.5,
													},
												},
											}}
											initial="hidden"
											animate="show"
											key={index}
										>
											<ListItem
												key={index}
												onClick={() => handleClick(index)}
												onMouseEnter={() => setActive(item.href)}
												onMouseLeave={() => setActive(null)}
												component={NavLink}
												to={item.href}
												selected={pathDirect === item.href}
												sx={{
													width: "100%",
													// ml: 1,
													...(pathDirect === item.href && {
														color: "white",
														backgroundColor: (theme) =>
															`${Colours.white} !important`,
														width: "100%",
														// ml: 0.4,
														// borderLeft: `4px solid ${Colours.gsblue}`,
													}),
												}}
											>
												<ListItemIcon
													sx={{
														color:
															active === item.href
																? Colours.gsblue
																: Colours.gsgrey,
														ml: 1,
														mr: 0.5,
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														...(pathDirect === item.href && {
															color: Colours.gsblue,
														}),
													}}
												>
													<item.icon size={item.iconSize} />
												</ListItemIcon>
												<Typography
													sx={{
														color:
															active === item.href
																? Colours.gsblue
																: Colours.gsgrey,
														fontWeight: 200,
														fontSize: 14,
														...(pathDirect === item.href && {
															color: Colours.gsblue,
														}),
													}}
												>
													{item.title}
												</Typography>
											</ListItem>
										</motion.div>
									)}
								</React.Fragment>
							</AccessControl>
						);
					})}
				</LayoutGroup>
			</List>

			<Box
				sx={{ mt: 0, width: "100%", justifySelf: "flex-end" }}
				onClick={() => navigate("/profile")}
			>
				{isAuthenticated && user && (
					<UserBadge
						handleLogout={handleLogout}
						loggedInUser={user}
						token={props.token}
					/>
				)}
			</Box>

			<Button
				size="small"
				onClick={handleLogout}
				variant="gradient"
				sx={{ mt: 2, padding: "4px 9px" }}
				// endIcon={<BiChevronRight />}
			>
				Log Out
			</Button>
			{/* </Box> */}
			{/* <Link to={"/"}>
				<ListItemText sx={{color: "white"}}>Log out</ListItemText>
			</Link> */}
			{/* <Box
				sx={{
					backgroundColor: Colours.gsblue,
					// width: "70%",
					height: "160px",
					borderRadius: 2,
					p: 2,
					m: 2,

					display: "flex",
					flexDirection: "column",
					justifyContent: "space-around",
				}}
			>
				<BsPatchQuestion color="white" size={30} />

				<Typography variant="h4" sx={{color: "white"}}>
					Questions?
				</Typography>
				<Typography
					variant="h5"
					sx={{color: "white", fontSize: 14, fontWeight: 100}}
				>
					We're here to help, just give us a shout
				</Typography>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "center",
					}}
				>
					<Typography sx={{color: "white"}}>Contact us</Typography>
					<BsArrowRightShort color="white" size={25} />
				</Box>
			</Box> */}
		</Box>
	);
	if (lgUp) {
		return (
			<Drawer
				anchor="left"
				open={props.isSidebarOpen}
				variant="persistent"
				PaperProps={{
					sx: {
						width: SidebarWidth,
						border: "none",
						// height: "100%",
						zIndex: 1,
						flex: 1,
					},
				}}
			>
				{SidebarContent}
			</Drawer>
		);
	}
	return (
		<Drawer
			anchor="left"
			open={props.isMobileSidebarOpen}
			onClose={props.onSidebarClose}
			PaperProps={{
				sx: {
					width: SidebarWidth,
					border: "none",
					// flex: 1,
					zIndex: 1,

					height: "100%",
					// height: "100vh",
				},
			}}
			variant="temporary"
		>
			{SidebarContent}
		</Drawer>
	);
};

export default Sidebar;
